<template>
    <Popup :modelValue=!!selectedRow
        @update:model-value=closeChild
        title="Payment confirmation"
        hasClose
    >
        <template #contents v-if=selectedRow>
            <div class="grid-item">
                The remaining amount {{ paidOrReceived }} for this Churn 
                <strong>{{ selectedRow.journalInvoiceReference }}</strong> is:
                <strong>£{{ Math.abs(parseFloat(selectedRow.balance)).toFixed(2) }}</strong>
            </div>
            <div class="grid-item">
                Please confirm the <strong>{{ receivedOrSent }}</strong> of funds.
            </div>

            <div class="top-content">
                <div class="flex-col-centered">
                    Select a payment date and amount:
                    <form @submit.prevent=ConfirmTransfer
                        title="Select a date for confirmed tranfers"
                        class="search-with-button"
                    >
                        <div class="date-picker" style="width: 50%">
                            <DatePicker
                                v-model="selectedDate"
                                type="date"
                                :enable-time-picker="false"
                                :format="FormatDatePicker"
                                placeholder="Select Date..."
                                :text-input-options="textInputOptions"
                                text-input
                                :max-date="new Date()"
                                :teleport="true"
                                required
                            />
                        </div>
                        <CurrencyInput
                            v-model="paymentAmount"
                            :max="Math.abs(+selectedRow.balance)"
                            :min="0.01"
                            :currency="MarketCurrency[selectedRow.market]"
                            style="border-radius: 0"
                            required
                        />
                        <button :disabled=working
                            class="right-button"
                        >
                            Confirm
                        </button>
                    </form>
                </div>
            </div>
        </template>
    </Popup>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import DatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import PaymentItem from "@/Interfaces/Payments/ChurnPaymentItem";
import Popup from "./Popup.vue";
import CurrencyInput from "./CurrencyInput.vue";
import { MarketCurrency } from "@/services/helpers/Helper";
import { confirmChurnPayment } from "@/services/network";
import useNotifierWithErrFormatter from "@/services/composables/NotifierWithErrFormatter";
import useSpinnerPopup from "@/services/composables/SpinnerPopup";
import { authService } from "@/auth/authSingleton";
import DateIgnoreTime from "@/types/DateIgnoreTime";

const props = defineProps<{
    modelValue?: PaymentItem;
}>();
const selectedRow = computed({
    get() {
        return props.modelValue
    },
    set(val) {
        emits('update:modelValue', val);
    }
});
const working = ref(false);
const selectedDate = ref<Date>();
const textInputOptions = { format: "yyyy/MM/dd" };
const paymentAmount = ref("");
const receivedOrSent = computed(() => {
    return +(selectedRow.value?.balance ?? 0) < 0 ? "transfer" : "receipt";
});
const paidOrReceived = computed(() => {
    return +(selectedRow.value?.balance ?? 0) < 0 ? "left to be paid" : "to be received";
});
const emits = defineEmits<{
    (e: 'update:modelValue', val?: PaymentItem);
    (e: 'confirm');
}>();
function closeChild(){
    selectedDate.value = undefined;
    paymentAmount.value = "";
    selectedRow.value = undefined;
}
const FormatDatePicker = (date: Date) => {
    return date.toLocaleString("sv-SE").split(" ")[0].replaceAll("-", "/");
};
async function ConfirmTransfer() {
    if (working.value) return;
    working.value = true;
    const closeSpinner = useSpinnerPopup().show();
    try{
        if (!selectedRow.value) throw new ReferenceError('selectedRow cannot be null when submitting the form');
        const payload = Object.assign(selectedRow.value, {
            userEmail: authService.getEmailAddress(),
            balanceDateUpdated: new DateIgnoreTime(selectedDate.value),
            amountPaid: paymentAmount.value,
        });
        const response = await confirmChurnPayment.put(payload);
        useNotifierWithErrFormatter().success({ msg: "Payment successfully registered." });
        closeChild();
        emits("confirm");
    }catch (error) {
        useNotifierWithErrFormatter().error({
            name: `${__filename}/${ConfirmTransfer.name}`,
            errorType: 'Confirming payment',
            error
        });
    } finally {
        closeSpinner();
        working.value = false;
    }
}
</script>

<style scoped>
.grid-item {
    padding: 10px;
}
</style>
