import { ICarPurchasesCarSaleInvoice } from '@/Interfaces/CarPurchases/ICarPurchasesCarSaleInvoice';
import DateIgnoreTime from '@/types/DateIgnoreTime';

export class CarPurchasesCarSaleInvoice {
    INVOICE_ID: string;
    ISSUE_DATE: DateIgnoreTime;
    CONTRACT_ID?: string;
    COLLECTIVE_INVOICE_ID?: string;
    MARKET?: string;
    AMOUNT_TOTAL?: number;
    AMOUNT_EX_VAT?: number;
    AMOUNT_VAT?: number;
    JOURNAL_STATUS?: string;
    BALANCE?: number;
    BALANCE_DATE_UPDATED?: Date;
    APPROVED_FOR_PAYMENT?: boolean;

    constructor(item: ICarPurchasesCarSaleInvoice) {
        this.INVOICE_ID = item.InvoiceId;
        this.ISSUE_DATE = new DateIgnoreTime(item.IssueDate);
        this.CONTRACT_ID = item.ContractId;
        this.COLLECTIVE_INVOICE_ID = item.CollectiveInvoiceId;
        this.MARKET = item.Market;
        this.AMOUNT_TOTAL = item.AmountTotal;
        this.AMOUNT_EX_VAT = item.AmountExVAT;
        this.AMOUNT_VAT = item.AmountVAT;
        this.JOURNAL_STATUS = item.JournalStatus;
        this.BALANCE = item.Balance;
        this.BALANCE_DATE_UPDATED = item.BalanceDateUpdated;
        this.APPROVED_FOR_PAYMENT = item.Approved;
    }

    GetDateFromString(dateString: string): Date {
        try {
            const [year, month, day] = dateString.split("T")[0].split("-").map(Number);
            const date: Date = new Date(year, month - 1, day);
            date.setHours(0, 0, 0, 0);
            return date;
        } catch {
            const date: Date = new Date(dateString);
            date.setHours(0, 0, 0, 0);
            return date;
        }
    }
}