import { AxiosResponse } from "axios";
import { financialSystemNetworkService } from "@/services/helpers/Helper";
import log from "loglevel";
import { Log2API } from "@/services/Logger";
import { LastReceivedFilesByType, LastReceivedFilesByTypeDataItem } from "@/models/LastReceivedFilesByType";
import { ILastReceivedFilesByType } from "@/Interfaces/DataProcessing/ILastReceivedFilesByType";
import { NetworkService } from "../NetworkService";
import { ILastReceivedFilesDetails } from "@/Interfaces/DataProcessing/ILastReceivedFilesDetails";
import { StagingData, StagingEntries, StagingEntry } from "@/models/StagingEntries";
import { DisplayableKeys } from "../SorterService";
import { FileDetails } from "@/models/LastReceivedFileDetails";

export const colsLastReceivedFilesByType: DisplayableKeys<LastReceivedFilesByTypeDataItem>[] = [
	"FileType",
	"WaitingName",
	"WaitingCreatedOn",
	"ProcessedName",
	"ProcessedCreatedOn",
	"FailedName",
	"FailedCreatedOn"
];
export const labelsLastReceivedFilesByType = [
	"File Type",
    "Waiting",
	"Created",
	"Processed",
	"Created",
	"Failed",
	"Created"
];
export const colsStagingEntry: DisplayableKeys<StagingEntry>[] = [
	'SourceFileReference',
	'TableType',
	'LoadingSequence',
	'TimesLoaded',
	'LoadingError',
	'TableData'
];
export const labelsStagingEntry = [
	"Source File",
	"Target Table",	
	"Loading Seq.",		
	"Times Loaded",
	"Loading Error",	
	"Table Data"
];

export const colsFileDetails: (keyof FileDetails)[] = [
    "Name",
    "CreatedOn",
];
export const labelsFileDetails = [
    "Name",
    "Date"
];

export function ParseItems(item: ILastReceivedFilesByType): LastReceivedFilesByType {
    log.trace("ParseItems()");

    return new LastReceivedFilesByType(item);
} 

export class DataHelperServices {
	Logger = new Log2API();

	mockFinancialSystemNetworkService = new NetworkService(
		process.env.VUE_APP_MOCK_API_URL_NO_PARAMS!
	);

	public async GetLastReceivedSFTPFiles(market: string): Promise<ILastReceivedFilesByType> {
		log.trace(`GetLastReceivedSFTPFiles()`);

		const urlParams: string = process.env.VUE_APP_LAST_RECEIVED_DATA_PROCESSING_URL_PARAMS + "/" + market;
		const data: ILastReceivedFilesByType = await new Promise((resolve, reject) => {
			financialSystemNetworkService
				.get(urlParams)
				.then((res: AxiosResponse<ILastReceivedFilesByType>) => {
					log.debug("GetLastReceivedSFTPFiles()\n res.data:", res.data);
					resolve(res.data)
				})
				.catch((error: any) => {
					reject(error);
				});
		});

		return data;
	}

	public async GetLastReceivedSFTPFilesDetails(market: string, fileType: string): Promise<ILastReceivedFilesDetails> {
		log.trace(`GetLastReceivedSFTPFilesDetails()`);

		const urlParams: string = process.env.VUE_APP_LAST_RECEIVED_DATA_PROCESSING_URL_PARAMS + "/" + market + "/" + fileType;
		const data: ILastReceivedFilesDetails = await new Promise((resolve, reject) => {
			financialSystemNetworkService
				.get(urlParams)
				.then((res: AxiosResponse<ILastReceivedFilesDetails>) => {
					log.debug("GetLastReceivedSFTPFilesDetails()\n res.data:", res.data);
					resolve(res.data)
				})
				.catch((error: any) => {
					reject(error);
				});
		});

		return data;
	}

	public async GetSourceFile(fileType: string, status: string, fileName: string, createdOn?: Date): Promise<any> {
		log.trace(`GetSourceFile()`);

		log.debug(createdOn, createdOn === undefined);
		log.debug(createdOn === undefined ? "" : `?createdOn=${createdOn}`);
		const urlParams: string = process.env.VUE_APP_VIEW_SOURCE_FILE_DATA_PROCESSING_URL_PARAMS + "/" + fileType + "/" + status + "/" + fileName + (createdOn === undefined ? "" : `?createdOn=${createdOn}`);
		const data: any = await new Promise((resolve, reject) => {
			financialSystemNetworkService
				.get(urlParams)
				.then((res: AxiosResponse<any>) => {
					resolve(res.data)
				})
				.catch((error: any) => {
					reject(error);
				});
		});

		return data;
	}

	public async GetStagingEntries(market: string): Promise<StagingEntry[]> {
		log.trace(`GetStagingEntries()`);

		const urlParams: string = process.env.VUE_APP_DATA_PROCESSING_STAGING_URL_PARAMS + "/" + market;
		const data: StagingEntries = await new Promise((resolve, reject) => {
			financialSystemNetworkService
				.get(urlParams)
				.then((res: AxiosResponse<StagingEntries>) => {
					log.debug("GetStagingEntries()\n res.data:", res.data);
					resolve(res.data)
				})
				.catch((error: any) => {
					reject(error);
				});
		});

		return data.StagingEntries;
	}

	public async GetStagingData(body: StagingEntry): Promise<string> {
		log.trace(`GetStagingData()`);

		console.log(body);

		const urlParams: string = process.env.VUE_APP_DATA_PROCESSING_STAGING_DATA_URL_PARAMS + "/" ;
		const data: StagingData = await new Promise((resolve, reject) => {
			financialSystemNetworkService
				.post(body,urlParams)
				.then((res: AxiosResponse<StagingData>) => {
					log.debug("GetStagingData()\n res.data:", res.data);
					resolve(res.data)
				})
				.catch((error: any) => {
					reject(error);
				});
		});

		return data.StagingData;
	}

	public async DeleteStagingEntries(body: StagingEntries): Promise<number> {
		log.trace(`DeleteStagingEntries()`);

		const urlParams: string = process.env.VUE_APP_DATA_PROCESSING_STAGING_DELETE_URL_PARAMS + "/" ;
		const status: number = await new Promise((resolve, reject) => {
			financialSystemNetworkService
				.delete(body,urlParams)
				.then((res: AxiosResponse<number>) => {
					log.debug("DeleteStagingEntries()\n res.status:", res.status);
					resolve(res.status)
				})
				.catch((error: any) => {
					reject(error);
				});
		});

		return status;
	}
}
