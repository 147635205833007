import { CarPurchasePayment } from "./CarPurchasePayment";

export class CarPurchasesPaymentRequest {
    CollectiveInvoiceId!: string;
    AmountTotal!: number;
    AmountExVATTotal!: number;
    AmountVATTotal!: number;
    Payments!: CarPurchasePayment[];
    ContractOwner!: string;
    OwnerAssignmentDate!: string;
    Market!: string;
    UserEmail!: string;
    // Partial stuff can be undefined but this class contains only fields that cannot be undefined
    // Can cause crash!
    constructor(data: Partial<CarPurchasesPaymentRequest>) {
        Object.assign(this, data);
    }
}