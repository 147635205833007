import ICustomerCreditNoteSpecificationTable from "@/Interfaces/CustomersAndContracts/ICustomerCreditNoteSpecificationTable";
import { extractNonArraysFromObj } from '@/services/helpers/ObjectHelper';
import DateIgnoreTime from "@/types/DateIgnoreTime";

export default class CustomerCreditNoteSpecificationTable implements ICustomerCreditNoteSpecificationTable {
    CUSTOMER_CREDIT_NOTE_NUMBER: string;
    PERIOD_DATE_FROM: DateIgnoreTime;
    PERIOD_DATE_TO: DateIgnoreTime;

    constructor(data: ICustomerCreditNoteSpecificationTable) {
        Object.assign(this,extractNonArraysFromObj(data));
        this.CUSTOMER_CREDIT_NOTE_NUMBER = data.CUSTOMER_CREDIT_NOTE_NUMBER;
        this.PERIOD_DATE_FROM=new DateIgnoreTime(data.PERIOD_DATE_FROM);
        this.PERIOD_DATE_TO=new DateIgnoreTime(data.PERIOD_DATE_TO);
    }

}
