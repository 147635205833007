import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import useNotifierWithErrFormatter from "../composables/NotifierWithErrFormatter";
import BaseNetworkService from "./BaseNetworkService";

export default class FileNetworkService<Param=undefined, Query=Param>
extends BaseNetworkService<Param, Query> {
    private static extractContentDisposition(disposition?: string) {
        if (disposition) {
            const fileNameMatch = disposition.match(/filename=(.+)/);
            if (fileNameMatch && fileNameMatch.length > 1) {
                return fileNameMatch[1];
            }
        }
        return undefined;
    }
    private static deduceFilenameFromURL(url: string): string {
        const last = url.split('/').pop();
        const splitLast = last?.split('?');
        var filename = splitLast?.[0];
        const paramStr = splitLast?.pop();
        const params = paramStr?.split('&');
        if (params) {
            for(const pair of params) {
                const val = pair.split('=')[1];
                if (val) { filename += `_${val}`; }
            }
        }
        return filename ?? "";
    }
    protected getUrl(paramURI: string, query?: Query|null) {
        const queryStr = this.getQueryString(query);
        return this.getBaseURL() + paramURI + queryStr;
    }
    protected getMethod() {
        return 'GET';
    }
    protected async getReqConfig(query?: Query|null): Promise<AxiosRequestConfig<Query>> {
        const headers = await this.getHeader();
        const responseType = 'blob';
        const method = this.getMethod();
        return { headers, method, responseType };
    }
    download(query?: Query|null, filename?: string): Promise<void>;
    download(param: Param|null, query: Query|null, filename?: string): Promise<void>;
    public async download(params?: Param|Query|null, query?: Query|null|string, filename?: string) {
        const anchor = document.createElement("a");
        document.body.appendChild(anchor);

        function isParam(params?: Param|Query|null, query?: Query|string|null): params is Param {
          if ((query!==undefined) && (typeof query!=='string') && params) return true;
          return false;
        }
        const paramPresent = isParam(params, query);
        const paramURI = paramPresent ? this.getUri(params) : this.getUri();
        const q = paramPresent ? query as Query|null : params;
        const url = this.getUrl(paramURI, q);
        const config = await this.getReqConfig(q);
        const res = await axios<any, AxiosResponse<Blob, Query>, Query>(url, config);
        const blobby = res.data;
        if (blobby.size == 0) {
            useNotifierWithErrFormatter().warn({
                msg: `${new Date().toLocaleString()} - Downloaded file is empty`
            });
        }
        const objectUrl = window.URL.createObjectURL(blobby);
        anchor.href = objectUrl;
        anchor.download = (paramPresent ? filename : query as string) ?? 

        FileNetworkService.extractContentDisposition(res.headers["content-disposition"]) ??
        FileNetworkService.deduceFilenameFromURL(url) ?? "";

        anchor.click();
    
        window.URL.revokeObjectURL(objectUrl);
    }
}