import { createApp, App } from 'vue';
import log from 'loglevel';
import SpinnerPopupVue from '@/components/SpinnerPopup.vue';

class SpinnerPopup {
    private popup: App<Element> | undefined = undefined;
    static show() {
        const spinnerPopup = new SpinnerPopup();
        const tempDiv = document.createElement('div');
        spinnerPopup.popup = createApp(SpinnerPopupVue);
        spinnerPopup.popup.mount(tempDiv);
        document.body.appendChild(tempDiv);
        return ()=>spinnerPopup.hide();
    }
    hide() {
        log.debug('SpinnerPopup.hide', this.popup);
        this.popup?._container?.remove();
        this.popup?.unmount();
    }
}
export interface ISpinnerPopup {
    /**
     * Creates a new vue app attached to the end of the body
     * 
     * @example
     * ```ts
     *  const closeSpinner = useSpinnerPopup().show();
     *  await task;
     *  closeSpinner();
     * ```
     * 
     * @returns Delegate for hiding the spinner
     */
    show: ()=>()=>void;
}
export default function useSpinnerPopup() {
    return { show: SpinnerPopup.show } as ISpinnerPopup;
}