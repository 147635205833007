<template id="VATLoan">
    <div class="tabs-container">
        <div class="top-tabs tabs-buttons">
            <div class="tabs">
                <button
                    :class="`button-left ${showDrawdown ? 'selected' : 'unselected'}`"
                    :disabled="showDrawdown"
                    :title="
                        showDrawdown
                            ? 'Currently viewing the Form to submit Drawdowns'
                            : 'Click to view Confirmed Drawdowns'
                    "
                    @click="ToggleTabs()"
                >
                    Drawdown
                </button>
            </div>
            <div class="tabs">
                <button
                    :class="`button-right ${showDrawdown ? 'unselected' : 'selected'}`"
                    :disabled="!showDrawdown"
                    :title="
                        !showDrawdown
                            ? 'Currently viewing Confirmed Drawdowns'
                            : 'Click to view the Form to submit Drawdowns'
                    "
                    @click="ToggleTabs()"
                >
                    Confirmed
                </button>
            </div>
        </div>
    </div>

    <SubmitDrawdown v-if="showDrawdown" />
    <ConfirmedDrawdown v-show="!showDrawdown" />
</template>

<script setup lang="ts">
import { ref } from "vue";
import SubmitDrawdown from "./SubmitDrawdown.vue";
import ConfirmedDrawdown from "./ConfirmedDrawdown.vue";

const showDrawdown = ref<boolean>(true);

function ToggleTabs() {
    showDrawdown.value = !showDrawdown.value;
}

</script>

<style scoped lang="scss"></style>
