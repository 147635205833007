import {
	currentTab,
	description,
	displayAmountVATTotal,
	infoError,
	isLoading,
	isDateSearch,
	journalTypeSelection,
	journalingType,
	metadata,
	showDescription,
	showDownloadVATReturnsError,
	showSearchForVATReturnsFromJournaledReferencesError,
	showSearchForVATReturnsFromJournaledReferencesErrorChanger,
	tabAmountVATTotal,
	tabVATReturns,
	tabs,
	Box1VATs,
	box1AmountVATTotal,
	Box3VATs,
	box3AmountVATTotal,
	Box4VATs,
	box4AmountVATTotal,
	Box5VATs,
	box5AmountVATTotal,
	Box6VATs,
	box6AmountExVATTotal,
	Box7VATs,
	box7AmountExVATTotal,
	validJournalReferences,
	box1JournalingTypes,
	box3JournalingTypes,
	box4JournalingTypes,
	box5JournalingTypes,
	box6JournalingTypes,
	box7JournalingTypes
} from '@/services/helpers/VATReturns/VATReturnsVariables';
import log from "loglevel";
import { IJournalVAT } from "@/Interfaces/VATReturns/IJournalVAT";
import JournalVAT from "@/models/VATReturns/JournalVat";
import { JournalingTypes, JournalingOptions, journalingTypes, JournalingType } from "@/models/Journaling/JournalingTypes";
import { VATReturns, displayedVATReturns, invalidJournalReferences, validJournalReferencesByJournalType } from "@/services/helpers/VATReturns/VATReturnsVariables";
import IJournalVATResponse from '@/Interfaces/VATReturns/IJournalVATResponse';
import INotFoundError from '@/Interfaces/Errors/INotFoundError';
import { IAccountingMetaData } from '@/Interfaces/VATReturns/IAccountingMetaData';
import { MonetaryValueWithCommas } from "@/services/helpers/Helper";

export function ParseItems(item: IJournalVAT): JournalVAT {
	log.trace("ParseItems():\nitem:", item);
	return new JournalVAT(item);
}
export function SortVATReturns() {
	const name = SortVATReturns.name;
	const prefix = `${name}()\n`;
	log.trace(`${name}()`);

	// Step 1: Group by lastReferenceElement
	const groupedByLastReferenceElement = displayedVATReturns.value
		?.reduce((groups, item) => {
			const referenceParts = item.Reference.split('_');
			const lastReferenceElement = referenceParts[referenceParts.length - 1];

			if (!groups[lastReferenceElement]) {
				groups[lastReferenceElement] = [];
			}

			groups[lastReferenceElement].push(item);

			return groups;
		}, {} as Record<string, JournalVAT[]>);
	log.trace(prefix + "groupedByLastReferenceElement:", groupedByLastReferenceElement);

	// Step 2: Sort groups by lastReferenceElement
	const sortedGroups = Object.keys(groupedByLastReferenceElement!)
		.sort()
		.map((lastReferenceElement) => {
			return {
				lastReferenceElement,
				items: groupedByLastReferenceElement![lastReferenceElement]
			};
		});

	// Step 3: Sort within groups by IssueDateTime
	sortedGroups.forEach((group) => {
		group.items.sort(
			(a, b) => b.IssueDateTime.getTime() - a.IssueDateTime.getTime()
		);
	});
	log.trace(prefix + "sortedGroups:", sortedGroups);

	// Step 4: Flatten the sorted groups
	const sortedVATReturns: JournalVAT[] = sortedGroups.reduce(
		(flattened, group) => [...flattened, ...group.items],
		[] as JournalVAT[]
	);
	log.trace(prefix + "sortedVATReturns:", sortedVATReturns);

	displayedVATReturns.value = sortedVATReturns;
}
export function FilterVATReturns() {
	// Logging
	const name = "FilterVATReturns";
	const prefix = `${name}()\n`;
	log.debug(prefix + "journalTypeSelection:", journalTypeSelection.value, "\ncurrentTab:", currentTab.value);

	if (journalTypeSelection.value == "") {
		GetTabData();
	} else {
		switch (currentTab.value) {
			case tabs.Summary:
				break;
			case tabs.Box1:
				// Update table data
				tabVATReturns.value = Box1VATs.value.filter((VATReturn: JournalVAT) =>
					journalTypeSelection.value == VATReturn.JournalingType
				);
				log.trace(prefix + "filteredTabVATReturns:", tabVATReturns.value);

				// Update amount total
				displayAmountVATTotal.value = GetAmountTotal(tabVATReturns.value || [], tabs.Box1);
				break;
			case tabs.Box3:
				// Update table data
				tabVATReturns.value = Box3VATs.value.filter((VATReturn: JournalVAT) =>
					journalTypeSelection.value == VATReturn.JournalingType
				);
				log.trace(prefix + "filteredTabVATReturns:", tabVATReturns.value);

				// Update amount total
				displayAmountVATTotal.value = GetAmountTotal(tabVATReturns.value || [], tabs.Box1, tabs.Box2);
				break;
			case tabs.Box4:
				// Update table data
				tabVATReturns.value = Box4VATs.value.filter((VATReturn: JournalVAT) =>
					journalTypeSelection.value == VATReturn.JournalingType
				);
				log.trace(prefix + "filteredTabVATReturns:", tabVATReturns.value);

				// Update amount total
				displayAmountVATTotal.value = GetAmountTotal(tabVATReturns.value || [], tabs.Box4);
				break;
			case tabs.Box5:
				// Update table data
				tabVATReturns.value = Box5VATs.value.filter((VATReturn: JournalVAT) =>
					journalTypeSelection.value == VATReturn.JournalingType
				);

				// Update amount total
				const Tab5Box3VATs: JournalVAT[] = GetTabVATReturns(tabs.Box1, tabs.Box2).filter((VATReturn: JournalVAT) =>
					journalTypeSelection.value == VATReturn.JournalingType
				);
				const Tab5Box4VATs: JournalVAT[] = GetTabVATReturns(tabs.Box4).filter((VATReturn: JournalVAT) =>
					journalTypeSelection.value == VATReturn.JournalingType
				);
				tabVATReturns.value = [...Tab5Box3VATs, ...Tab5Box4VATs]
				log.trace(prefix + "filteredTabVATReturns:", tabVATReturns.value);

				const tab5Box3AmountVATTotal: number = GetAmountTotal(Tab5Box3VATs, tabs.Box1, tabs.Box2);
				log.trace("tab5Box3AmountVATTotal:", tab5Box3AmountVATTotal);
				const tab5Box4AmountVATTotal: number = GetAmountTotal(Tab5Box4VATs, tabs.Box4);
				log.trace("tab5Box4AmountVATTotal:", tab5Box4AmountVATTotal);

				displayAmountVATTotal.value = 0;
				displayAmountVATTotal.value += tab5Box3AmountVATTotal;
				displayAmountVATTotal.value -= tab5Box4AmountVATTotal;
				break;
			case tabs.Box6:
				// Update table data
				tabVATReturns.value = Box6VATs.value.filter((VATReturn: JournalVAT) =>
					journalTypeSelection.value == VATReturn.JournalingType
				);
				log.trace(prefix + "filteredTabVATReturns:", tabVATReturns.value);

				// Update amount total
				displayAmountVATTotal.value = GetAmountTotal(tabVATReturns.value || [], tabs.Box6);
				break;
			case tabs.Box7:
				// Update table data
				tabVATReturns.value = Box7VATs.value.filter((VATReturn: JournalVAT) =>
					journalTypeSelection.value == VATReturn.JournalingType
				);
				log.trace(prefix + "filteredTabVATReturns:", tabVATReturns.value);

				// Update amount total
				displayAmountVATTotal.value = GetAmountTotal(tabVATReturns.value || [], tabs.Box7);
				break;
			default:
				log.warn(prefix + "currentTab:", currentTab.value, "\nUnhandled tab")
				break;
		}
	}
}
export function ValidateJournalReferences(journalReferences: string[]) {
	// Logging
	const name = "ValidateJournalReferences";
	const prefix = `${name}()\r\n`
	log.trace(`${name}{}`);

	// Reset
	invalidJournalReferences.value = [];
	validJournalReferencesByJournalType.value = {};
	validJournalReferences.value = [];

	for (const journalReference of journalReferences) {
		if (ValidateJournalReference(journalReference)) {
			const journalReferenceParts = journalReference.split("_");
			log.trace(prefix + "journalReferenceParts:", journalReferenceParts);
			var journalType = PascalToSnakeCase(journalReferenceParts[journalReferenceParts.length - 1]);
			// Clean
			if (journalType.endsWith(",")) {
				journalType = journalType.slice(0, -1);
			}
			log.trace(prefix + "journalType:", journalType);

			if (Object.values(JournalingTypes).includes(journalType as JournalingTypes)) {
				log.trace(prefix + `journalType (${journalType}) is a valid JournalingType`);
				if (!validJournalReferencesByJournalType.value[journalType]) {
					validJournalReferencesByJournalType.value[journalType] = [];
				}

				validJournalReferences.value.push(journalReference)
				validJournalReferencesByJournalType.value[journalType].push(journalReference);
			} else {
				log.trace(prefix + `journalType (${journalType}) is an invalid JournalingType`);
				// Add to invalid list
				invalidJournalReferences.value.push(journalReference);
			}
		} else {
			// Add to invalid list
			log.trace(prefix + `Invalid journalReference: ${journalReference}`);
			invalidJournalReferences.value.push(journalReference);
		}
	}

	log.trace("validJournalReferencesByJournalType:", validJournalReferencesByJournalType.value);
}
export function ValidateJournalReference(journalReference: string): boolean {
	// Logging
	const name = "ValidateJournalReference";
	journalReference = journalReference.toUpperCase();
	var prefix = `${name}()\njournalReference: ${journalReference}\n`;
	log.trace(prefix, "journalReference:", journalReference);

	const isBackout = journalReference.startsWith(JournalingOptions.JournalBackoutPrefix.toUpperCase());
	log.trace(prefix, "isBackout:", isBackout);
	if (isBackout) {
		journalReference = journalReference.replace(`${JournalingOptions.JournalBackoutPrefix}_`, "");
		prefix = `${name}()\njournalReference: ${journalReference}\n`;
		log.trace(prefix + "Removed backout prefix");
	}

	const validJournalReferencePrefix = journalReference.startsWith(JournalingOptions.JournalReferencePrefix.toUpperCase());
	log.trace(prefix, "validJournalReferencePrefix:", validJournalReferencePrefix);

	const isInvoice = journalReference.startsWith(JournalingOptions.InvoiceReferencePrefix.toUpperCase());
	log.trace(prefix, "isInvoice:", isInvoice);

	const journalReferenceSplits = journalReference.split("_");
	log.trace(prefix, "journalReferenceSplits.length:", journalReferenceSplits.length);

	let validJournalReference = false;
	if (isInvoice) {
		// Journal Reference should be of format JRN_INV_<invoiceNumber>_<journalType>
		if (journalReferenceSplits.length === 4) {
			validJournalReference = true;
		}
	} else {
		// Journal Reference should be of format JRN_<invoiceNumber>_<journalType>
		if (journalReferenceSplits.length === 3) {
			validJournalReference = true;
		}
	}

	const journalNumberString = isInvoice ? journalReferenceSplits[2] : journalReferenceSplits[1];
	log.trace(prefix, "journalNumberString:", journalNumberString);
	const journalNumberValid = !isNaN(parseInt(journalNumberString));

	log.trace(prefix, "validJournalReferencePrefix:", validJournalReferencePrefix, "\nvalidJournalReference:", validJournalReference, "\njournalNumberValid:", journalNumberValid);
	log.trace(prefix, "ValidJournalReference:", validJournalReferencePrefix && validJournalReference && journalNumberValid);
	return validJournalReferencePrefix && validJournalReference && journalNumberValid;
}
export function PascalToSnakeCase(input: string, toUpper = true): string {
	const snakeCase = input.replace(/([a-z0-9])([A-Z])/g, "$1_$2");
	return toUpper ? snakeCase.toUpperCase() : snakeCase.toLowerCase();
}
export function ResetError() {
	showDescription.value = false;

	showSearchForVATReturnsFromJournaledReferencesErrorChanger.value = !showSearchForVATReturnsFromJournaledReferencesErrorChanger.value;
	showSearchForVATReturnsFromJournaledReferencesError.value = false;
	showDownloadVATReturnsError.value = !showDownloadVATReturnsError.value;
	showDownloadVATReturnsError.value = false;
	infoError.value = undefined;
}
export function DisplayVATReturns(response: IJournalVATResponse) {
	const name = "DisplayVATReturns";
	log.trace(`${name}()`);

	ResetError();

	metadata.value = response.Metadata;
	description.value = metadata.value.Description;
	journalingType.value = metadata.value.JournalingType;
	showDescription.value = description.value.length > 0 ? true : false;

	VATReturns.value = response.Invoices;
	log.trace(`${name}()\nParseItems()`);
	displayedVATReturns.value = VATReturns.value.map(ParseItems);
	log.trace(`${name}()\nParsed Items`);

	log.trace(`${name}()\nSortVATReturns()`);
	SortVATReturns();
	log.trace(`${name}()\nSorted VAT Returns`);
	log.trace(`${name}()\ndisplayedVATReturns: ${displayedVATReturns.value}`);
	tabVATReturns.value = displayedVATReturns.value;

	isLoading.value = false;
}
export function DisplayVATReturnsNotFound(response: INotFoundError) {
	log.trace(`DisplayVATReturnsNotFound()`);

	ResetError();

	showDescription.value = true;
	console.log("Description:", response.response?.data.Error);
	description.value = response.response?.data.Error;

	displayedVATReturns.value = [];
	tabVATReturns.value = [];

	SortVATReturns();
	log.debug(
		"SearchForJournalReference() displayedVATReturns:",
		displayedVATReturns.value
	);

	isLoading.value = false;
}
export function ToggleTab(tab: tabs) {
	currentTab.value = tab;
}
export function GetTabData() {
	const name = GetTabData.name;
	const prefix = `${name}\r\n`;
	switch (currentTab.value) {
		case tabs.Summary:
			break;
		case tabs.Box1:
			tabVATReturns.value = Box1VATs.value;
			displayAmountVATTotal.value = box1AmountVATTotal.value;
			break;
		case tabs.Box3: // Sum of boxes 1 and 2
			tabVATReturns.value = Box3VATs.value;
			displayAmountVATTotal.value = box3AmountVATTotal.value;
			break;
		case tabs.Box4:
			tabVATReturns.value = Box4VATs.value;
			displayAmountVATTotal.value = box4AmountVATTotal.value;
			break;
		case tabs.Box5: // Difference between Box 3 and Box 4
			tabVATReturns.value = Box5VATs.value;
			displayAmountVATTotal.value = box5AmountVATTotal.value;
			break;
		case tabs.Box6: // Total value of sales and all other outputs excluding any VAT.
			tabVATReturns.value = Box6VATs.value;
			displayAmountVATTotal.value = box6AmountExVATTotal.value;
			break;
		case tabs.Box7: // Total value of purchases and all other inputs excluding any VAT.
			tabVATReturns.value = Box7VATs.value;
			displayAmountVATTotal.value = box7AmountExVATTotal.value;;
			break;
		default:
			log.warn(prefix + "currentTab:", currentTab.value, "\nUnhandled tab")
			tabAmountVATTotal.value = 0;
			displayAmountVATTotal.value = tabAmountVATTotal.value;
			tabVATReturns.value = displayedVATReturns.value;
			break;
	}
}
export function GetAmountTotal(journalVATs: JournalVAT[], tab: tabs, otherTab?: tabs): number {
	const name = GetAmountTotal.name;
	var prefix = otherTab == undefined ? `${name}()\ntab: ${tab}\njournalVATs: ${journalVATs}\n` : `${name}()\ntab: ${tab}\notherTab: ${otherTab}}\njournalVATs: ${journalVATs}\n`;
	log.trace(prefix);

	if (otherTab == undefined) {
		return journalVATs.reduce((partialSum: number, vatReturn: JournalVAT) => {
			const metadata: IAccountingMetaData = vatReturn.JournalVATMetaData.AccountingMetaData.find((metadata: IAccountingMetaData) =>
				metadata.AccountingBox.toString() == tab.toString()
			)!;
			return (
				metadata.IsIncrease
					? partialSum + (metadata.UseAmountVAT ? vatReturn.AmountVat : vatReturn.AmountExVat)
					: partialSum - (metadata.UseAmountVAT ? vatReturn.AmountVat : vatReturn.AmountExVat)
			);
		}, 0);
	} else {
		return journalVATs.reduce((partialSum: number, vatReturn: JournalVAT) => {
			const metadata: IAccountingMetaData = vatReturn.JournalVATMetaData.AccountingMetaData.find((metadata: IAccountingMetaData) =>
				(metadata.AccountingBox.toString() == tab.toString()) || (metadata.AccountingBox.toString() == otherTab.toString())
			)!;
			return (
				metadata.IsIncrease
					? partialSum + (metadata.UseAmountVAT ? vatReturn.AmountVat : vatReturn.AmountExVat)
					: partialSum - (metadata.UseAmountVAT ? vatReturn.AmountVat : vatReturn.AmountExVat)
			);
		}, 0);
	}
}
export function GetTabVATReturns(tab: tabs, otherTab?: tabs): JournalVAT[] {
	const name = GetTabVATReturns.name;
	var prefix = otherTab == undefined ? `${name}()\ntab: ${tab}` : `${name}()\ntab: ${tab}\notherTab: ${otherTab}}\n`;
	log.trace(prefix);

	if (otherTab == undefined) {
		return displayedVATReturns.value?.filter((vatReturn: JournalVAT) => {
			for (const metadata of vatReturn.JournalVATMetaData.AccountingMetaData) {
				if (metadata.AccountingBox.toString() == tab.toString()) {
					return true;
				}
			}
			return false;
		}) || [];
	} else {
		return displayedVATReturns.value?.filter((vatReturn: JournalVAT) => {
			for (const metadata of vatReturn.JournalVATMetaData.AccountingMetaData) {
				if ((metadata.AccountingBox.toString() == tab.toString()) || (metadata.AccountingBox.toString() == otherTab.toString())) {
					return true;
				}
			}
			return false;
		}) || [];
	}
}
export function GetTabJournalingTypes(): JournalingType[] {
	switch (currentTab.value) {
		case tabs.Box1:
			return journalingTypes.filter(j => box1JournalingTypes.value.includes(j.Canonical));
		case tabs.Box3:
			return journalingTypes.filter(j => box3JournalingTypes.value.includes(j.Canonical));
		case tabs.Box4:
			return journalingTypes.filter(j => box4JournalingTypes.value.includes(j.Canonical));
		case tabs.Box5:
			return journalingTypes.filter(j => box5JournalingTypes.value.includes(j.Canonical));
		case tabs.Box6:
			return journalingTypes.filter(j => box6JournalingTypes.value.includes(j.Canonical));
		case tabs.Box7:
			return journalingTypes.filter(j => box7JournalingTypes.value.includes(j.Canonical));
		default:
			return journalingTypes.filter(j =>
				Object.keys(
					validJournalReferencesByJournalType
				).includes(j.Canonical)
			)
				.sort((a, b) =>
					a.Canonical.localeCompare(
						b.Canonical
					)
				);
	}
}
export function GetClassOrSign(metadata: IAccountingMetaData[], getClass: boolean, useAmountVAT = true, tab?: tabs): string {
	const prefix = "GetClassOrSign()\n";
	log.trace(prefix + "metadata:", metadata);

	const positive = getClass ? 'highlight-green-bold' : '(+) ';
	const negative = getClass ? 'failure' : '(-) ';

	tab = tab ?? currentTab.value;
	switch (tab) {
		case tabs.Summary:
		case tabs.Box1:
		case tabs.Box4:
			// Apply class to AmountVAT
			var metadataForCurrentTab = metadata.find(metadata => {
				log.trace(`accountingBox: ${metadata.AccountingBox.toString}\ncurrentTab: ${tab!.toString()}\n${metadata.AccountingBox.toString() == tab!.toString()}}`)
				return metadata.AccountingBox.toString() == tab!.toString();
			});
			log.trace(prefix + "metadataForCurrentTab:", metadataForCurrentTab);
			if (metadataForCurrentTab?.UseAmountVAT && useAmountVAT) {
				return metadataForCurrentTab?.IsIncrease ? positive : negative;
			} else {
				return '';
			}
		case tabs.Box3:
			// Apply class to AmountVAT
			var metadataForCurrentTab = metadata.find(metadata => {
				log.trace(`accountingBox: ${metadata.AccountingBox.toString}\ncurrentTab: ${tab!.toString()}\n${metadata.AccountingBox.toString() == tab!.toString()}}`)
				return (
					(metadata.AccountingBox.toString() == tabs.Box1.toString())
					|| (metadata.AccountingBox.toString() == tabs.Box2.toString())
				);
			});
			log.trace(prefix + "metadataForCurrentTab:", metadataForCurrentTab);
			if (metadataForCurrentTab?.UseAmountVAT && useAmountVAT) {
				return metadataForCurrentTab?.IsIncrease ? positive : negative;
			} else {
				return '';
			}
		case tabs.Box5:
			// Tab 5 is Tab 3 - Tab 4
			// Therefore, reverse the class for Tab 4
			var metadataForTab3 = metadata.find(metadata => {
				log.trace(`accountingBox: ${metadata.AccountingBox.toString}\ncurrentTab: ${tab!.toString()}\n${metadata.AccountingBox.toString() == tab!.toString()}}`)
				return (
					(metadata.AccountingBox.toString() == tabs.Box1.toString())
					|| (metadata.AccountingBox.toString() == tabs.Box2.toString())
				);
			});
			log.trace(prefix + "metadataForTab3:", metadataForTab3);

			var metadataForTab4 = metadata.find(metadata => {
				log.trace(`accountingBox: ${metadata.AccountingBox.toString}\ncurrentTab: ${tab!.toString()}\n${metadata.AccountingBox.toString() == tab!.toString()}}`)
				return (
					metadata.AccountingBox.toString() == tabs.Box4.toString()
				);
			});
			log.trace(prefix + "metadataForTab4:", metadataForTab4);

			if (metadataForTab3?.UseAmountVAT && useAmountVAT) {
				return metadataForTab3?.IsIncrease ? positive : negative;
			} else if (metadataForTab4?.UseAmountVAT && useAmountVAT) {
				// Reverse class for Tab 4
				return metadataForTab4?.IsIncrease ? negative : positive;
			} else {
				return '';
			}
		case tabs.Box6:
		case tabs.Box7:
			// Apply class to AmountExVAT
			var metadataForCurrentTab = metadata.find(metadata => {
				log.trace(`accountingBox: ${metadata.AccountingBox.toString}\ncurrentTab: ${tab!.toString()}\n${metadata.AccountingBox.toString() == tab!.toString()}}`)
				return metadata.AccountingBox.toString() == tab!.toString();
			});
			log.trace(prefix + "metadataForCurrentTab:", metadataForCurrentTab);
			if (!metadataForCurrentTab?.UseAmountVAT && !useAmountVAT) {
				return metadataForCurrentTab?.IsIncrease ? positive : negative;
			} else {
				return '';
			}
		default:
			return '';
	}

}
export function GetClassOrSignBackoutCompatible(journalVAT: JournalVAT, amount: number | undefined, getClass: boolean, useAmountVAT = true, tab?: tabs): string {
	const prefix = "GetClassOrSignBackoutCompatible()\n";

	const metadata: IAccountingMetaData[] = journalVAT.JournalVATMetaData.AccountingMetaData;
	log.trace(prefix + "metadata:", metadata);

	function GetResult(isIncrease: boolean): string {
		return (
			isIncrease
				? getClass ? 'highlight-green-bold' : '(+) '
				: getClass ? 'failure' : '(-) '
		) + (
				amount == undefined ? "" : AbsoluteValue(MonetaryValueWithCommas(amount))
			);
	}

	tab = tab ?? currentTab.value;
	switch (tab) {
		case tabs.Summary:
		case tabs.Box1:
		case tabs.Box4:
			// Apply class to AmountVAT
			var metadataForCurrentTab = metadata.find(metadata => {
				log.trace(`accountingBox: ${metadata.AccountingBox.toString}\ncurrentTab: ${tab!.toString()}\n${metadata.AccountingBox.toString() == tab!.toString()}}`)
				return metadata.AccountingBox.toString() == tab!.toString();
			});
			log.trace(prefix + "metadataForCurrentTab:", metadataForCurrentTab);
			if (metadataForCurrentTab?.UseAmountVAT && useAmountVAT) {
				var isIncrease: boolean = metadataForCurrentTab?.IsIncrease;
				isIncrease = journalVAT.AmountVat >= 0 ? isIncrease : !isIncrease;
				return GetResult(isIncrease);
			} else {
				return amount == undefined ? '' : MonetaryValueWithCommas(amount);
			}
		case tabs.Box3:
			// Apply class to AmountVAT
			var metadataForCurrentTab = metadata.find(metadata => {
				log.trace(`accountingBox: ${metadata.AccountingBox.toString}\ncurrentTab: ${tab!.toString()}\n${metadata.AccountingBox.toString() == tab!.toString()}}`)
				return (
					(metadata.AccountingBox.toString() == tabs.Box1.toString())
					|| (metadata.AccountingBox.toString() == tabs.Box2.toString())
				);
			});
			log.trace(prefix + "metadataForCurrentTab:", metadataForCurrentTab);
			if (metadataForCurrentTab?.UseAmountVAT && useAmountVAT) {
				var isIncrease: boolean = metadataForCurrentTab?.IsIncrease;
				isIncrease = journalVAT.AmountVat >= 0 ? isIncrease : !isIncrease;
				return GetResult(isIncrease);
			} else {
				return amount == undefined ? '' : MonetaryValueWithCommas(amount);
			}
		case tabs.Box5:
			// Tab 5 is Tab 3 - Tab 4
			// Therefore, reverse the class for Tab 4
			var metadataForTab3 = metadata.find(metadata => {
				log.trace(`accountingBox: ${metadata.AccountingBox.toString}\ncurrentTab: ${tab!.toString()}\n${metadata.AccountingBox.toString() == tab!.toString()}}`)
				return (
					(metadata.AccountingBox.toString() == tabs.Box1.toString())
					|| (metadata.AccountingBox.toString() == tabs.Box2.toString())
				);
			});
			log.trace(prefix + "metadataForTab3:", metadataForTab3);

			var metadataForTab4 = metadata.find(metadata => {
				log.trace(`accountingBox: ${metadata.AccountingBox.toString}\ncurrentTab: ${tab!.toString()}\n${metadata.AccountingBox.toString() == tab!.toString()}}`)
				return (
					metadata.AccountingBox.toString() == tabs.Box4.toString()
				);
			});
			log.trace(prefix + "metadataForTab4:", metadataForTab4);

			if (metadataForTab3?.UseAmountVAT && useAmountVAT) {
				var isIncrease: boolean = metadataForTab3?.IsIncrease;
				isIncrease = journalVAT.AmountVat >= 0 ? isIncrease : !isIncrease;
				return GetResult(isIncrease);
			} else if (metadataForTab4?.UseAmountVAT && useAmountVAT) {
				// Reverse class for Tab 4
				var isIncrease: boolean = !metadataForTab4?.IsIncrease;
				isIncrease = journalVAT.AmountVat >= 0 ? isIncrease : !isIncrease;
				return GetResult(isIncrease);
			} else {
				return amount == undefined ? '' : MonetaryValueWithCommas(amount);
			}
		case tabs.Box6:
		case tabs.Box7:
			// Apply class to AmountExVAT
			var metadataForCurrentTab = metadata.find(metadata => {
				log.trace(`accountingBox: ${metadata.AccountingBox.toString}\ncurrentTab: ${tab!.toString()}\n${metadata.AccountingBox.toString() == tab!.toString()}}`)
				return metadata.AccountingBox.toString() == tab!.toString();
			});
			log.trace(prefix + "metadataForCurrentTab:", metadataForCurrentTab);
			if (!metadataForCurrentTab?.UseAmountVAT && !useAmountVAT) {
				var isIncrease: boolean = metadataForCurrentTab?.IsIncrease!;
				isIncrease = journalVAT.AmountVat >= 0 ? isIncrease : !isIncrease;
				return GetResult(isIncrease);
			} else {
				return amount == undefined ? '' : MonetaryValueWithCommas(amount);
			}
		default:
			return amount == undefined ? '' : MonetaryValueWithCommas(amount);
	}

}
function AbsoluteValue(number: string) {
	return number.startsWith("-")
		? number.slice(1)
		: number;
}
