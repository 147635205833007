export interface IFileType {
    ID: string;
    APIName: string;
    DisplayName: string;
}

export class FileType implements IFileType {
    ID: string;
    APIName: string;
    DisplayName: string;
    constructor(ID: string, APIName: string, DisplayName: string) {
        this.ID = ID;
        this.APIName = APIName;
        this.DisplayName = DisplayName;
    }
}

export const fileTypes: FileType[] = [
    new FileType("CAR_SALE_CREDIT_NOTE", "CarSaleCreditNote", "Car Sale Credit Note"),
    new FileType("CAR_SALE_INVOICE", "CarSaleInvoice", "Car Sale Invoice"),
    new FileType("CAR_SALE_INVOICE_LIST", "CarSalesInvoiceList", "Car Sales Invoice List"),
    new FileType("CHURN", "Churn", "Churn"),
    new FileType("CONTRACT", "Contract", "Contract"),
    new FileType("CUSTOMER_CREDIT_NOTE", "CreditNote", "Credit Note"),
    new FileType("CUSTOMER", "Customer", "Customer"),
    new FileType("CUSTOMER_INVOICE", "CustomerInvoice", "Customer Invoice"),
    new FileType("DUNNING_NOTICE", "DunningNotice", "Dunning Notice"),
    new FileType("FINAL_SETTLEMENT", "FinalSettlement", "Final Settlement"),
    new FileType("HIRE_AGREEMENT", "HireAgreement", "Hire Agreement"),
    new FileType("CUSTOMER_PAYMENT_ADVICE", "PaymentAdvices", "Payment Advices"),
    new FileType("CUSTOMER_PAYMENT", "Payments", "Payments"),
    new FileType("RV", "RV", "RV"),
    new FileType("RVGF", "RVGF", "RVGF"),
    new FileType("RECHARGE_INVOICE", "RechargeInvoice", "Recharge Invoice"),
    new FileType("REMARKETING_INVOICE", "RemarketingInvoice", "Remarketing Invoice"),
    new FileType("SERVICE_INVOICE", "ServiceInvoice", "Service Invoice"),
    new FileType("VEHICLE", "Vehicle", "Vehicle"),
    new FileType("TERMS_CONSENTED", "TermsConsented", "Terms & Conditions"),
];
