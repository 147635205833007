import IContractTable from "@/Interfaces/CustomersAndContracts/IContractTable";
import { extractNonArraysFromObj } from '@/services/helpers/ObjectHelper';
import DateIgnoreTime from "@/types/DateIgnoreTime";
import IContractDownPaymentTable from "@/Interfaces/CustomersAndContracts/IContractDownPaymentTable";
import IContractDownPaymentScheduleTable from "@/Interfaces/CustomersAndContracts/IContractDownPaymentScheduleTable";
import '@/types/Array';

export default class ContractTable implements IContractTable {
    CONTRACT_ID: string;
    INITIAL_DATE: DateIgnoreTime;
    ACTIVATION_DATE: DateIgnoreTime;
    TERMINATION_NOTICE_DATE: DateIgnoreTime;
    TERMINATION_DATE: DateIgnoreTime;
    CANCELLATION_DATE: DateIgnoreTime;
    VALID_FROM_DATE: DateIgnoreTime;
    BACBOOK_ORIGINAL_ACTIVATION_DATE: DateIgnoreTime;
    constructor(data: IContractTable) {
        Object.assign(this,extractNonArraysFromObj(data));
        this.CONTRACT_ID = data.CONTRACT_ID;
        this.INITIAL_DATE=new DateIgnoreTime(data.INITIAL_DATE);
        this.ACTIVATION_DATE=new DateIgnoreTime(data.ACTIVATION_DATE);
        this.TERMINATION_NOTICE_DATE=new DateIgnoreTime(data.TERMINATION_NOTICE_DATE);
        this.TERMINATION_DATE=new DateIgnoreTime(data.TERMINATION_DATE);
        this.CANCELLATION_DATE=new DateIgnoreTime(data.CANCELLATION_DATE);
        this.VALID_FROM_DATE=new DateIgnoreTime(data.VALID_FROM_DATE);
        this.BACBOOK_ORIGINAL_ACTIVATION_DATE=new DateIgnoreTime(data.BACBOOK_ORIGINAL_ACTIVATION_DATE);
        
        const groups = data.ContractDownPaymentScheduleTable?.groupBy('CONTRACT_ID');
        if (groups) data.ContractDownPaymentTable?.forEach(x=>x.downpayments = groups[x.CONTRACT_ID]);
    }
}
