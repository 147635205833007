import { AxiosResponse } from "axios";
import { financialSystemNetworkService } from "@/services/helpers/Helper";
import log from "loglevel";
import { Log2API } from "@/services/Logger";
import { Buffer } from "buffer";
import { IJournalingBillInvoice } from "@/Interfaces/Invoices/IJournalingBillInvoice";
import IJournalingBillInvoiceResponse from "@/Interfaces/Invoices/IJournalingBillInvoiceResponse";
import IDownloadJournaledInvoiceBody from "@/Interfaces/IDownloadJournaledInvoiceBody";
import IPdfFileResponse from '@/Interfaces/IpdfFileResponse';
import { DisplayableKeys } from "../SorterService";
import DateIgnoreTime from "@/types/DateIgnoreTime";

export const cols: DisplayableKeys<IJournalingBillInvoice>[] = [
	"InvoiceId",
    "Reference",
	"IssueDateTimeDate",
];
export const labels = [
	"Invoice Number",
	"Reference",
    "Date",
];

export function ParseItems(item: IJournalingBillInvoice) {
    // Convert Date from string to Date
    item.IssueDateTimeDate = new DateIgnoreTime(item.IssueDateTime);
    return item;
}

export class JournaledInvoicesServices {
	Logger = new Log2API();

	public async GetInvoiceByDate(dateRange: Date[], journalingType: string): Promise<IJournalingBillInvoiceResponse> {
		log.trace("GetInvoiceByDate()\ndateRange", dateRange, "\njournalingType", journalingType);

		const dateFrom: string = dateRange[0].toLocaleString('sv-SE').split(" ")[0];
		const dateTo: string = dateRange[1].toLocaleString('sv-SE').split(" ")[0];

		const urlParams: string = process.env.VUE_APP_INVOICES_DATE_URL_PARAMS + "/" + journalingType + `?dateFrom=${dateFrom}&dateTo=${dateTo}`;
		const data: IJournalingBillInvoiceResponse = (await financialSystemNetworkService.get(urlParams)).data;
		data.Invoices.forEach(x=>x.Reference = x.HasFileData ? `${x.InvoiceId}.pdf` : x.Reference);
		return data;
	}

	public async GetInvoiceBillJournals(
		journalReference: string
	): Promise<IJournalingBillInvoiceResponse> {
		log.trace(`GetInvoiceBillJournals()\njournalReference: ${journalReference}`);

		const urlParams: string = process.env.VUE_APP_JOURNALED_INVOICES_URL_PARAMS + "/" + journalReference + '?';
		const data: IJournalingBillInvoiceResponse = (await financialSystemNetworkService.get(urlParams)).data;
		data.Invoices.forEach(x=>x.Reference = x.HasFileData ? `${x.InvoiceId}.pdf` : x.Reference);
		return data;
	}
	public DownloadInvoiceBillJournals(checkedRows: string[], zip: boolean, journalingType: string, documentType: string = ""): Promise<string> {		log.trace("DownloadInvoiceBillJournals()");
		
		// {{url}}/invoice-data/download";
		const url = process.env.VUE_APP_JOURNALED_INVOICES_DOWNLOAD_URL_PARAMS!;

		const body: IDownloadJournaledInvoiceBody = {
			InvoiceIds: checkedRows,
            ZipFile: zip,
			JournalingType: journalingType,
			DocumentType: documentType
		}
        log.debug("DownloadInvoiceBillJournals()\nbody", body);
		
        return new Promise((resolve, reject) => {
			this.GetInvoiceBillJournalPdfFileUrl(url, body)
				.then((url: string) => {
					log.debug("DownloadInvoiceBillJournals()\nurl", url);
					resolve(url);
				})
				.catch((error: any) => {
					reject(error);
				});
		});
	}

	public async GetInvoiceBillJournalPdfFileUrl(urlParams: string, body: IDownloadJournaledInvoiceBody): Promise<string> {
        log.trace("GetInvoiceBillJournalPdfFileUrl()\n urlParams", urlParams, "\nbody", body);
      
		const url: string = await this.ProcessInvoiceBillJournalPdfData(urlParams, body)
			.then((byteArray: ArrayBuffer) => {
				const file: Blob = new Blob([byteArray], { type: "application/pdf" });
				const url = URL.createObjectURL(file);
				log.debug("GetInvoiceBillJournalPdfFileUrl()\nurl:", url);
				return url;
			})
			.catch((error: any) => {
				throw error;
			});

		return url;
	}
	private async ProcessInvoiceBillJournalPdfData(urlParams: string, body: IDownloadJournaledInvoiceBody): Promise<ArrayBuffer> {
        log.trace("ProcessInvoiceBillJournalPdfData()\n urlParams", urlParams, "\nbody", body);
		
		const res = await this.GetInvoiceBillJournalsPdfData(urlParams, body);
		const buffer = res.data;
		
		const contentType = res.headers["content-type"]?.toString();
		log.debug('ProcessInvoiceBillJournalPdfData, res', res, contentType);
		if (contentType?.startsWith('application/json') || contentType?.startsWith('text/plain')) {
			// @ts-ignore Buffer Argument of type 'Uint8Array' is not assignable to parameter of type 'number[]'

			const decoder = new TextDecoder('utf-8'); // Specify encoding if necessary
			const decodedString = decoder.decode(new Uint8Array(buffer));
			const pdfRes = JSON.parse(decodedString);

			log.debug('ProcessInvoiceBillJournalPdfData', pdfRes);
			const byteArray = Buffer.from(pdfRes.Data, "base64");
			return byteArray;
		} else {
			return buffer;
		}
	}

    public async GetInvoiceBillJournalsPdfData(urlParams: string, body: IDownloadJournaledInvoiceBody): Promise<AxiosResponse<ArrayBuffer>> {

		return await financialSystemNetworkService.post(body, urlParams, undefined, false, 'arraybuffer');
    }
}