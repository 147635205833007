import ICustomerCreditNoteTable from "@/Interfaces/CustomersAndContracts/ICustomerCreditNoteTable";
import { extractNonArraysFromObj } from '@/services/helpers/ObjectHelper';
import DateIgnoreTime from "@/types/DateIgnoreTime";

export default class CustomerCreditNoteTable implements ICustomerCreditNoteTable{
    CUSTOMER_INVOICE_ID: string;
    CREDIT_NOTE_NUMBER: string;
    ISSUE_DATE: DateIgnoreTime;
    DUE_DATE: DateIgnoreTime;

    constructor(data: ICustomerCreditNoteTable) {
        Object.assign(this,extractNonArraysFromObj(data));
        this.CUSTOMER_INVOICE_ID = data.CUSTOMER_INVOICE_ID;
        this.CREDIT_NOTE_NUMBER = data.CREDIT_NOTE_NUMBER;
        this.ISSUE_DATE=new DateIgnoreTime(data.ISSUE_DATE);
        this.DUE_DATE=new DateIgnoreTime(data.DUE_DATE);
    }
}
