import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { Log2API } from "@/services/Logger";
import { apiLoggerKey, loggerKey } from "./types/ServiceKeys";
import log from "loglevel";

const Logger = new Log2API();

export function useAPILogger() {
    return Logger;
}

createApp(App)
    .provide("log", log)
    .provide("Logger", Logger)
    .provide(loggerKey, log)
    .provide(apiLoggerKey, Logger)
    .use(router)
    .mount("#app");
