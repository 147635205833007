import { MarketSelection, isMarketSupported } from "@/services/helpers/Helper";
import DateIgnoreTime from "@/types/DateIgnoreTime";
import DatePeriod from "@/types/DatePeriod";
import IPaymentItem from "./IPaymentItem";
import IChurnPaymentItem from "./IChurnPaymentItemRaw";

export default class ChurnPaymentItem implements IPaymentItem {
    constructor(raw: IChurnPaymentItem) {
        Object.assign(this, raw);
        if (!isMarketSupported(raw.market)) {
            console.error(`market ${raw.market} is unrecognised`, raw);
            this.market = 'GB';
        } else this.market = raw.market;
        this.periodMonth = new DatePeriod(raw.periodMonth);
        this.dueDate = new DateIgnoreTime(raw.dueDate);
        this.invoiceId = raw.invoiceNumber;
    }
    journalInvoiceReference!: string;
    invoiceNumber!: string;
    invoiceId: string;
    periodMonth: DatePeriod;
    balance!: string;
    market: keyof typeof MarketSelection;
    dueDate: DateIgnoreTime;
}