<template class="VatLoans">
    <div class="tabs-container">
        <div class="top-tabs tabs-buttons">
            <div class="tabs">
                <button
                    :class="`button-left ${showOutstanding ? 'selected' : 'unselected'}`"
                    :disabled="showOutstanding"
                    :title="
                        showOutstanding
                            ? 'Currently viewing outstanding VAT Loans'
                            : 'Click to view Repaid VAT Loans'
                    "
                    @click="ToggleTabs()"
                >
                    Outstanding
                </button>
            </div>
            <div class="tabs">
                <button
                    :class="`button-right ${showOutstanding ? 'unselected' : 'selected'}`"
                    :disabled="!showOutstanding"
                    :title="
                        !showOutstanding
                            ? 'Currently viewing Repaid VAT Loans'
                            : 'Click to view Outstanding VAT Loans'
                    "
                    @click="ToggleTabs()"
                >
                    Repaid
                </button>
            </div>
        </div>
    </div>

    <div class="content-container">
        <GettingErrorInfo
            :showError="!!infoError"
            errorDescription="(Getting Vat Loans)"
            :error="infoError"
            :change="!!infoError"
        />
        <div class="top-content">
            <div class="flex-row-centered">
                <MarketSelector @change="updateSelection" enableSE />
                <div v-show="confirmVisible" class="center-buttons">
                    <button @click="proceedToPayment" class="incident-nav-button">
                        Confirm Payment
                    </button>
                </div>
            </div>
        </div>

        <div class="table-container">
            <TablePersistentChecking
                ref="table"
                :Rows="showOutstanding ? outstandingRows : repaidRows"
                :Cols="showOutstanding ? colsLoans : colsRepaidLoans"
                :HasCheckBox="true"
                :HasError="!!infoError"
                :SingleSelection="true"
                :CheckCallBack="updateCheckedRows"
                SplitColKeys
                :RowsPerPage="showOutstanding ? 40 : 10"
            >
                <template #DrawdownDate="props">
                    <div>
                        {{
                            props.value.DrawdownDate!.toLocaleString("sv-SE").split(" ")[0]
                        }}
                    </div>
                </template>
            </TablePersistentChecking>

            <div v-if="repayments" style="padding-top: 1rem">
                <label>
                    <h3 class="header">Loan Re-Payments:</h3>
                </label>

                <TablePersistentChecking
                    ref="secondTable"
                    :Rows="repayments"
                    :Cols="repaymentCols"
                    SplitColKeys
                />
            </div>

            <LoanPaymentPopUp v-model="selectedRow" @confirm="refreshAfterConfirm" />
        </div>
    </div>
</template>

<script setup lang="ts">
import MarketSelector from "@/components/MarketSelector.vue";
import { ITableExpose } from "@/components/TablePersistentChecking.vue";
import { DrawdownHelper } from "@/services/helpers/DrawdownHelper";
import { marketSelection } from "@/services/helpers/Helper";
import { computed, onMounted, ref } from "vue";
import TablePersistentChecking from "@/components/TablePersistentChecking.vue";
import { VatLoan } from "@/models/VatLoan";
import DatePeriod from "@/types/DatePeriod";
import ErrorInfo from "@/components/ErrorInfo.vue";
import { GetAuthLevel } from "@/config/AuthConfig";
import { authService } from "@/auth/authSingleton";
import LoanPaymentPopUp from "@/components/VATLoan/LoanPaymentPopUp.vue";
import { VatLoanRepayment } from "@/models/Ledgering/VatLoanRepayment";

const showOutstanding = ref(true);
const urlParams = process.env.VUE_APP_GET_VAT_LOAN_REPAYMENT_URL_PARAMS;

//Services
const DrawdownServices: DrawdownHelper = new DrawdownHelper();
const userRoleLevel = GetAuthLevel(authService.getUsersRole());
const confirmVisible = computed(() => userRoleLevel > 4 && showOutstanding.value);

// ERRORS
const GettingErrorInfo = ErrorInfo;
const infoError = ref();

// TABLE
const isLoading = ref(false);
const table = ref<ITableExpose<VatLoan>>();
const checkedRow = ref<VatLoan>();
const selectedRow = ref<VatLoan | undefined>();
const allLoans = ref<VatLoan[]>([]);

const outstandingRows = ref<VatLoan[]>([]);
const repaidRows = ref<VatLoan[]>([]);
const colsLoans: Array<keyof VatLoan> = [
    "Market",
    "DrawdownDate",
    "VatLoanAmount",
    "VatLoanPeriod",
    "RemainingBalance",
    "UserEmail"
];
const colsRepaidLoans: Array<keyof VatLoan> = [
    "Market",
    "DrawdownDate",
    "VatLoanAmount",
    "VatLoanPeriod",
    "UserEmail"
];
const repaymentCols: Array<keyof VatLoanRepayment> = [
    "RepaymentDate",
    "RepaymentAmount",
    "RepaymentPeriod",
    "UserEmail"
];
const repayments = computed(() => {
    if (!checkedRow.value || checkedRow.value.Repayments?.length == 0) {
        return undefined;
    }

    return checkedRow.value.Repayments
        ? checkedRow.value.Repayments.map((item) => ({
              RepaymentAmount: item.RepaymentAmount,
              RepaymentDate: item.RepaymentDate,
              RepaymentPeriod: new DatePeriod(item.RepaymentPeriod),
              UserEmail: item.UserEmail,
          }))
        : undefined;
});

onMounted(async () => {
    getVatLoans();
});

async function getVatLoans() {
    try {
        const data = await DrawdownServices.getVatLoans(
            urlParams!,
            marketSelection.value
        );
        allLoans.value = data.map((x) => new VatLoan(x));

        repaidRows.value = allLoans.value.filter((x) => x.RemainingBalance === 0);
        outstandingRows.value = allLoans.value.filter((x) => x.RemainingBalance! > 0);
    } catch (error) {
        infoError.value = error;
    } finally {
        isLoading.value = false;
    }
}

function updateCheckedRows() {
    checkedRow.value = table.value?.GetChecked()[0];
}

function updateSelection() {
    getVatLoans();
}

function proceedToPayment() {
    if (!checkedRow.value) {
        window.alert("Warning: no payment has been selected to confirm yet.");
    }
    selectedRow.value = checkedRow.value;
}

const tabsKey = ref(0);
function ToggleTabs() {
    tabsKey.value += 1;
    ResetTabs();
}

function ResetTabs() {
    showOutstanding.value = !showOutstanding.value;
    setTimeout(() => {
        table.value?.ResetSort();
    }, 0);
}
function refreshAfterConfirm() {
    checkedRow.value = undefined;
    selectedRow.value = undefined;
    getVatLoans();
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/layout.scss";
@import "@/assets/styles/buttons.scss";
</style>
