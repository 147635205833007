import { authService } from "@/auth/authSingleton";
import { DrawdownTableItem } from "@/models/Drawdown";
import { AxiosResponse } from "axios";
import { financialSystemNetworkService } from "./Helper";
import IConfirmDrawdownRequest from "@/Interfaces/VATLoans/IConfirmDrawdownRequest";
import IDrawdownItem from "@/Interfaces/VATLoans/IDrawdownItem";
import IVatLoanItem from "@/Interfaces/VATLoans/IVatLoan";
import IConfirmLoanPaymentRequest from "@/Interfaces/Ledgering/IConfirmLoanPaymentRequest";

export class DrawdownHelper {
    public async ConfirmDrawdown(urlParams: string, selectedDrawdown: DrawdownTableItem): Promise<AxiosResponse> {
        const drawdownDate = new Date(selectedDrawdown.DrawdownDate);
        const drawdownPeriod = new Date(drawdownDate.getFullYear(), drawdownDate.getMonth(), 1);

        const payload: IConfirmDrawdownRequest = {
            Market: selectedDrawdown.Market,
            DrawdownAmount: selectedDrawdown.DrawdownAmount,
            DrawdownDate: selectedDrawdown.DrawdownDate.toLocaleString('sv-SE').split(" ")[0],
            DrawdownPeriod: drawdownPeriod.toLocaleString('sv-SE').split(" ")[0],
            UserEmail: authService.getEmailAddress(),
            VatLoans: selectedDrawdown.VatLoans,
        };

        return new Promise((resolve, reject) => {
            financialSystemNetworkService.post(payload, urlParams)
                .then((res: AxiosResponse<any>) => {
                    resolve(res)
                })
                .catch((error: any) => {
                    console.log(error);
                    reject(error);
                })
        })
    }

    public async getConfirmedDrawdowns(urlParams: string, market: string): Promise<IDrawdownItem[]> {
        return new Promise((resolve, reject) => {
            financialSystemNetworkService.get(`${urlParams}/${market}`)
                .then((res: AxiosResponse<IDrawdownItem[]>) => {
                    const data = res.data;
                    resolve(data ? data : [])
                })
                .catch((error: any) => {
                    reject(error);
                })
        })
    }

    public async getVatLoans(urlParams: string, market: string): Promise<IVatLoanItem[]> {
        return new Promise((resolve, reject) => {
            financialSystemNetworkService.get(`${urlParams}/${market}`)
                .then((res: AxiosResponse<IVatLoanItem[]>) => {
                    const data = res.data;
                    resolve(data ? data : [])
                })
                .catch((error: any) => {
                    reject(error);
                })
        })
    }

    public async confirmPayment(urlParams: string, payload: IConfirmLoanPaymentRequest) {
        return new Promise((resolve, reject) => {
            financialSystemNetworkService.post(payload, urlParams)
            .then((res: AxiosResponse<any>) => {
                resolve(res)
            })
                .catch((error: any) => {
                    reject(error);
                })
            }
        )
    }
}
