import { financialSystemNetworkService } from "../Helper";
import { AxiosResponse } from "axios";
import TransferPaymentTable from "@/models/Ledgering/TransferPaymentTable";
import ITransferPaymentsItem from "@/Interfaces/Ledgering/ITransferPaymentsItem";
import IConfirmLedgerTransferRequest from "@/Interfaces/Ledgering/IConfirmLedgerTransferRequest";
import { authService } from "@/auth/authSingleton";
import LedgerTransferStatus from "@/types/LedgerTransferStatus";
import { DisplayableKeys } from "@/services/SorterService";
import LedgerTransaction from "@/models/Ledgering/LedgerTransaction";

export function getCols(getHistory: boolean): DisplayableKeys<TransferPaymentTable>[] {
    const baseColumns: DisplayableKeys<TransferPaymentTable>[] = [
        "TransactionId",
        "Market",
        "Amount",
        "TransferTypeId",
        "AccountFrom",
        "AccountTo",
        "TargetDate"
    ];
    if (getHistory) {
        return [
            ...baseColumns,
            "ValueDate",
            "UserStatusUpdated",
            "DateTimeStatusUpdated"
        ];
    } else {
        return baseColumns;
    }
};
export function getLabels(getHistory: boolean): string[] {
    const baseLabels: string[] = [
        "Transaction ID",
        "Market",
        "Amount",
        "Transfer Type",
        "Account From",
        "Account To",
        "Target Date",
    ];
    if (getHistory) {
        return [
            ...baseLabels,
            "Value Date",
            "User Updated",
            "DateTime Updated",
        ]
    } else {
        return baseLabels
    }
};
export const transactionCols: (keyof LedgerTransaction)[] = [
    "paymentId",
    "transactionId",
    "accountId",
    "valueDate",
    "targetDate",
    "amount",
    "market",
    "currency",
    "paymentAdviceReference",
    "paymentReference",
    "transferTypeId",
    "itemType",
    "itemCategory",
    "categoryPeriod",
    "invoiceId",
    "creditNoteId",
    "contractId",
    "assignedTo",
    "transferInfo",
];
export function getColKeys(getHistory: boolean) {
    return getHistory ? "DateTimeStatusUpdated" : "TransactionId";
}
export function getSortCol(getHistory: boolean): keyof TransferPaymentTable {
    return getHistory ? "DateTimeStatusUpdated" : "TransactionId";
}
export function getDefaultSortCols(getHistory: boolean): Array<number> {
    return([
        getCols(getHistory).findIndex((col) => {
            return col === getSortCol(getHistory);
        }),
    ]);
}
export function getDefaultSortOrders(getHistory: boolean): Array<1 | -1> {
    return getHistory ? ([-1] as Array<1 | -1>) : ([1] as Array<1 | -1>);
}
export interface ICache {
    Data: TransferPaymentTable[];
}
export function ParseItems(item: TransferPaymentTable) {
    const [year, month, day] = `${item.TargetDate}`.split("-");
    return item;
}
export class TransferPaymentsHelper {
    public async getTransferPayments(urlParams: string, status: LedgerTransferStatus, market: string, TransferType:string = ""): Promise<ITransferPaymentsItem[]> {
        return new Promise((resolve, reject) => {
            financialSystemNetworkService.get(`${urlParams}?status=${status}&market=${market}&TransferType=${TransferType}`)
            .then((res: AxiosResponse<ITransferPaymentsItem[]>) => {
                    const data = res.data;
                    resolve(data ? data : []);
                })
                .catch((error: any) => {
                    reject(error);
                });
        })
    }
    public async putConfirmTransferPayments(TransactionIds: number[], selectedDate: string): Promise<AxiosResponse> {
        const urlParams: string = process.env
        .VUE_APP_CONFIRM_LEDGER_TRANSFER_PAYMENTS_URL_PARAMS ?? 'confirm-ledger-transfer';
        const payload: IConfirmLedgerTransferRequest = {
            TransactionIds: TransactionIds,
            ValueDate: selectedDate,
            UserEmail: authService.getEmailAddress(),
        };
        return new Promise((resolve, reject) => {
            financialSystemNetworkService.put(payload, urlParams)
            .then((res: AxiosResponse<any>) => {
                resolve(res)
            })
                .catch((error: any) => {
                    reject(error);
                })
            }
        )
    }
}