<template>
    <div class="group"><slot></slot></div>
</template>
<style lang="scss" scoped>
.group {
    display:flex;
    align-items: stretch;

    >:first-child {
        border-radius: var(--buttons-border-radius) 0px 0px var(--buttons-border-radius);
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border: var(--border-width) solid var(--primary-color-dark);
        margin-right: 0;
        &:focus-visible {
            outline-offset: 1px;
            z-index:2;
        }
    }
    >:not(:first-child):not(:last-child) {
        border-radius: 0 !important;
        border: var(--border-width) solid var(--primary-color-dark);
        border-left: none;
        border-color: var(--primary-color-dark);
        margin-left: 0;
        margin-right: 0;
        &:focus-visible {
            outline-offset: 1px;
            z-index:2;
        }
    }
    >:last-child {
        border-radius: 0px var(--buttons-border-radius) var(--buttons-border-radius) 0px;
        border: var(--border-width) solid var(--primary-color-dark);
        border-left: none;
        border-color: var(--primary-color-dark);
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        margin-left: 0;
        &:focus-visible {
            outline-offset: 1px;
            z-index:2;
        }
    }
    >* {
        box-sizing: border-box;
        flex: 1 1 auto;
    }
    :deep(>* input:not([type="checkbox"]):not([type="radio"])),
    :deep(>* select) {
        border: 0;
    }
}
</style>