<template>
    <div class="JournaledInvoices">
        <div class="content-container">
            <div class="top-content">
                <SearchForJournaledInvoicesErrorInfo
                    :showError=!!invoiceError
                    errorDescription="(Getting Invoices)"
                    :error=invoiceError
                    :change=!invoiceError
                    closeError
                />
                <DownloadJournaledInvoicesErrorInfo
                    :showError=!!downloadError
                    errorDescription="(Downloading Invoices PDFs)"
                    :error=downloadError
                    :change=!downloadError
                    closeError
                />

                <div class="flex-row-centered selection-form flex-row-start">
                    <div class="flex-col-centered input-container">
                        <TitledSelection for="journal-ref-id" title="Search for Journal Reference:">
                            <form id="journal-ref" @submit.prevent=searchByRef>
                                <GroupedInputButton>
                                    <input id="journal-ref-id"
                                        v-model="journalReference"
                                        class="selection-form-input"
                                        type="text"
                                        placeholder="Enter Journal Reference..."
                                        pattern=".*\S+.*"
                                        required
                                    />
                                <button title="Enter exactly 1 journal reference to search" class="selection-form-btn">Search</button>
                                </GroupedInputButton>
                            </form>
                        </TitledSelection>
                        <button class="default-btn" v-if=!disallowDownload
                            @click=download
                            :disabled=isDownloading
                            title="Download selected invoices as a zip file (select invoices using the table checkboxes)"
                        >Download Invoices</button>
                    </div>
                    <div class="flex-col-centered input-container">
                        <TitledSelection for="dp-input-journal-date" title="Search for Invoice by Date:">
                            <form id="journal-date" @submit.prevent=SearchInvoiceByDate>
                                <GroupedInputButton>
                                    <DatePicker uid="journal-date"
                                        v-model=dateRange 
                                        range  
                                        :enable-time-picker=false
                                        :format="FormatDatePicker"
                                        text-input
                                        placeholder="Select Date..."
                                        :text-input-options="textInputOptions"
                                        :partial-range=false
                                        :teleport=true
                                        required
                                    />
                                    <JournalTypeSelector v-model=journalTypeSelectionDate class="selection-form-input" required/>
                                    <button title="Pick start and end date by clicking on the date input box and then click search to look up invoices by date" class="selection-form-btn">Search</button>
                                </GroupedInputButton>
                            </form>
                        </TitledSelection>
                    </div>
                    <div class="flex-col-centered input-container">
                        <TitledSelection for="journal-number-input" title="View by Invoice Number:">
                            <form id="journal-number" @submit.prevent=viewFullPagePDFInvoice(invoiceID,journalTypeSelectionNumber)>
                                <GroupedInputButton>
                                    <input id="journal-number-input"
                                        class="selection-form-input"
                                        type="text"
                                        v-model="invoiceID"
                                        placeholder="Enter Invoice Number..."
                                        required
                                    />
                                    <JournalTypeSelector v-model=journalTypeSelectionNumber class="selection-form-input" required/>
                                    <button title="Enter exactly 1 invoice number to open its pdf file in a new tab" class="selection-form-btn">Search</button>
                                </GroupedInputButton>
                            </form>
                        </TitledSelection>
                    </div>
                </div>

                <div v-if="showDescription && !invoiceError" class="desc-error">
                    <div class="desc">
                        <div class="desc-header">NOTE:</div>
                        <div>
                            {{
                                `${description}`.endsWith(".")
                                    ? `${description}`.slice(0, -1)
                                    : `${description}`
                            }}.
                        </div>
                    </div>
                </div>
            </div>
            <Table ref="table"
                :Rows=displayedJournalBillInvoices
                :Cols="cols"
                :Labels="labels"
                :RowCheckableFilter="x=>x.HasFileData"
                HasCheckBox
                CheckIncludesNonDisplayed
                :HasError=!!invoiceError
            >
                <template #Reference="props">
                    <button
                        @click="ViewPdf(props.value)"
                        :disabled="pdfButtonStatuses[props.value.InvoiceId]"
                        class="fake-link"
                    >
                        {{ props.value.Reference }}
                    </button>
                </template>
            </Table>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, inject, computed } from "vue";
// Services
import { apiLoggerKey } from "@/types/ServiceKeys";
import {
    cols,
    labels,
    ParseItems,
    JournaledInvoicesServices,
} from "@/services/helpers/JournaledInvoicesHelper";
import { FunctionAccessLevels, DenyFunctionLevelAccess } from "@/config/AuthConfig";
import { FormatDatePicker } from "@/services/helpers/Helper";
// Data
import IJournalingBillInvoiceResponse from "@/interfaces/Invoices/IJournalingBillInvoiceResponse";
import {
    IJournalingBillInvoice,
    IJournalingBillInvoiceMetadata,
} from "@/interfaces/Invoices/IJournalingBillInvoice";
import INotFoundError from "@/Interfaces/Errors/INotFoundError";
// Table
import Table, { ITableExpose } from "@/components/TablePersistentChecking.vue";
// Misc
import log from "loglevel";
import router from "@/router";
import { AxiosError } from "axios";
import DatePicker from '@/components/common/DatePicker.vue';
import GroupedInputButton from "@/components/GroupedInputButton.vue";
import JournalTypeSelector from '@/components/Journals/JournalTypeSelector.vue';
import SearchForJournaledInvoicesErrorInfo from "@/components/ErrorInfo.vue";
import useNotifier from "@/services/composables/Notifier";
import useSpinnerPopup from "@/services/composables/SpinnerPopup";
import TitledSelection from "@/components/TitledSelection.vue";

const dateRange = ref<Date[]>();
const textInputOptions = ref({ format: 'yyyy/MM/dd'});

const fileName = "JournaledInvoices.vue";
const Logger = inject(apiLoggerKey);

const journalReference = ref('');

const invoiceID = ref('');

const journalTypeSelectionNumber = ref('');
const journalTypeSelectionDate = ref('');

// ERROR
const DownloadJournaledInvoicesErrorInfo = SearchForJournaledInvoicesErrorInfo;
const invoiceError = ref();
const downloadError = ref();

// DESCRIPTION
const showDescription = ref(false);
const description = ref<string>();
const journalingType = ref<string>();
const metadata = ref<IJournalingBillInvoiceMetadata>();

// TABLE
const isDownloading = ref(false);
const journalBillInvoices = ref<IJournalingBillInvoice[]>();
const displayedJournalBillInvoices = ref<IJournalingBillInvoice[]>();
const table = ref<ITableExpose<IJournalingBillInvoice>>();
const pdfButtonStatuses = ref<Record<string,boolean>>({});

// MISC
const disallowDownload = computed(()=>DenyFunctionLevelAccess(FunctionAccessLevels.DownloadInvoices));
const JIServices = new JournaledInvoicesServices();

onMounted(() => {
    log.trace("onMounted() Invoices");

    document.title = `Invoices - Optio`;

    journalBillInvoices.value = [];
    displayedJournalBillInvoices.value = journalBillInvoices.value;
});
function searchByRef() {
    if (journalReference.value.trim()) {
        log.debug(`search()\nSearching for ${journalReference.value}`);
        SearchForJournalReference();
        table.value?.ClearChecked();
    }
}
function download() {
    if (isDownloading.value) return;
    const checkedRows = table.value?.GetChecked();
    if (checkedRows && checkedRows.length) {
        log.debug(`download()\nDownloading ${checkedRows.length} files.`);
        DownloadInvoiceBillJournals();
    } else {
        const msg = 'No files selected to download.';
        log.debug(`${fileName}.download()\n${msg}`);
        useNotifier().warn({msg});
    }
}
async function SearchInvoiceByDate(){
    table.value?.ClearChecked();
    const name = SearchInvoiceByDate.name;
    const Class = fileName + "/" + name;
    var Message = `Searching for Invoices by Date for Journal Type ${journalTypeSelectionDate.value} and Date Range ${dateRange.value}.`;

    log.trace(`${name}()\n${Message}`);
    try {
        displayedJournalBillInvoices.value = undefined;
        const response = await JIServices.GetInvoiceByDate(dateRange.value!, journalTypeSelectionDate.value);
        log.debug("GetInvoiceByDate() Response:", response);
        displayedJournalBillInvoices.value = MapInvoiceBillJournals(response);
        Logger?.LogInformation(Message, Class);        
    } catch(error) {
        if (error instanceof AxiosError && error.response?.status == 400) {
            DisplayInvoiceBillJournalsNotFound(error as INotFoundError);
        } else {
            invoiceError.value = error;
            Message = `(Error) ${Message}: ${error}`;
            log.error(Message);
            Logger?.LogError(Message, Class);
        }
    }
}
async function SearchForJournalReference() {
    const name = SearchForJournalReference.name;
    const Class = fileName + "/" + name;
    var Message = `Searching for Invoices files (Journal Reference: ${journalReference.value}).`;

    log.trace(`${name}()\n${Message}`);
    try {
        displayedJournalBillInvoices.value = undefined;
        const response = await JIServices.GetInvoiceBillJournals(journalReference.value);
        log.debug("SearchForJournalReference() Response:", response);
        displayedJournalBillInvoices.value = MapInvoiceBillJournals(response);
        Logger?.LogInformation(Message, Class);
    } catch(error) {
        if (error instanceof AxiosError && error.response?.status == 400) {
            DisplayInvoiceBillJournalsNotFound(error as INotFoundError);
        } else {
            invoiceError.value = error;
            Message = `(Error) ${Message}: ${error}`;
            log.error(Message);
            Logger?.LogError(Message, Class);
        }
    }
}
function MapInvoiceBillJournals(response: IJournalingBillInvoiceResponse) {
    log.trace(`DisplayInvoiceBillJournals()`);

    ResetError();

    metadata.value = response.Metadata;
    description.value = metadata.value.Description;
    journalingType.value = metadata.value.JournalingType;
    showDescription.value = description.value.length > 0 ? true : false;

    journalBillInvoices.value = response.Invoices;
    const results = journalBillInvoices.value.map(ParseItems);
    log.debug(
        "SearchForJournalReference() displayedJournalBillInvoices:",
        results
    );
    return sortInvoiceBillJournals(results);
}
function DisplayInvoiceBillJournalsNotFound(response: INotFoundError) {
    log.trace(`DisplayInvoiceBillJournalsNotFound()`);

    ResetError();

    showDescription.value = true;
    console.log("Description:", response.response?.data.Error);
    description.value = response.response?.data.Error;

    displayedJournalBillInvoices.value = [];
}
function ResetError() {
    showDescription.value = false;
    invoiceError.value = undefined;
}
async function DownloadInvoiceBillJournals() {
    const name = DownloadInvoiceBillJournals.name;
    const Class = fileName + "/" + name;
    var Message = `Downloading Invoices files (Journal Reference: ${journalReference.value}).`;

    log.trace(`name()\n${Message}`);
    isDownloading.value = true;
    const closeSpinner = useSpinnerPopup().show();
    downloadError.value = undefined;
    try {
        //@ts-expect-error
        const checkedRows = table.value.GetChecked().map(x=>x.InvoiceId);
        if (checkedRows.length <= 0) return;
        // Get url (API call)
        const url = await JIServices.DownloadInvoiceBillJournals(
            checkedRows,
            checkedRows.length > 1,
            journalingType.value!
        );
        log.debug("DownloadInvoiceBillJournals()\njournalingType:", journalingType.value);

        // Create anchor element and set attributes
        const link = document.createElement("a");
        link.href = url;
        link.download =
            checkedRows.length > 1
                ? `${journalingType.value}.zip`
                : `${checkedRows.at(0) !== null && checkedRows.at(0)?.trim() !== '' ? checkedRows.at(0) : "Journaled_" + journalingType.value}.pdf`;

        // Trigger a click on the anchor element
        link.click();

        // Remove the anchor element
        link.remove();

        Logger?.LogInformation(Message, Class);
    } catch (error) {
        downloadError.value = error;
        Message = `${Message}: ${error}`;
        log.error(Message);
        Logger?.LogError(Message, Class);
    } finally {
        closeSpinner();
        isDownloading.value = false;
    }
}
function sortInvoiceBillJournals(results: IJournalingBillInvoice[]) {
    log.trace("sortInvoiceBillJournals()");

    return results.sort(
        (a, b) =>
            new Date(b.IssueDateTime).getTime() - new Date(a.IssueDateTime).getTime()
    );
}
// View full page PDF invoice in new tab
function viewFullPagePDFInvoice(fileID: string | undefined, fileType: string | undefined,) {
    log.trace("viewFullPagePDFInvoice()");
    log.debug(`"viewFullPagePDFInvoice()": ${fileID}, ${fileType}`);

    if (!fileType) {
        log.debug("viewFullPagePDFInvoice() - fileType is undefined")
        return;
    }

    const rResolved = router.resolve({
        name: "Invoice (PDF)",
        params: {
            id: fileID?.trim(),
        },
        query: {
            type: fileType,
            endpoint: process.env.VUE_APP_JOURNALED_INVOICES_DOWNLOAD_URL_PARAMS,
            method: "post"
        },
    });
    const url = rResolved.href;
    // Open in new tab
    log.debug("viewFullPagePDFInvoice() - Opening in new tab")
    window.open(url, "_blank");
}
// async function ViewPdf(invoiceName: string) {
async function ViewPdf(invoicedJournalData: IJournalingBillInvoice) {
    log.trace("ViewPdf()");

    const name = ViewPdf.name;
    const Class = fileName + "/" + name;

    // Disable button
    pdfButtonStatuses.value[invoicedJournalData.InvoiceId] = true;

    let url: string | void;
    // If Invoice Journal contains file data, open file in new tab using Full Page PDF
    if (invoicedJournalData.HasFileData) {
        viewFullPagePDFInvoice(invoicedJournalData.InvoiceId, journalingType.value);

        const Message = `Downloaded Invoices file (Invoice id: ${invoicedJournalData.InvoiceId}, Journal Reference: ${journalReference.value}).`;
        Logger?.LogInformation(Message, Class);
    }
    // If Invoice Journal does not contain file data, open url in new tab
    else if (!invoicedJournalData.HasFileData) {
        url = invoicedJournalData.Reference;

        window.open(url, "_blank");

        const Message = `Downloaded Invoices file (Invoice id: ${invoicedJournalData.InvoiceId}, Journal Reference: ${journalReference.value}).`;
        Logger?.LogInformation(Message, Class);
    }

    // Enable button
    pdfButtonStatuses.value[invoicedJournalData.InvoiceId] = false;
}
</script>
<style scoped lang="scss">
@import "@/assets/styles/selection-form-inputs.scss";
.flex-row-start {
    align-items: flex-start;
}
</style>