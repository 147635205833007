<template>
    <div class="EoPVerifications">
        <div v-if="isLoading" class="content-container">
            <div class="loading">
                <span class="loader"></span>
            </div>
        </div>
        <div v-else class="content-container">
            <div class="top-content">
                <GettingEoPVerificationsErrorInfo
                    :showError="!!infoError"
                    errorDescription="(Getting EoP Verifications)"
                    :error="infoError"
                    :change="!!infoError"
                />
                <div class="flex-row-centered">
                    <MarketSelector @change=updateSelection enableSE />
                    <PeriodSelector v-model=periodSelection showTitle @update:model-value=updateSelection />
                    <TitledSelection title="Originator Selection:" for="originatorSelector">
                        <select id="originatorSelector"
                            v-model="originatorSelection"
                            @change="updateSelection()"
                            class="dropdown"
                        >
                            <option
                                v-for="originator in originators"
                                :value="originator.val"
                                :key="originator.val"
                            >
                                {{ originator.text }}
                            </option>
                        </select>
                    </TitledSelection>
                </div>
            </div>
            <Table
                :Rows=displayedEoPVerificationsList
                :Cols=cols
                :HasError=!!infoError
                v-model:Page=page
                v-model:RowsPerPage=rowCount
                :Labels=labels
                :DefaultSortCols=defaultSortCols
                :DefaultSortOrders=defaultSortOrders
            >
                <template #Status="props">
                    <div v-if="props.value.Status === 'SUCCESS'" class="highlight-green-bold">
                        {{ props.value.Status }}
                    </div>
                    <div v-if="props.value.Status === 'ERROR'" class="failure">
                        {{ props.value.Status }}
                    </div>
                </template>
                <template #Report="props">
                    <button
                        @click="ViewEoPVerificationReport(props.value.JournalType, props.value.JournalName)"
                        :disabled="DenyFunctionLevelAccess(FunctionAccessLevels.ViewEoPVReport)"
                        :title="DenyFunctionLevelAccess(FunctionAccessLevels.ViewEoPVReport) ? `View ${props.value.JournalName} report (Access denied)` : `View ${props.value.JournalName} report (opens new page)`"
                        class="table-button"
                    >
                        Report
                    </button>
                </template>
            </Table>
        </div>
    </div>
</template>

<script setup lang="ts">
import {
    ref,
    onMounted,
    onBeforeMount,
    inject,
} from "vue";
import { onBeforeRouteLeave } from "vue-router";
import router from "@/router";
// Services
import { apiLoggerKey } from "@/types/ServiceKeys";
import {
    cols,
    labels,
    EoPVerificationsServices,
    parseData,
    defaultSortCols,
    defaultSortOrders,
} from "@/services/helpers/EoPVerificationsHelper";
import { FunctionAccessLevels, DenyFunctionLevelAccess } from "@/config/AuthConfig";
// Data
import { ISelection } from "@/Interfaces/ISelection";
import { MarketSelection, marketSelection } from "@/services/helpers/Helper";
// Table
import Table from "@/components/TablePersistentChecking.vue";
// Misc
import log from "loglevel";
import ErrorInfo from "@/components/ErrorInfo.vue";
import { IEoPItem, IEoPVerifications, ICache } from "@/Interfaces/EoPVerifications/IEoPVerifications";
import DatePeriod from "@/types/DatePeriod";
import MarketSelector from "@/components/MarketSelector.vue";
import PeriodSelector from "@/components/PeriodSelector.vue";
import TitledSelection from "@/components/TitledSelection.vue";

const page = ref(1);
const rowCount = ref(40);

const fileName = "EoPVerifications.vue";
const Logger = inject(apiLoggerKey);

////////////////////////////////////////////////////////////////////////////
// LOGIC
////////////////////////////////////////////////////////////////////////////
onBeforeRouteLeave((to, from, next) => {
    log.trace("onBeforeRouteLeave() EoPVerifications");

    // If leaving to go to "EoP Verification Report", save EoPVerifications in session storage
    if (to.name === "EoP Verification Report") {
        const cache: ICache = {
            Data: EoPVerifications.value,
            Metadata: {
                Market: marketSelection.value,
                Period: periodSelection.value.toJSON(),
                Originator: originatorSelection.value,
                PageNumber: page.value,
                RowCount: rowCount.value,
                SortCols: defaultSortCols.value,
                SortOrders: defaultSortOrders.value,
            },
        };
        log.debug("onBeforeRouteLeave()\ncache.Metadata", cache.Metadata);
        sessionStorage.setItem("EoPVerifications", JSON.stringify(cache));

        const periodCache = periodSelection.value;
        log.debug("onBeforeRouteLeave()\nperiodCache", periodCache);
        sessionStorage.setItem("EoPVerificationsPeriod", periodCache.toJSON());
    }
    next();
});

onBeforeMount(() => {
    log.trace("onBeforeMount() EoPVerifications");
    if (
        lastPage.value?.startsWith(EoPVerificationRoutePath!) ||
        previousPage.value?.startsWith(EoPVerificationRoutePath!)
    ) {
        if ("EoPVerifications" in sessionStorage) {
            log.debug("onBeforeMount() 'EoPVerifications' in sessionStorage");
        }
    } else {
        log.debug("onBeforeMount() removing 'EoPVerifications' from sessionStorage");
        sessionStorage.removeItem("EoPVerifications");
        sessionStorage.removeItem("EoPVerificationsPeriod");
    }
});

const EoPVerificationRoutePath = router.options.routes
    .find((route) => route.name === "EoP Verification Report")
    ?.path.split("/:")[0];
const previousPage = ref(router.options.history.state.back?.toString());
const lastPage = ref(router.options.history.state.forward?.toString());

onMounted(() => {
    log.debug("onMounted() EoPVerifications");
    document.title = `EoP Verifications - Optio`
    GetEoPVerifications();
});
// Get EoPVerifications data from API
async function GetEoPVerifications() {
    const name = GetEoPVerifications.name;
    const Class = fileName + "/" + name;
    infoError.value = undefined;
    const raw = sessionStorage.getItem("EoPVerifications");
    log.debug("Loading EoPVerification Reports from session storage", raw);
    if (raw) {
        const cache: ICache = JSON.parse(raw);
        const data = cache.Data;
        const metadata = cache.Metadata;

        EoPVerifications.value = data;

        marketSelection.value = (Object.keys(MarketSelection) as (keyof typeof MarketSelection)[]).find(
            m => m === metadata.Market
        ) ?? 'GB';
        periodSelection.value = new DatePeriod(metadata.Period);
        if (!periodSelection.value.isValid()) console.trace('periodSelection should not be invalid');
        originatorSelection.value = metadata.Originator!;

        page.value = metadata.PageNumber!;
        rowCount.value = metadata.RowCount!;
        defaultSortCols.value = metadata.SortCols!;
        defaultSortOrders.value = metadata.SortOrders!;
        displayedEoPVerificationsList.value = parseData(data);
        isLoading.value = false;
    } else {
        const periodStr = sessionStorage.getItem("EoPVerificationsPeriod");
        if (periodStr) periodSelection.value = new DatePeriod(periodStr);
        const market = marketSelection.value;
        const period = periodSelection.value;
        const company = originatorSelection.value;
        var Message = `Getting EoPVerifications (market: ${market}, period: ${period}, originator: ${company})`;
        log.debug("Loading EoPVerification Reports from API");
        isLoading.value = true;
        displayedEoPVerificationsList.value = undefined;
        if (!period.isValid()) console.trace('API periodSelection should not be invalid');
        try {
            const data = await EoPVServices.getEoPVerifications(market, period, company);
            EoPVerifications.value = data;
            displayedEoPVerificationsList.value = parseData(data);
            Logger?.LogInformation(Message, Class);
        } catch (error) {
            infoError.value = error;
            Message = `(Error) ${Message}, error: ${error}`;
            log.error(Message);
            Logger?.LogError(Message, Class);
        } finally {
            isLoading.value = false;
        }
    }    
}
//Route to the EoP Verification Report page with the selected Journal Type and parameters
function ViewEoPVerificationReport(JournalType: string, DisplayType: string) {
    log.trace("ViewEoPVerificationReport()\nId:", JournalType);
    const r = {
        name: "EoP Verification Report",
        params: {
            id: JournalType,
        },
        query: {
            market: marketSelection.value,
            period: periodSelection.value.toJSON(),
            company: originatorSelection.value,
            type: DisplayType
        },
    };
    // Open in current tab
    router.push(r);
}

// ERROR
const GettingEoPVerificationsErrorInfo = ErrorInfo;
const infoError = ref();

// TABLE
const isLoading = ref(true);
const EoPVerifications = ref<IEoPVerifications>();
const displayedEoPVerificationsList = ref<IEoPItem[]>();

// MISC
const EoPVServices: EoPVerificationsServices = new EoPVerificationsServices();

// Selector values defintions
const originators: ISelection[] = [{ text: "Volvo", val: "Volvo" }];

// Selector values ititialization
const periodSelection = ref(new DatePeriod(new Date().addMonths(-1)));
const originatorSelection = ref(originators[0].val);

function updateSelection() {
    log.trace("updateSelection()");

    log.debug("Removing EoPVerifications and EoPVerificationsPeriod from sessionStorage");
    sessionStorage.removeItem("EoPVerifications");
    sessionStorage.removeItem("EoPVerificationsPeriod");

    const name = updateSelection.name;
    const Message = `Changed selection to: market: ${marketSelection.value}; period: ${periodSelection.value}; originator: ${originatorSelection.value}.`;
    const Class = fileName + "/" + name;

    isLoading.value = true;
    GetEoPVerifications();
    Logger?.LogInformation(Message, Class);
}
</script>
