<template>
    <Popup v-model=displayingForm title="Create New Ledger Transaction" hasClose >
        <template #contents>
            <div class=form-container :class="{ 'hide-scroll':loading }">
                <div v-if=error class="error">Error loading options</div>
                <Spinner v-else-if=loading>Loading options</Spinner>
                <form :class="{hidden:loading}" class="two-column" @submit.prevent=onSubmit>
                    <label for="account">
                        Account Id: 
                    </label>
                    <select id="account" @change=onUpdated v-model=transaction.accountId required>
                        <option value='' disabled selected hidden>mandatory</option>
                        <option v-for="id of options?.accountIds"
                            :value=id 
                            :key=id >
                            {{ id }}
                        </option>
                    </select>
                    <label for="market">
                        Market: 
                    </label>
                    <select id="market" @change=onUpdated v-model=transaction.market required>
                        <option value='' disabled selected hidden>mandatory</option>
                        <option v-for="([k,v]) in Object.entries(MarketSelection)"
                            :value=k
                            :key=k >
                            {{ v }}
                        </option>
                    </select>
                    <label for="currency">
                        Currency: 
                    </label>
                    <select id="currency" @change=onUpdated v-model=transaction.currency required>
                        <option :value=null>Market Default</option>
                        <option v-for="id in options?.marketCurrencies"
                            :value=id 
                            :key=id >
                            {{ id }}
                        </option>
                    </select>
                    <label for="transfer-type">
                        Transfer Type Id:
                    </label>
                    <select id="transfer-type" @change=onUpdated v-model=transaction.transferTypeId required>
                        <option value='' disabled selected hidden>mandatory</option>
                        <option v-for="id in options?.transferTypes"
                            :value=id 
                            :key=id >
                            {{ id }}
                        </option>
                    </select>
                    <label for="item-type">
                        Item Type Id:
                    </label>
                    <select id="item-type" @change=onUpdated v-model=transaction.itemType required>
                        <option value='' disabled selected hidden>mandatory</option>
                        <option v-for="id in options?.itemTypes"
                            :value=id 
                            :key=id >
                            {{ id }}
                        </option>
                    </select>
                    <label for="item-category">
                        Item Category:
                    </label>
                    <select id="item-category" @change=onUpdated v-model=transaction.itemCategory required>
                        <option value='' disabled selected hidden>mandatory</option>
                        <option v-for="id in options?.itemCategories"
                            :value=id 
                            :key=id >
                            {{ id }}
                        </option>
                    </select>
                    <label for="assigned-to">
                        Assigned To:
                    </label>
                    <ClearableSelect id="assigned-to" @update:model-value=onUpdated v-model=transaction.assignedTo>
                        <option v-for="id in options?.contractOwnerIds"
                            :value=id 
                            :key=id >
                            {{ id }}
                        </option>
                    </ClearableSelect>
                    <label for="category-period">
                        Category Period:
                    </label>
                    <PeriodSelector id="category-period"
                        class=input 
                        hasNullSelector
                        nullSelectorName="NULL"
                        @change=onUpdated 
                        v-model=transaction.categoryPeriod
                    />
                    <label for="target-date">
                        Target Date:
                    </label>
                    <DatePicker class=input id="target-date" @update:model-value=onUpdated v-model=transaction.targetDate :enable-time-picker=false :teleport=true required placeholder="mandatory" />
                    <label for="value-date">
                        Value Date:
                    </label>
                    <DatePicker class=input id="value-date" @update:model-value=onUpdated v-model=transaction.valueDate :enable-time-picker=false :teleport=true />
                    <label for=transaction-id>
                        Transaction Id:
                    </label>
                    <input type="text" id="transfer-info" @input=onUpdated v-model=transaction.transactionId :pattern=transIdPattern placeholder=null>
                    <label for="transfer-info">
                        Transfer Info:
                    </label>
                    <input type="text" id="transfer-info" @input=onUpdated v-model="transaction.transferInfo">
                    <label for="payment-advice-ref">
                        Payment Advice Reference:
                    </label>
                    <input type="text" id="payment-advice-ref" @input=onUpdated v-model="transaction.paymentAdviceReference">
                    <label for="payment-advice-ref">
                        Payment Reference:
                    </label>
                    <input type="text" id="payment-ref" @input=onUpdated v-model="transaction.paymentReference">
                    <label for="invoice-id">
                        Invoice Id:
                    </label>
                    <input type="text" id="invoice-id" @input=onUpdated v-model="transaction.invoiceId">
                    <label for="credit-note-id">
                        Credit Note Id:
                    </label>
                    <input type="text" id="credit-note-id" @input=onUpdated v-model="transaction.creditNoteId">
                    <label for="contract-id">
                        Contract Id:
                    </label>
                    <input type="text" id="contract-id" @input=onUpdated v-model="transaction.contractId">
                    <label for="amount">
                        Amount:
                    </label>
                    <CurrencyInput v-model="transaction.amount"
                        @input=onUpdated
                        placeholder="mandatory"
                        :currency="transaction.currency ?? MarketCurrency[transaction.market ?? '']"
                        :step=0.0001
                        required
                    />
                    <button class="confirm-button" type="submit">Confirm</button>
                </form>
            </div>
        </template>
    </Popup>
</template>
<script setup lang="ts">
import '@vuepic/vue-datepicker/dist/main.css';
import {ref,computed,onMounted,watch} from 'vue';
import DatePicker from '@vuepic/vue-datepicker';
import Popup from '../Popup.vue';
import Spinner from '../Spinner.vue';
import CurrencyInput from '../CurrencyInput.vue';
import ClearableSelect from '../ClearableSelect.vue';
import PeriodSelector from '../PeriodSelector.vue';
import useSpinnerPopup from '@/services/composables/SpinnerPopup';
import ILedgerTransactionRequest from '@/Interfaces/Ledgering/ILedgerTransactionRequest';
import ILedgerTransactionOptions from '@/Interfaces/Ledgering/ILedgerTransactionOptions';
import { ledgerTransactionOptions, ledgerTransactions } from '@/services/network';
import { MarketSelection, marketSelection, MarketCurrency } from '@/services/helpers/Helper';
import LedgerTransaction from '@/models/Ledgering/LedgerTransaction';
import ILedgerTransaction from '@/Interfaces/Ledgering/ILedgerTransaction';
import DateIgnoreTime from '@/types/DateIgnoreTime';
import useNotifierWithErrFormatter from '@/services/composables/NotifierWithErrFormatter';

const props = defineProps<{
    /**
     * Is the form being displayed
     */
    modelValue: boolean;
}>();
const emits = defineEmits<{
    (e:'update:modelValue', val: boolean): void;
    (e:'submit', val: LedgerTransaction): void;
}>();
defineExpose({
    assignTemplate
});
const displayingForm = computed({
    get(){
        return props.modelValue;
    },
    set(val: boolean) {
        emits('update:modelValue', val);
    }
});
const error = ref();
const working = ref(false);
let promise: Promise<ILedgerTransactionOptions> | undefined = undefined;
const options = ref<ILedgerTransactionOptions>();
const loading = computed(()=>!!error.value||!options.value);
const updated = ref(true);
const preFilledId = ref('');
const transIdPattern = computed(()=>`^(${preFilledId.value}0|)$`);
//const loading = true;
const transaction = ref(getDefaultTransactionRequest());
onMounted(refreshOptions);
watch(displayingForm, (val)=>{
    if (val) loadOptionsIfNeeded();
    else {
        transaction.value = getDefaultTransactionRequest();
        preFilledId.value = '';
    }
});
function getDefaultTransactionRequest(): Partial<ILedgerTransactionRequest> {
    return {
        accountId: '',
        market: marketSelection.value,
        currency: null,
        transferTypeId: '',
        itemType: '',
        itemCategory: '',
    };
}
function loadOptionsIfNeeded() {
    console.trace('loadOptionsIfNeeded', options.value);
    if (!options.value && !promise) refreshOptions();
}
async function refreshOptionsInner() {
        const optionRes = await ledgerTransactionOptions.get();
        return optionRes.data;
}
async function refreshOptions() {
    try {
        promise = refreshOptionsInner();
        error.value = undefined;
        options.value = await promise;
    } catch(e: unknown) {
        error.value = e;
    } finally {
        promise = undefined;
    }
}
function emptyStringToNull(nullable?: string | null) {
    return nullable ? nullable : null;
}
async function submit(transaction: ILedgerTransactionRequest) {
    const closeSpinner = useSpinnerPopup().show();
    try {
        if (!updated.value) {
            useNotifierWithErrFormatter().warn({
                msg: 'No value has been updated'
            });
            return;
        }
        const res = await ledgerTransactions.post(transaction);
        const transactionRaw: ILedgerTransaction = {
            paymentId: res.data.paymentId,
            transactionId: transaction.transactionId ? +transaction.transactionId : null,
            accountId: transaction.accountId,
            valueDate: transaction.valueDate,
            targetDate: transaction.targetDate,
            amount: +transaction.amount,
            market: transaction.market,
            currency: transaction.currency ?? MarketCurrency[transaction.market],
            paymentAdviceReference: transaction.paymentAdviceReference,
            paymentReference: transaction.paymentReference,
            transferTypeId: transaction.transferTypeId,
            itemType: transaction.itemType,
            itemCategory: transaction.itemCategory,
            categoryPeriod: transaction.categoryPeriod,
            invoiceId: transaction.invoiceId,
            creditNoteId: transaction.creditNoteId,
            contractId: transaction.contractId,
            assignedTo: transaction.assignedTo,
            transferInfo: transaction.transferInfo
        }
        onPostSuccess();
        emits('submit', new LedgerTransaction(transactionRaw));
    } catch (error) {
        useNotifierWithErrFormatter().error({
            errorType: 'Submitting Ledger Transaction',
            error
        });
    } finally {
        closeSpinner();
    }
}
async function onSubmit() {
    if (working.value) return;
    working.value = true;
    /**
     * cover for stuff not covered by input checks
     */
    function validateRequest(obj: Partial<ILedgerTransactionRequest>): obj is ILedgerTransactionRequest {
        let result = true;
        obj.transactionId = emptyStringToNull(obj.transactionId);
        obj.valueDate = new DateIgnoreTime(obj.valueDate);
        obj.targetDate = new DateIgnoreTime(obj.targetDate!); // can never be undefined since it's called by onSubmit
        obj.paymentAdviceReference = emptyStringToNull(obj.paymentAdviceReference);
        obj.paymentReference = emptyStringToNull(obj.paymentReference);
        obj.invoiceId = emptyStringToNull(obj.invoiceId);
        obj.creditNoteId = emptyStringToNull(obj.creditNoteId);
        obj.contractId = emptyStringToNull(obj.contractId);
        return result;
    }
    if (validateRequest(transaction.value)) submit(transaction.value);
    working.value = false;
}
function onPostSuccess() {
    useNotifierWithErrFormatter().success({
        msg: 'Transaction successfully registered.'
    });
    displayingForm.value = false;
    updated.value = true;
    transaction.value = getDefaultTransactionRequest();
}
function assignTemplate(template: ILedgerTransaction) {
    updated.value = false;
    transaction.value = Object.assign(getDefaultTransactionRequest(), template);
    if (typeof transaction.value.amount === 'number') transaction.value.amount = `${transaction.value.amount}`;
    preFilledId.value = transaction.value.transactionId?`${transaction.value.transactionId}|`:'';
}
function onUpdated() {
    updated.value = true;
}
</script>
<style scoped lang="scss">
.hide-scroll {
    overflow: hidden;
}
.form-container {
    flex: 1 1 auto;
    >.error {
        vertical-align: middle;
        text-align: center;
    }
    >form.two-column {
        display: grid;
        row-gap: 0.25rem;
        column-gap: 0.5rem;
        grid-template-columns: auto auto;
        align-items: center;

        >label {
            height: fit-content;
            font-weight: bold;
            text-align: left;
            vertical-align: middle;
        }
        .input,
        :deep(input),
        :deep(select),
        select {
            border-color: black;
            font-size: 0.8rem;
            border-radius: 0.4rem;
            padding-top: 0.2rem;
            padding-bottom: 0.2rem;
            border-width: 2px;
            text-align: right;
        }
        select:invalid,
        select option[value=""] {
            color: grey;
        }
        select option {
            color: black;
        }
        >.confirm-button {
            margin-top: 10px;
            border-width: 0px;
            border-radius: var(--buttons-border-radius);
            background-color: var(--primary-color-lighter);
            padding: 10px;
            color: blue;
            font-size: larger;
            font-weight: bold;
            width: 100%;
            grid-column: span 2;
            &:hover {
                background-color: var(--primary-color);
                color: white;
                cursor: pointer;
            }
            &:disabled {
                color: #b6b6b6;
                background-color: var(--light-grey);
                &:hover {
                    background-color: var(--primary-color-lighter);
                    cursor: not-allowed;
                }
            }
        }
    }
}
</style>