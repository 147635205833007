import { AxiosError } from "axios";

interface INotFoundErrorData {
    Error: string;
}
interface INotFoundError {
    response: {
        data: INotFoundErrorData;
    }
}

export default INotFoundError;
export function isINotFoundError(error: unknown): error is INotFoundError {
    return !!(error as AxiosError<INotFoundErrorData>)?.response?.data?.Error;
}