import ICustomerPaymentAdviceSpecificationTable from "@/Interfaces/CustomersAndContracts/ICustomerPaymentAdviceSpecificationTable";
import { extractNonArraysFromObj } from '@/services/helpers/ObjectHelper';
import DateIgnoreTime from "@/types/DateIgnoreTime";

export default class CustomerPaymentAdviceSpecificationTable implements ICustomerPaymentAdviceSpecificationTable {
    CUSTOMER_INVOICE_ID: string;
    EXECUTION_DATE: DateIgnoreTime;
    TRANSACTION_DATE: DateIgnoreTime;

    constructor(data: ICustomerPaymentAdviceSpecificationTable) {
        Object.assign(this,extractNonArraysFromObj(data));
        this.CUSTOMER_INVOICE_ID = data.CUSTOMER_INVOICE_ID;
        this.EXECUTION_DATE=new DateIgnoreTime(data.EXECUTION_DATE);
        this.TRANSACTION_DATE=new DateIgnoreTime(data.TRANSACTION_DATE);
    }
}
