import { generateBlobDownload } from "./Helper";
import PropCaseHelper from "./PropCaseHelper";

const csvDelimiter = ',';
export default class DbTableCsvHelper {
    public static getKeys<T extends object>(data: T[]) {
        if (data.length <= 0) return [];
        return [...data.reduce((oldSet: Set<string>, x)=>{
            Object.entries(x).forEach(([k,v])=>v!==undefined?oldSet.add(k):undefined);
            return oldSet;
        }, new Set<string>()).keys()];
    }
    public static generateCsvStr<T extends object>(data: T[]) {
        const keys = DbTableCsvHelper.getKeys(data);
        const headers = keys.map(k=>`"${PropCaseHelper.toSnakeCase(k).toUpperCase()}"`)
                            .join(csvDelimiter);
        const rows = data.map(row=>keys.map(k=>`"${row[k]??''}"`).join(csvDelimiter));
        return headers + '\n' + rows.join('\n');
    }
    private static generateCsvDownloadFromText(csv: string, name?: string) {
        var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        generateBlobDownload(blob, name ?? '.csv');
    }
    public static generateCsvDownload<T extends object>(data: T[], name?: string) {
        const csv = DbTableCsvHelper.generateCsvStr(data);
        return DbTableCsvHelper.generateCsvDownloadFromText(csv,name);
    }
}