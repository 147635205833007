// SERVICES
import { authService } from "@/auth/authSingleton";
// MISC
import log from "loglevel";

export enum AuthorisationLevel {
    NoAuthorisation,
    External,
    Restricted,
    DepositManager,
    Operator,
    Analyst,
    Admin,
    Maintenance
}
export const FunctionAccessLevels = {
    // Journaling
    ProformaJNL: AuthorisationLevel.Admin,
    ExportJNL: AuthorisationLevel.Admin,
    BackoutJNLReport: AuthorisationLevel.Admin,
    // EoP Verifications
    ViewEoPVReport: AuthorisationLevel.Operator,
    // EoP Verification
    CreateEoPVReport: AuthorisationLevel.Analyst,
    // Alerts and Warnings
    ManageAlertsAndWarnings: AuthorisationLevel.Operator,
    // Invoices
    DownloadInvoices: AuthorisationLevel.Maintenance,
    // Hire Agreements
    DownloadHireAgreement: (AuthorisationLevel.Operator || AuthorisationLevel.External),
    BulkDownloadHireAgreements: AuthorisationLevel.Maintenance,
    // Reports
    ViewRestrictedReports: AuthorisationLevel.Operator,
    // Fees Configuration
    WriteFeesConfig: AuthorisationLevel.Analyst,
    // Delete Staging Entries
    DeleteStagingEntries: AuthorisationLevel.Admin,
    // Assign Car Purchase to Investor
    CarPurchasesAssignToOwner: AuthorisationLevel.Analyst,
};
export function GetAuthLevel(role: string): AuthorisationLevel {
    if (!(role in AuthorisationLevel)) {
        return AuthorisationLevel.NoAuthorisation;
    } else {
        return AuthorisationLevel[role];
    }
}
/**
 * Returns true if user role < input functionAccessLevel
 * @param functionAccessLevel Minimum level to be granted access
 */
export function DenyFunctionLevelAccess(functionAccessLevel: AuthorisationLevel, checkExternal?: boolean): boolean {
    const authLevel = GetAuthLevel(authService.getUsersRole());
    let denyAccess: boolean = authLevel < functionAccessLevel;
    if (checkExternal) {
        denyAccess = authLevel === AuthorisationLevel.External ? false : authLevel < functionAccessLevel;
    }

    if (denyAccess) {
        log.trace("DenyFunctionLevelAccess()\nfunctionAccessLevel:", functionAccessLevel, "\nDeny access:", denyAccess);
    }

    return denyAccess;
}
