import { AxiosResponse } from "axios";
import { financialSystemNetworkService } from "@/services/helpers/Helper";
import log from "loglevel";
import {IEoPVerifications, IEoPItem} from "@/Interfaces/EoPVerifications/IEoPVerifications";
import { Ref, ref } from "vue";
import { DisplayableKeys } from '../SorterService';
import DatePeriod from "@/types/DatePeriod";

export const cols: DisplayableKeys<IEoPItem>[] = [
    'JournalCategory',
    'JournalName',
    'ClosingDate',
    'ProcessedDateTime',
    'User',
    'Status',
    'TotalAmount',
    'Report'
];
export const labels = [
    'Category',
    'Type',
    'Closing',
    'Processed',
    'User',
    'Status',
    'Ttl Amount',
    'Report'
];
const sortCol: keyof IEoPItem = 'JournalCategory';
export const defaultSortCols = ref([cols.findIndex((col) => {return col === sortCol;}),]);
export const defaultSortOrders = ref<(1|-1)[]>([1]);
export const checkedRows = ref('');

// export function ParseItems(item: EoPVerification) {
//     // Convert Closing and Processed from string to Date
//     item.ClosingDate = new Date(item.Processed);
//     item.ProcessedDate = new Date(item.Processed);

//     // Convert TotalAmount from comma-separated string to float
//     item.TotalAmount = parseFloat(`${item.TotalAmount}`.replaceAll(",", ""));

//     return item;
// }
// export function UnParseItems(item: EoPVerification) {
//     // Convert TotalAmount from float to comma-separated string
//     item.TotalAmount =
//         typeof item.TotalAmount === "number"
//             ? item.TotalAmount.toLocaleString("en-UK", {
//                   style: "currency",
//                   currency: "EUR",
//               }).substring(1)
//             : item.TotalAmount;

//     return item;
// }

export class EoPVerificationsServices {
    public async getEoPVerifications(market:string, period:DatePeriod, company: string): Promise<IEoPVerifications> {
        log.trace("getEoPVerifications()");
        const urlParams: string = process.env.VUE_APP_EOP_VERIFICATIONS_URL_PARAMS! + "?" +`market=${market}&period=${period}&company=${company}`;
        return (await financialSystemNetworkService.get(urlParams)).data;
    }
}

export function parseData(data?: IEoPVerifications): IEoPItem[]|undefined {
    const items: IEoPItem[] = data?Object.values(data):[];
    return items;
}