<script setup lang="ts">
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
const router = useRouter();
const emits = defineEmits<{
    (e:'change', tab:string)
}>();
const props = defineProps<{
    /** Key of the route params to fetch current tab */
    paramKey: string,
    /** Keys of the tabs, display if tabDisplayMap not defined */
    tabs: string[],
    tabDisplayMap?: Record<string, string>,
    /** Keys of the tabs that are not clickable */
    disabled?: string[],
}>();
const key = ref(0);
const cur = computed(()=>((router.currentRoute.value.params[props.paramKey]?router.currentRoute.value.params[props.paramKey]:props.tabs[0]) as string).toUpperCase());
function changeTab(tab: string) {
    emits('change', tab);
    router.push({name:router.currentRoute.value.name??'', params:{type:tab.toLowerCase()}});
    key.value = key.value + 1;
    if (key.value > 100000) key.value = 0;
}
</script>
<template>
<div class="tabs-container">
    <ul class="tabs">
        <li v-for="tab of tabs" :class="['tab', {'selected':cur===tab.toUpperCase()}]" @click="changeTab(tab)" :disabled="disabled?.includes(tab)">
            <slot :value=tab>
                {{ tabDisplayMap?.[tab] ?? tab }}
            </slot>
        </li>
    </ul>
    <RouterView :key=key />
</div>
</template>
<style lang="scss" scoped>
.tabs-container {   
    display: flex;
    flex-direction: column;
    justify-items: center;
    flex-grow: 0;
    >.tabs {
        margin: 0;
        padding: 0;
        display: flex;
        align-items: stretch;
        list-style:none;
        >.tab {
            flex-grow: 1;
            margin: 0;
            padding: 10px;
            font-size: larger;
            color: #b6b6b6;
            background-color: #f0f0f0;
            font-weight: normal;
            border-color: white;
            border-radius: var(--buttons-border-radius);
            border-width: 0px;
            text-align: center;
            &:hover {
                background-color: var(--primary-color);
                color: white;
                cursor: pointer;
            }
            &:visited {
                color: inherit;
            }
            &:disabled {
                background-color: var(--light-grey);
                color: blue;
                &:hover {
                    background-color: var(--primary-color-lighter);
                    cursor: not-allowed;
                }
            }
            &.selected {
                font-weight: bold;
                background-color: var(--primary-color-lighter);
                border-width: 0 0 .2rem 0;
                border-color: blue;
                color: blue;
                border-bottom-style: outset;
            }
        }
        >:first-child {
            border-radius: var(--topbar-sidebar-content-radius) 0px 0px 0px;
        }
        >:not(:first-child):not(:last-child) {
            border-radius: 0px;
        }
        >:last-child {
            border-radius: 0px var(--topbar-sidebar-content-radius) 0px 0px;
        }
    }
}
</style>