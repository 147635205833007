import { authService } from "@/auth/authSingleton";
import { IApiHeaders } from "@/Interfaces/IApiHeaders";

export const enum NetworkSvcType {
  API,
  TICKET,
  LOG,
}
export default abstract class BaseNetworkService<Param, Query> {
    protected defaultHeaders: IApiHeaders[];
    protected scopes: string[];
    private baseUrl?: string;
    constructor(
      protected uri: string,
      type: NetworkSvcType = NetworkSvcType.API,
      scopes?: string[],
      defaultHeaders?: IApiHeaders[],
    ) {
      const apiUrl = process.env.VUE_APP_FINANCIAL_SYSTEM_API_URL_NO_PARAMS;
      const ticketUrl = process.env.VUE_APP_TICKETING_ADAPTER_API_URL_NO_PARAMS;
      const logUrl = process.env.VUE_APP_LOGGING_API_URL_NO_PARAMS;
      const urls = [apiUrl, ticketUrl, logUrl];
      this.scopes = scopes ?? [];
      this.defaultHeaders = defaultHeaders ?? [];
      this.baseUrl = urls[type];
    }
    protected getBaseURL() {
      return this.baseUrl;
    }
    protected getUri(params?: Param) {
      const re = /\/{(.*?)}/g;
      let result = this.uri.replace(re, '');
      if (!params) return result;
      const it = this.uri.matchAll(re);
      for (const [_, k] of it) {
        const param = params[k];
        if (param) {
          result += `/${encodeURIComponent(param)}`;
        }
      }
      return result;
    }
    protected removeOpenClosingQuotes(x: string) {
      if (x==="") return x;
      return x.replace(/(^"|"$)/g, '');
    }
    protected encodeVal(v: unknown) {
      if (v === undefined) return '';
      else if (v === null) return JSON.stringify(v);
      else if (typeof v === 'function') return v();
      else if (v instanceof Array) {
        if (v.length > 0) {
          // breaks if we have second level of array... fix later
          return v.map(x=>this.encodeVal(x))
                  .filter(s=>s)
                  .join(',');
        } else return '';
      } else if (typeof v === 'string') return v;
      else return JSON.stringify(v);
    }
    protected getQueryString(obj?: Query|null) {
      if (!obj) return '';
      return (
        '?' +
        Object.entries(obj)
          .filter(([k, v])=> v===""|| (v && !(v instanceof Array && v.length<=0)))
          .map(([k, v]) => [encodeURIComponent(k), this.removeOpenClosingQuotes(this.encodeVal(v))])
          .map(([k, v]) => v===""?k:`${k}=${v}`)
          .join("&")
      );
    }
    protected async getHeader(): Promise<object> {
      const header = {};
      for (const headerData of this.defaultHeaders) {
        header[headerData.name] = headerData.value;
      }
      if (this.scopes !== undefined) {
        const authValue = await authService.getApiToken();
        header["Authorization"] = "Bearer " + authValue;
      }
      if (header) return header;
      else throw new Error("Undefined header");
    }
}