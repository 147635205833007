import { loggingApiNetworkService } from '@/services/helpers/Helper';
import { authService } from "@/auth/authSingleton";
import { IAPILogger } from "@/Interfaces/IAPILogger";
import { getIPAddress } from '@/services/helpers/Helper';
import log from "loglevel";

export class Log2API implements IAPILogger {
    LogError;
    LogWarning;
    LogInformation;
    constructor() {
        this.LogError = LogError;
        this.LogWarning = LogWarning;
        this.LogInformation = LogInformation;
    }
}

enum LogTypes {
    Error = "error",
    Warning = "warning",
    Information = "information",
}

const Module: string = process.env.VUE_APP_LOGGING_API_MODULE_NAME!;

async function Log(Message: string, Class: string, LogType: string) {
    try {
        const messagePrefix: string =
            "(Name: " +
            authService.getUserName() +
            ", Email: " +
            authService.getEmailAddress() +
            ", Role: " +
            authService.getUsersRole() +
            ", IP Address: " +
            await getIPAddress() +
            ") ";
        const logMessage: string = messagePrefix + Message;
        log.trace(`Log()\nMessage: ${logMessage}`);
    
        const body = {
            Message: logMessage,
            Class: Class,
            Module: Module,
        };
        const response = await loggingApiNetworkService.post(body, LogType)
		if (response.status !== 201) {
			log.warn("Log()\nStatus:", response.status);
		}
    } catch(e) {
        log.error(e);
    }
}

async function LogError(Message: string, Class: string) {
    Log(Message, Class, LogTypes.Error);
}

async function LogWarning(Message: string, Class: string) {
    Log(Message, Class, LogTypes.Warning);
}

async function LogInformation(Message: string, Class: string) {
    Log(Message, Class, LogTypes.Information);
}
