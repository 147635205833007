import log from "loglevel";
import router from "@/router";
import { ICarPurchasesCollectiveCarSaleInvoice } from "@/Interfaces/CarPurchases/ICarPurchasesCollectiveCarSaleInvoice";
import { CarPurchasesCollectiveCarSaleInvoice } from "@/models/CarPurchases/CarPurchasesCollectiveCarSaleInvoice";
import { ICarPurchasesCarSaleInvoice } from "@/Interfaces/CarPurchases/ICarPurchasesCarSaleInvoice";
import { CarPurchasesCarSaleInvoice } from "@/models/CarPurchases/CarPurchasesCarSaleInvoice";

export function RowClicked(row: CarPurchasesCollectiveCarSaleInvoice) {
	log.trace("RowClicked()");

	const r = {
		name: "Car Purchase",
		params: {
			market: row.Market,
			id: row.CollectiveInvoiceId,
		},
	};
	// Open in current tab
	router.push(r);
}
export function ParseCarPurchaseCollectiveCarSaleInvoices(item: ICarPurchasesCollectiveCarSaleInvoice): CarPurchasesCollectiveCarSaleInvoice {
	log.trace(`ParseItems()`);

	return new CarPurchasesCollectiveCarSaleInvoice(item);
}
export function ParseCarPurchaseCarSaleInvoices(item: ICarPurchasesCarSaleInvoice): CarPurchasesCarSaleInvoice {
	log.trace(`ParseItems()`);

	return new CarPurchasesCarSaleInvoice(item);
}
export function FilterCarSaleInvoicesByContractIds(contractIds?: string, data?: CarPurchasesCarSaleInvoice[]) {
	const contractIdsList = contractIds?.split(",").filter(x=>x).map(x=>x.trim());

	return contractIdsList?.length
		? data?.filter((carPurchase: CarPurchasesCarSaleInvoice) =>
			carPurchase.CONTRACT_ID != undefined && contractIdsList.includes(carPurchase.CONTRACT_ID)
		)
		: data;
}
export const FormatDatePicker = (date?: Date, replace: boolean = true) => {
	return (replace
		? date?.toLocaleString("sv-SE").split(" ")[0]?.replaceAll("-", "/")
		: date?.toLocaleString("sv-SE").split(" ")[0]) ?? '';
};
