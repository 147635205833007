import { Ref, ref } from "vue";
import { AxiosResponse } from "axios";
import { financialSystemNetworkService } from "@/services/helpers/Helper";
import log from "loglevel";
import { Log2API } from "@/services/Logger";
import IUploadFile from "@/Interfaces/SageBackup/IUploadFile";
import IBackupFile from "@/Interfaces/SageBackup/IBackupFile";
import { DisplayableKeys } from "../SorterService";


export const cols: DisplayableKeys<IBackupFile>[] = [
    "FileName",
    "Modified"
];
export const labels = [
    "Name",
    "Uploaded At"
];
const sortCol: keyof IBackupFile = "Modified";
export const defaultSortCols = ref([
    cols.findIndex(col => col === sortCol),
]);
export const defaultSortOrders = ref<(1 | -1)[]>([-1]);

export class SageBackupHelper {
	Logger = new Log2API();
	public percentage = ref(0); // Define percentage at the class level

	public async GetBackups(): Promise<AxiosResponse<IBackupFile[]>>  {
		const name = this.GetBackups.name;
		log.trace(name + "()");

		const urlParams: string = `${process.env.VUE_APP_BACKUP_SAGE_FILE_URL_PARAMS!}`;
		const response: AxiosResponse<IBackupFile[]> =  await Get<IBackupFile[]>(urlParams);

		return response;
	}
	
	public async BackupFile<T>(fileName: string, fileSize: number, fileContents: string): Promise<AxiosResponse<T>> {
		const name = this.BackupFile.name;
		log.trace(name + "()\nfileName:", fileName, "\nfileSize");

		const urlParams: string = `${process.env.VUE_APP_BACKUP_SAGE_FILE_URL_PARAMS!}`;
		// const headers: object = {
		// 	FileSize: fileSize,
		// };
		const data: IUploadFile = {
			FileName: fileName,
			Payload: fileContents
		}
		// const response: AxiosResponse<T> =  await Post<T>(data, urlParams, headers);
		const response: AxiosResponse<T> =  await Post<T>(data, urlParams);
		this.percentage.value = response.status == 201 ? 100 : -1;

		return response;
	}
}

async function Get<T>(urlParams: string): Promise<AxiosResponse<T>> {
	log.debug("Get()\nurlParams:", urlParams);

	const response: AxiosResponse<T> = await new Promise((resolve, reject) => {
		financialSystemNetworkService
			.get(urlParams)
			.then((res: AxiosResponse<T>) => {
				log.debug("Get()\nurlParams:", urlParams, "\nres.data:", res.data);
				resolve(res)
			})
			.catch((error: any) => {
				reject(error);
			});
	});

	return response;
}

async function Post<T>(data: IUploadFile, urlParams: string, headers?: object): Promise<AxiosResponse<T>> {
	log.debug("Post()\nurlParams:", urlParams);

	const response: AxiosResponse<T> = await new Promise((resolve, reject) => {
		financialSystemNetworkService
			.post(data, urlParams, headers, false)
			.then((res: AxiosResponse<T>) => {
				log.debug("Post()\nurlParams:", urlParams, "\nres.data:", res.data);
				resolve(res)
			})
			.catch((error: any) => {
				reject(error);
			});
	});

	return response;
}

interface IPacket {
	Payload: ArrayBuffer;
}

class Packet {
	Payload: string;

	constructor(payload: string) {
		this.Payload = payload;
	}
}
