<template>
    <div class="Unauthorised">
        <div class="content-container">
            <div class="top-content">
                <UnauthorisedErrorInfo
                    :showError=true
                    :errorDescription="errorDetails"
                    :errorDisplayText="errorDisplayText"
                    :errorMessage="errorDetails"
                />
            </div>
            <div class="table-container"></div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { onMounted } from "vue";
// Services
import { AuthorisationLevel } from "@/config/AuthConfig";
import { authService } from "@/auth/authSingleton";
import { LocationQuery, LocationQueryValue, RouteRecordRaw, useRoute } from "vue-router";
// Misc
import { routes } from "@/router/index"
import ErrorInfo from "@/components/ErrorInfo.vue";

onMounted(() => {
    document.title = `Unauthorised - Optio`
});

// ROUTE
const route = useRoute();
const locationQuery: LocationQuery = route.query;
const locationQueryValue: LocationQueryValue | LocationQueryValue[] = locationQuery.redirect;
const accessAttempt: string | undefined = !locationQueryValue ?  route.path : toTitleCase(
    locationQueryValue?.toString()
        ?.replace("/", "")
        ?.replace("/", " ")
        ?.replaceAll("-", " ")
        ?.split("/")
        ?.shift()
);
const requiredAuthLevel = routes.find((route: RouteRecordRaw) => route.path === locationQueryValue?.toString().split("/").slice(0, 2).join("/") )?.meta?.authLevel;

function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    function(txt) {
      return (txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
        .replace("And", "and")
        .replace("Eop", "EoP");
    }
  );
}

// ERRORS
const UnauthorisedErrorInfo = ErrorInfo;
const errorDisplayText: string = `You do not have the correct permissions to access ${accessAttempt}.`
const errorDetails: string = `(Unauthorised) Your authorisation level is ${AuthorisationLevel[authService.getUsersRole()]} (${authService.getUsersRole()}), however this page requires an authorisation level of ${requiredAuthLevel ?? AuthorisationLevel.Maintenance}.
    \nPlease seek assistance from the help desk or try logging in with a different account.`
</script>

<style>
.Unauthorised {
}
</style>
