<template>
    <Popup
        :modelValue="!!selectedRows"
        @update:model-value="closeChild"
        title="Payment confirmation"
        hasClose
    >
        <template #contents v-if="selectedRows">
            <div class="grid-item">
                The remaining balance on {{ `${selectedRows.length == 1 ? 'this service invoice is:' : 'these service invoices are:'}` }}
                <strong>{{ MarketCurrency[selectedRows[0].market]}} {{ MonetaryValueWithCommas(sumOfBalances) }}</strong>
            </div>
            <form class="top-content" @submit.prevent="ConfirmTransfer">
                <span style="padding-bottom: 0.4rem"
                    >Select a payment date
                    {{ `${selectedRows.length == 1 ? "and an amount" : ""}` }}:</span
                >
                <span
                    title="Select a date for confirmed ledger tranfers"
                    class="search-with-button"
                >
                    <div class="date-picker" style="width: 50%">
                        <DatePicker
                            v-model="selectedDate"
                            type="date"
                            :enable-time-picker="false"
                            :format="FormatDatePicker"
                            placeholder="Select Date..."
                            :text-input-options="textInputOptions"
                            text-input
                            :max-date="new Date()"
                            :teleport="true"
                            required
                        />
                    </div>

                    <CurrencyInput
                        v-if="selectedRows.length == 1"
                        v-model="paymentAmount"
                        :max="selectedRows[0].balance"
                        :min="0.01"
                        :currency="MarketCurrency[selectedRows[0].market]"
                        style="border-radius: 0"
                        required
                    />

                    <button :disabled="working" class="right-button">Confirm</button>
                </span>
            </form>
        </template>
    </Popup>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, ref, computed } from "vue";
import DatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import PaymentItem from "@/Interfaces/Payments/ServiceInvoicePaymentItem";
import Popup from "./Popup.vue";
import CurrencyInput from "./CurrencyInput.vue";
import { confirmServiceInvoicePayment as confirmPayment } from "@/services/network";
import useNotifierWithErrFormatter from "@/services/composables/NotifierWithErrFormatter";
import useSpinnerPopup from "@/services/composables/SpinnerPopup";
import { authService } from "@/auth/authSingleton";
import DateIgnoreTime from "@/types/DateIgnoreTime";
import {
    MarketCurrency,
    MonetaryValueWithCommas,
    marketSelection,
} from "@/services/helpers/Helper";
import { IConfirmServiceInvoicePaymentItems } from "@/Interfaces/Payments/IConfirmServiceInvoicePaymentRequest";

const props = defineProps<{
    modelValue?: PaymentItem[];
}>();
const selectedRows = computed({
    get() {
        return props.modelValue;
    },
    set(val) {
        emits("update:modelValue", val);
    },
});
const working = ref(false);
const selectedDate = ref<Date>();
const textInputOptions = { format: "yyyy/MM/dd" };
const paymentAmount = ref("");
const emits = defineEmits<{
    (e: "update:modelValue", val?: PaymentItem[]);
    (e: "confirm");
}>();
const sumOfBalances = computed(() => {
    return selectedRows.value!.reduce((total, obj) => total + parseFloat(obj.balance), 0);
});

function closeChild() {
    selectedDate.value = undefined;
    paymentAmount.value = "";
    selectedRows.value = undefined;
}
const FormatDatePicker = (date: Date) => {
    return date.toLocaleString("sv-SE").split(" ")[0].replaceAll("-", "/");
};
async function ConfirmTransfer() {
    if (working.value) return;
    working.value = true;
    const closeSpinner = useSpinnerPopup().show();
    try {
        if (!selectedRows.value)
            throw new ReferenceError(
                "selectedRow cannot be null when submitting the form"
            );

        const paymentItems: IConfirmServiceInvoicePaymentItems[] = selectedRows.value.map((row) => ({
                invoiceId: row.invoiceId,
                amountTotal: row.amountTotal,
                amountVAT: row.amountVAT,
                periodDateFrom: row.periodDateFrom,
                periodDateTo: row.periodDateTo,
                periodMonth: row.periodMonth,
                issueDate: row.issueDate,
                dueDate: row.dueDate,
                balance: row.balance,
                amountPaid: selectedRows.value?.length === 1 ? paymentAmount.value : row.balance,
            })
        );

        const payload = Object.assign({
            serviceInvoiceItems: paymentItems,
            userEmail: authService.getEmailAddress(),
            balanceDateUpdated: new DateIgnoreTime(selectedDate.value),
            market: marketSelection.value,
        });
        const response = await confirmPayment.put(payload);
        useNotifierWithErrFormatter().success({
            msg: "Payment successfully registered.",
        });
        closeChild();
        emits("confirm");
    } catch (error) {
        useNotifierWithErrFormatter().error({
            name: `${__filename}/${ConfirmTransfer.name}`,
            errorType: "Confirming payment",
            error,
        });
    } finally {
        closeSpinner();
        working.value = false;
    }
}
</script>

<style scoped>
.grid-item {
    padding: 10px;
}
</style>
