type KeyReturn<T, K extends keyof T> = T[K] extends string|number ? T[K] : never;
type KeyTypeKeys<T, K extends keyof T> = KeyReturn<T, K> extends never ? never : K;

interface Array<T> {
    groupBy<K extends keyof T>(key: KeyTypeKeys<T,K>): Record<KeyReturn<T,K>, T[]>;
}

Array.prototype.groupBy = function<T, K extends keyof T>(key: KeyTypeKeys<T,K>) {
    return this.reduce(function(rv, x: T) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
};