<template>
    <div class="DataProcessing">
        <div class="center-dropdown-table-container">
            <div class="center-dropdown-table" style="margin-top: 0px;">
                <tbody>
                    <tr>
                        <td>
                            <div class="center-buttons bottom-margin-only">
                                <button
                                    :class="`button-left ${showFileView || showFileDetailsView ? 'selected' : 'unselected'}`"
                                    style="width: 100%;"
                                    :disabled="showFileView || showFileDetailsView"
                                    :title="showFileView || showFileDetailsView ? 'Currently in File View' : 'Click to switch to Staging View'"
                                    @click="HandleFileViewClick()"
                                >
                                    File View
                                </button>
                            </div>
                        </td>
                        <td>
                            <div class="center-buttons bottom-margin-only">
                                <button
                                    :class="`button-right ${showStagingView ? 'selected' : 'unselected'}`"
                                    style="width: 100%;"
                                    :disabled="showStagingView"
                                    :title="showStagingView ? 'Currently Staging View' : 'Click to switch to File View'"
                                    @click="HandleStagingViewClick()"
                                >
                                    Staging View
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </div>
        </div>
        <div v-if="isLoading" class="content-container">
            <div class="loading">
                <span class="loader"></span>
            </div>
        </div>
        <div v-else class="content-container">
            <div
                v-if="showSourceFilePopup"
                @click="showSourceFilePopup = false"
                title="Click outside of popup to close source file."
                class="overlay"
            >
                <div v-if="isSourceFileLoading">
                    <div class="loading">
                        <span class="loader"></span>
                    </div>
                </div>
                <div v-else @click.stop class="popup">
                    <div class="description-title">
                        <div v-if="showSourceFileError">
                            <div style="color: red">Error Viewing Source File:</div>
                        </div>
                        <div v-else>Source File:</div>
                    </div>
                    <div
                        class="description-subtitle"
                        :style="showSourceFileError ? 'color: red' : ''"
                    >
                        {{ sourceFileDisplayName }}
                        <br>
                        ({{
                            fileTypes.find((fileType) => fileType.ID === fileTypeSelection)
                                ?.DisplayName
                        }})
                    </div>
                    <pre v-html="sourceFile" :style="showSourceFileError ? 'color: red' : ''"></pre>
                    <div style="text-align: center">
                        <button
                            @click="showSourceFilePopup = false"
                            title="Close source file. (You can also click outside the popup to close source file.)"
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>

            <div
                v-if="showLoadingErrorPopup"
                @click="showLoadingErrorPopup = false"
                title="Click outside of popup to close loading error."
                class="overlay"
            >
                
                <div @click.stop class="popup">
                    <div class="description-title">Loading Error</div>
                    <div class="description-subtitle">{{ loadingErrorDisplayName }}</div>
                    <div style="margin-bottom: 20px; padding: 20px;">{{ loadingError }}</div>
                    <div style="text-align: center">
                        <button
                            @click="showLoadingErrorPopup = false"
                            title="Close loading error. (You can also click outside the popup to close loading error.)"
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>

            <div
                v-if="showTableDataPopup"
                @click="showTableDataPopup = false"
                title="Click outside of popup to close table data."
                class="overlay"
            >
                <div v-if="isTableDataLoading">
                    <div class="loading">
                        <span class="loader"></span>
                    </div>
                </div>
                <div v-else @click.stop class="popup">
                    <div class="description-title">
                        <div v-if="showTableDataError">
                            <div style="color: red">Error Viewing Table Data:</div>
                        </div>
                        <div v-else>Table Data</div>
                    </div>
                    <div
                        class="description-subtitle"
                        :style="showTableDataError ? 'color: red' : ''"
                    >
                        {{ tableDataDisplayName }}
                        <br>
                        {{ tableDataDisplaySubName }}
                    </div>
                    <pre v-html="tableData" :style="showTableDataError ? 'color: red' : ''"></pre>
                    <div style="text-align: center">
                        <button
                            @click="showTableDataPopup = false"
                            title="Close table data. (You can also click outside the popup to close table data.)"
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>

            <div v-if=showStagingDeletePopup
                @click="showStagingDeletePopup = false"
                title="Click outside of popup to close."
                class="overlay"
            >
                
                <div @click.stop class="popup">
                    <div class="description-title"> Warning </div>
                    <div class="description-subtitle" >
                        You are about to delete the following staging entries:
                    </div>
                    <pre>{{ stagingDeleteData }}</pre>
                    <div style="text-align: center">
                        <button
                            @click="DeleteStagingEntries(stagingViewTable?.GetChecked()??[])"
                            title="Delete Staging Entries"
                        >
                            Confirm Deletion
                        </button>
                    </div>
                </div>
            </div>

            <div class="top-content">
                <GettingDPErrorInfo
                    :showError=!!fileError
                    :errorDescription=errorDescription
                    :error=fileError
                    :change="!!fileError"
                />
                <DeletingEntiresErrorInfo
                    :showError=!!infoError
                    :errorDescription=errorDescription
                    :error=infoError
                    :change="!!infoError"
                />
                <GettingStagingErrorInfo
                    :showError=!!stagingError
                    :errorDescription=errorDescription
                    :error=stagingError
                    :change="!!stagingError"
                />

                <div class="flex-row-centered">
                    <MarketSelector enableSE @change=updateMarketSelection />
                    <div class="flex-col-centered">
                        <div>File Type Selection:</div>
                        <select v-model=fileTypeSelection @change=updateFileTypeSelection class="dropdown">
                            <option v-for="fileType in localFileTypes" :value="fileType.ID" :key="fileType.ID">
                                {{ fileType.DisplayName }}
                            </option>
                        </select>
                    </div>
                    <button :class="['centre-button', 'center-buttons-button-disabled', { hidden:!showStagingView }]"
                        style="width: 10rem; font-size: 1rem; padding: 0.4rem;"
                        :title="DenyFunctionLevelAccess(FunctionAccessLevels.DeleteStagingEntries) ? `Delete Staging Entries (Access Denied)` : `Delete Staging Entries`"
                        @click="PreDeleteStagingEntryWarning(stagingViewTable?.GetChecked() ?? [])"
                        :disabled="DenyFunctionLevelAccess(FunctionAccessLevels.DeleteStagingEntries)"
                    >
                        Delete Entries
                    </button>
                </div>
            </div>
            <Table v-if="showFileView"
                :Rows=displayedLastReceivedSFTPFiles
                :Cols=colsLastReceivedFilesByType
                :Labels=labelsLastReceivedFilesByType
                :DefaultSortCols=defaultSortCols
                :DefaultSortOrders=defaultSortOrders
                :RowClickedCallBack=LastReceivedFilesRowClicked
                :HasError=!!fileError
            >
                <template #FileType="props">
                    <div style="display: flex; justify-content: space-between">
                        <span style="font-size:120%; font-weight: bold;">{{ fileTypes.find((x) => x.APIName === props.value.FileType)?.DisplayName }}</span>
                    </div>
                </template>
                <template #WaitingName="props">    
                    <span class="FileNameDisplay">{{ props.value.WaitingName }}</span>
                </template>
                <template #ProcessedName="props">    
                    <span class="FileNameDisplay">{{ props.value.ProcessedName }}</span>
                </template>
                <template #FailedName="props">    
                    <span class="FileNameDisplay">{{ props.value.FailedName }}</span>
                </template>
            </Table>
            <div v-if="showFileDetailsView" id="details-view" class="table-container">
                <div class="details-column">
                    <div class="details-column-title">Waiting Files</div>
                    <Table
                        :Rows=waitingLastReceivedFilesDetails
                        :Cols=colsFileDetails
                        :Labels=labelsFileDetails
                        :HasError=!!infoError
                        MiniPaging
                    >
                        <template #Name={value}>
                            <div
                                @click="
                                    ViewSourceFile(
                                        marketSelection,
                                        fileTypeSelection,
                                        SFTPFileStatuses[SFTPFileStatuses.Waiting],
                                        value.Name
                                    )
                                "
                                :disabled="
                                    fileTypeSelection ===
                                    fileTypes.find((fileType) => fileType.ID === 'CUSTOMER')
                                        ?.ID
                                "
                                :title="
                                    fileTypeSelection ===
                                    fileTypes.find((fileType) => fileType.ID === 'CUSTOMER')
                                        ?.ID
                                        ? 'Cannot view ' +
                                        fileTypes.find(
                                            (fileType) => fileType.ID === 'CUSTOMER'
                                        )?.DisplayName +
                                        ' files'
                                        : 'View source file'
                                "
                                :class="[
                                    fileTypeSelection ===
                                    fileTypes.find((fileType) => fileType.ID === 'CUSTOMER')
                                        ?.ID
                                        ? 'text-link-denied'
                                        : 'fake-link'
                                    , 'filename'
                                ]"
                            >
                                {{ value.Name.replaceAll('_','_​') }}
                            </div>
                        </template>
                    </Table>
                </div>
                <div class="details-column">
                    <div class="details-column-title">Processed Files</div>
                    <Table
                        :Rows="processedLastReceivedFilesDetails"
                        :Cols="colsFileDetails"
                        :Labels="labelsFileDetails"
                        :HasError=!!infoError
                        MiniPaging
                    >
                        <template #Name={value}>
                            <div
                                @click="
                                    ViewSourceFile(
                                        marketSelection,
                                        fileTypeSelection,
                                        SFTPFileStatuses[SFTPFileStatuses.Processed],
                                        value.Name
                                    )
                                "
                                :disabled="
                                    fileTypeSelection ===
                                    fileTypes.find((fileType) => fileType.ID === 'CUSTOMER')
                                        ?.ID
                                "
                                :title="
                                    fileTypeSelection ===
                                    fileTypes.find((fileType) => fileType.ID === 'CUSTOMER')
                                        ?.ID
                                        ? 'Cannot view ' +
                                        fileTypes.find(
                                            (fileType) => fileType.ID === 'CUSTOMER'
                                        )?.DisplayName +
                                        ' files'
                                        : 'View source file'
                                "
                                :class="[
                                    fileTypeSelection ===
                                    fileTypes.find((fileType) => fileType.ID === 'CUSTOMER')
                                        ?.ID
                                        ? 'text-link-denied'
                                        : 'fake-link'
                                    , 'filename'
                                ]"
                            >
                                {{ value.Name.replaceAll('_','_​') }}
                            </div>
                        </template>
                    </Table>
                </div>
                <div class="details-column">
                    <div class="details-column-title">Failed Files</div>
                    <Table
                        :Rows="failedLastReceivedFilesDetails"
                        :Cols="colsFileDetails"
                        :Labels="labelsFileDetails"
                        :HasError=!!infoError
                        MiniPaging
                    >
                        <template #Name={value}>
                            <div
                                @click="
                                    ViewSourceFile(
                                        marketSelection,
                                        fileTypeSelection,
                                        SFTPFileStatuses[SFTPFileStatuses.Failed],
                                        value.Name
                                    )
                                "
                                :disabled="
                                    fileTypeSelection ===
                                    fileTypes.find((fileType) => fileType.ID === 'CUSTOMER')
                                        ?.ID
                                "
                                :title="
                                    fileTypeSelection ===
                                    fileTypes.find((fileType) => fileType.ID === 'CUSTOMER')
                                        ?.ID
                                        ? 'Cannot view ' +
                                        fileTypes.find(
                                            (fileType) => fileType.ID === 'CUSTOMER'
                                        )?.DisplayName +
                                        ' files'
                                        : 'View source file'
                                "
                                :class="[
                                    fileTypeSelection ===
                                    fileTypes.find((fileType) => fileType.ID === 'CUSTOMER')
                                        ?.ID
                                        ? 'text-link-denied'
                                        : 'fake-link'
                                    , 'filename'
                                ]"
                            >
                                {{ value.Name.replaceAll('_','_​') }}
                            </div>
                        </template>
                    </Table>
                </div>
            </div>
            <Table v-if="showStagingView" ref="stagingViewTable"
                :Rows=displayedStagingEntries
                :Cols=colsStagingEntry
                :Labels=labelsStagingEntry
                HasCheckBox
                :HasError=!!infoError
            >
                <template #TableType="props">
                    <div>
                        <span>{{ props.value.TableType.replace("Table","") }}</span>
                    </div>
                </template>
                <template #LoadingError="props">
                    <div style="display: flex;">
                        <button 
                            class="overflow-text"
                            @click="ViewLoadingError(props.value)"
                            :title="`View Full Loading Error in Popup`"
                        >
                            {{ FormatLoadingError(props.value.LoadingError) }}
                            </button>
                    </div>
                </template>
                <template #TableData="props">
                    <div  style="display: flex;">
                        <button
                            @click="ViewTableData(props.value)"
                            class="table-button"
                            :title="`View Table Data in Popup`"
                            style="flex-grow: 1; flex-shrink: 0;"
                        >
                            View Data
                        </button>
                    </div>
                </template>
            </Table>
        </div>
    </div>
</template>

<script setup lang="ts">
import { onMounted, inject, ref, computed, onUnmounted, watch } from "vue";
// Services
import { apiLoggerKey } from "@/types/ServiceKeys";
import {
    colsLastReceivedFilesByType,
    labelsLastReceivedFilesByType,
    colsStagingEntry,
    labelsStagingEntry,
    colsFileDetails,
    labelsFileDetails,
    ParseItems,
    DataHelperServices,
} from "@/services/helpers/DataProcessingHelper";
// Data
import { LastReceivedFilesByType, LastReceivedFilesByTypeDataItem } from "@/models/LastReceivedFilesByType";
import { defaultMarket, marketSelection } from "@/services/helpers/Helper";
import { FileType, fileTypes } from "@/Interfaces/IFileTypes";
// Table
import Table, { ITableExpose } from "@/components/TablePersistentChecking.vue";
// Misc
import log from "loglevel";
import { AxiosError } from "axios";
import ErrorInfo from "@/components/ErrorInfo.vue";
import { StagingEntries, StagingEntry } from "@/models/StagingEntries";
import { SFTPFileStatuses } from "@/models/LastReceivedFilesByType";
import { FunctionAccessLevels, DenyFunctionLevelAccess } from "@/config/AuthConfig";
import MarketSelector from "@/components/MarketSelector.vue";
import { FileDetails, LastReceivedFilesDetails } from "@/models/LastReceivedFileDetails";
//import { lastReceivedFileDetails } from "@/services/network/mock";

const fileName = "DataProcessing.vue";
const Logger = inject(apiLoggerKey);

document.title = `Data Processing - Optio`;

// MISC
const DPServices = new DataHelperServices();

//SELECTORS
const localFileTypes = ref<FileType[]>([...fileTypes]);
const fileTypeSelection = ref("ALL_FILE_TYPES");

// VIEWS
const showFileView = ref(true);
const showFileDetailsView = ref(false);
const showStagingView = ref(false);

// LAST RECEIVED FILES
const lastReceivedSFTPFiles = ref<LastReceivedFilesByType>();
const displayedLastReceivedSFTPFiles = ref<LastReceivedFilesByTypeDataItem[]>();
const lastReceivedFilesDetails = ref<LastReceivedFilesDetails>();
const processedLastReceivedFilesDetails= ref<FileDetails[]>();
const waitingLastReceivedFilesDetails= ref<FileDetails[]>();
const failedLastReceivedFilesDetails= ref<FileDetails[]>();

//STAGING TABLE
const stagingViewTable = ref<ITableExpose<StagingEntry>>();
const stagingEntries= ref<StagingEntry[]>();
const displayedStagingEntries= computed(()=>
    fileTypeSelection.value === "ALL_FILE_TYPES" ? 
        stagingEntries.value : 
        stagingEntries.value?.filter((x) => x.FileType === fileTypeSelection.value)
);

// VIEW SOURCE FILES
const sourceFileDisplayName = ref<string>();
const showSourceFilePopup = ref(false);
const sourceFile = ref();

// WIEW LOADING ERROR
const showLoadingErrorPopup = ref(false);
const loadingErrorDisplayName = ref<string>();
const loadingError = ref();

// VIEW TABLE DATA
const showTableDataPopup = ref(false);
const tableDataDisplayName = ref<string>();
const tableDataDisplaySubName = ref<string>();
const tableData = ref();

// DELETE STAGING ENTRIES
const showStagingDeletePopup = ref(false);
const confirmStagingDeletion = ref(false);
const stagingDeleteData = ref<string>();

// LOADING STATUS
const isLoading = ref(false);
const isSourceFileLoading = ref(false);
const isTableDataLoading = ref(false);

// ERROR
const GettingDPErrorInfo = ErrorInfo;
const DeletingEntiresErrorInfo = ErrorInfo;
const GettingStagingErrorInfo = ErrorInfo;
const showTableDataError = ref(false);
const showSourceFileError = ref(false);
const fileError = ref();
const stagingError = ref();
const infoError = ref();
const errorDescription = ref<string>();

// TABLE
const sortCol: keyof LastReceivedFilesByTypeDataItem = 'FileType';
const defaultSortCols = ref([colsLastReceivedFilesByType.findIndex(col => col === sortCol)]);
const defaultSortOrders = ref<(1|-1)[]>([1]);
    
onMounted(async () => {
    log.debug("onMounted() Data Processing");
    const newFileType = new FileType("ALL_FILE_TYPES", "AllFileTypes", "All File Types");
    localFileTypes.value.unshift(newFileType);
    marketSelection.value = defaultMarket
    await GetLastReceivedSFTPFiles(marketSelection.value);
});

onUnmounted(() => {
    marketSelection.value = 'GB'
})

async function GetLastReceivedSFTPFiles(market: string) {
    log.trace("GetLastReceivedSFTPFiles()");

    const name = GetLastReceivedSFTPFiles.name;
    const Class = fileName + "/" + name;
    var Message = `Getting last received ${marketSelection.value} files for all types`;
    try {
        infoError.value = undefined;
        displayedLastReceivedSFTPFiles.value = undefined;
        const response = await DPServices.GetLastReceivedSFTPFiles(market);
        lastReceivedSFTPFiles.value = ParseItems(response);

        displayedLastReceivedSFTPFiles.value = lastReceivedSFTPFiles.value.GetData();

        log.debug(
            `GetLastReceivedSFTPFiles()\ndisplayedLastReceivedSFTPFiles: (${
                displayedLastReceivedSFTPFiles.value.length
            }) ${JSON.stringify(displayedLastReceivedSFTPFiles.value)}`
        );

        log.trace(
            `GetLastReceivedSFTPFiles()\nresponse: ${JSON.stringify(
                lastReceivedSFTPFiles.value
            )}`
        );

        Logger?.LogInformation(Message, Class);
    } catch (error) {
        fileError.value = error;
        errorDescription.value = `Getting Last Received ${marketSelection.value} Files for All Types`;
        const Message = `Error retrieving last received ${marketSelection.value} files for all types: ${error}`;
        log.error(Message);
        Logger?.LogError(Message, Class);
    } finally {
        isLoading.value = false;
    }
}
async function GetLastReceivedSFTPFilesDetails(market: string, fileType: string) {
    const name = GetLastReceivedSFTPFilesDetails.name;
    const Class = fileName + "/" + name;
    var Message = `Getting Last Received SFTP file details (market: ${market}, fileType: ${fileType})`;

    log.trace(`${name}()\n${Message}`);

    isLoading.value = true;

    fileTypes.forEach((fileType) => {
        sessionStorage.removeItem(fileType.ID);
    });

    try {
        const data = await DPServices.GetLastReceivedSFTPFilesDetails(market, fileType);
        //const data = (await lastReceivedFileDetails.get()).data;
        lastReceivedFilesDetails.value = new LastReceivedFilesDetails(data);

        waitingLastReceivedFilesDetails.value = lastReceivedFilesDetails.value.Waiting;
        processedLastReceivedFilesDetails.value = lastReceivedFilesDetails.value.Processed;
        failedLastReceivedFilesDetails.value = lastReceivedFilesDetails.value.Failed;

    } catch(error) {
        fileError.value = error;
        errorDescription.value = `Getting Last Received ${marketSelection.value} Files of Type ${fileTypeSelection.value}`;

        Message = `(Error) ${Message}: ${error}.`;
        log.error(Message);
        Logger?.LogError(Message, Class);
    } finally {
        isLoading.value = false;
    }
}
async function GetStagingEntries(market: string){
    const name = GetStagingEntries.name;
    const Class = fileName + "/" + name;
    var Message = `Getting Staging Entries (market: ${market})`;

    try {
        stagingEntries.value = undefined;
        ResetErrors();
        const response = await DPServices.GetStagingEntries(market);
        stagingEntries.value = response;
    } catch(error) {
        stagingError.value = error;
        errorDescription.value = `Getting Staging Entries for ${marketSelection.value}`;

        Message = `(Error) ${Message}: ${error}.`;
        log.error(Message);
        Logger?.LogError(Message, Class);
    } finally {
        isLoading.value = false;
    }
}
async function ViewTableData(stagingEntry: StagingEntry) {
    const name = ViewTableData.name;
    const Class = fileName + "/" + name;
    var Message = `Getting Staging Data (staging entry: ${stagingEntry})`;

    isTableDataLoading.value = true;
    showTableDataPopup.value = true;

    await DPServices.GetStagingData(stagingEntry)
        .then((response: string) => {

            try{
                tableData.value = JSON.stringify(JSON.parse(response), null, 6);
            }
            catch{
                tableData.value = response;
            }
            
            tableDataDisplayName.value = stagingEntry.SourceFileReference;
            tableDataDisplaySubName.value = "Table: " + stagingEntry.TableType.replace("Table", "") + "   |   " +"Loading Seq: " + stagingEntry.LoadingSequence;

            log.trace(
                `GetStagingData()\n Response: ${JSON.stringify(
                    response
                )}`
            );

            isTableDataLoading.value = false;
        })
        .catch((error: any) => {
            showTableDataError.value = true;
            isTableDataLoading.value = false;

            if (error instanceof AxiosError) {
                tableData.value = `{"ERROR":{"Info":"Error Table Data. "Details":"${error}","Status":"${error.response?.status}, ${error.response?.statusText}","Message":"Your request could not be fulfilled at this moment due to a server-side error, please try again later. If this error persists, please raise a ticket with the help desk."}}`
                showTableDataPopup.value = true;
            }

            Message = `(Error) ${Message}: ${error instanceof AxiosError ? error : error.message}.`;
            log.error(Message);
            Logger?.LogError(Message, Class);
        });
}
function PreDeleteStagingEntryWarning(stagingEntries: StagingEntry[]){
    log.trace("PreDeleteStagingEntryWarning()");

    if (stagingEntries.length === 0){
        return;
    }

    stagingDeleteData.value = JSON.stringify(stagingEntries, null, 6);
    confirmStagingDeletion.value = false;
    showStagingDeletePopup.value = true;
    isLoading.value = false;

}
async function DeleteStagingEntries(stagingEntries: StagingEntry[]){
    const name = DeleteStagingEntries.name;
    const Class = fileName + "/" + name;
    var Message = `Deleting Staging Entries (staging entries: ${stagingEntries})`;

    showStagingDeletePopup.value = false;

    isLoading.value = true;

    const stagingEntriesBody : StagingEntries =  {
        StagingEntries: stagingEntries
    }
    try {
        const response = await DPServices.DeleteStagingEntries(stagingEntriesBody);
        log.trace(
            `DeleteStagingEntries()\n Response: ${JSON.stringify(
                response
            )}`
        );
        await GetStagingEntries(marketSelection.value);
        stagingViewTable.value?.ClearChecked();
    } catch(error) {
        infoError.value = error;
        errorDescription.value = `Deleting Staging Entries for ${stagingEntries}`;

        Message = `(Error) ${Message}: ${error}.`;
        log.error(Message);
        Logger?.LogError(Message, Class);
    } finally {
        isLoading.value = false;
    }
}
async function ViewSourceFile(market: string, fileType: string, status: string, sourceFileName: string, createdOn?: Date) {
    const name = ViewSourceFile.name;
    const Message = `Viewing source file: ${sourceFileName} (market: ${market}, file type: ${fileType}).`;
    const Class = fileName + "/" + name;

    log.trace(`${name}()\n${Message}`);
    sourceFileDisplayName.value = sourceFileName;
    showSourceFileError.value = false;
    showSourceFilePopup.value = true;
    isSourceFileLoading.value = true;

    Logger?.LogInformation(Message, Class);
    if (fileTypeSelection.value in sessionStorage) {
        log.debug(
            `ViewSourceFile()\nChecking session storage for ${fileTypeSelection.value} ${fileName}`
        );

        const cacheItem: any = JSON.parse(sessionStorage.getItem(fileTypeSelection.value)!);

        if (sourceFileName in cacheItem) {
            log.debug(
                `ViewSourceFile()\nLoading ${fileTypeSelection.value} ${fileName} source file from session storage`
            );
            const sourceFile: any = cacheItem[sourceFileName];
            try {
                DisplaySourceFile(sourceFile);
            }
            catch {
                DisplaySourceFile(sourceFile, false);
            }
        } else {
            log.debug(
                `ViewSourceFile()\n${fileTypeSelection.value} ${fileName} not in session storage`
            );
            await GetSourceFileAndHandleErrors(Class, Message, market, fileType, status, sourceFileName, createdOn);
        }
    } else {
        await GetSourceFileAndHandleErrors(Class, Message, market, fileType, status, sourceFileName, createdOn);
    }
    isSourceFileLoading.value = false;
}
async function GetSourceFileAndHandleErrors(Class: string, Message: string, market: string, fileType: string, status: string, sourceFileName: string, createdOn?: Date) {
    log.debug(
        `ViewSourceFile()\nLoading ${fileTypeSelection.value} source file from API`
    );
    await DPServices.GetSourceFile(fileType, status, sourceFileName, createdOn)
        .then((data: any) => {
            try {
                DisplaySourceFile(data);
            }
            catch {
                DisplaySourceFile(data, false);
            }
            CacheSourceFile(sourceFileName, data);

            Logger?.LogInformation(Message, Class);
        })
        .catch((error: any) => {
            showSourceFileError.value = true;
            isLoading.value = false;

            if (error instanceof AxiosError) {
                DisplaySourceFile(
                    `{"ERROR":{"Info":"Error viewing source file: ${sourceFileName}","File type":"${fileType}","Details":"${error}","Status":"${error.response?.status}, ${error.response?.statusText}","Message":"Your request could not be fulfilled at this moment due to a server-side error, please try again later. If this error persists, please raise a ticket with the help desk."}}`
                );
                Message = `(Error) ${Message}: ${error}.`;
            } else {
                DisplaySourceFile(error, false);
                Message = `(Error) ${Message}: ${error.message}.`;
            }
            log.error(Message);
            Logger?.LogError(Message, Class);
        });
}
function DisplaySourceFile(fileContents: string, jsonFile: boolean=true) {
    log.trace("DisplaySourceFile()\nfileContents:", fileContents);
    sourceFile.value = jsonFile
        ? JSON.stringify(JSON.parse(fileContents), null, 6)
        : "<div style='color: red'>INVALID FILE:<br>This file is not properly JSON formatted.</div><br>" + fileContents;
}
function ViewLoadingError(stagingEntry: StagingEntry) {

    log.trace(`ViewLoadingError()`);
    loadingErrorDisplayName.value = stagingEntry.SourceFileReference      ;
    showLoadingErrorPopup.value = true;

    loadingError.value = stagingEntry.LoadingError;
}
function CacheSourceFile(sourceFileName: string, fileContents?: string) {
    log.trace("CacheSourceFile()");
    const cacheItem: any = fileTypeSelection.value in sessionStorage
        ? JSON.parse(sessionStorage.getItem(fileTypeSelection.value)!)
        : {};
    cacheItem[sourceFileName] = fileContents;
    sessionStorage.setItem(fileTypeSelection.value, JSON.stringify(cacheItem));
}
function LastReceivedFilesRowClicked(row: LastReceivedFilesByTypeDataItem) {
    log.trace("LastReceivedFilesRowClicked()");

    console.log(row);
    console.log(row.FileType);

    fileTypeSelection.value = localFileTypes.value.find((fileType) => fileType.APIName === row.FileType)!.ID;

    showFileView.value = (fileTypeSelection.value === "ALL_FILE_TYPES");
    showFileDetailsView.value = !(fileTypeSelection.value === "ALL_FILE_TYPES");
    showStagingView.value = false;

    GetLastReceivedSFTPFilesDetails(marketSelection.value, fileTypeSelection.value);
}
async function updateMarketSelection() {
    log.trace("updateMarketSelection()");
    log.debug("Market selection changed");

    const name = updateMarketSelection.name;
    const Message = `Changed selection to: market: ${marketSelection.value}.`;
    const Class = fileName + "/" + name;

    ResetErrors();

    if (showFileView.value) {
        await GetLastReceivedSFTPFiles(marketSelection.value);
    }
    else if (showFileDetailsView.value){
        await GetLastReceivedSFTPFilesDetails(marketSelection.value, fileTypeSelection.value);
    } 
    else if (showStagingView.value) {
        await GetStagingEntries(marketSelection.value);
    }

    Logger?.LogInformation(Message, Class);
}
async function updateFileTypeSelection() {
    log.trace("updateFileTypeSelection()");
    log.debug("File Type selection changed");

    const name = updateFileTypeSelection.name;
    const Message = `Changed selection to: fileType: ${fileTypeSelection.value}.`;
    const Class = fileName + "/" + name;

    // Fetch Data
    if (showFileView.value || showFileDetailsView.value) {

        ResetErrors();

        showFileView.value = (fileTypeSelection.value === "ALL_FILE_TYPES");
        showFileDetailsView.value = !(fileTypeSelection.value === "ALL_FILE_TYPES");

        if (showFileView.value) {
            await GetLastReceivedSFTPFiles(marketSelection.value);
        }
        else {
            await GetLastReceivedSFTPFilesDetails(marketSelection.value, fileTypeSelection.value);
        } 
    }
    Logger?.LogInformation(Message, Class);
}
async function HandleFileViewClick() {
    log.trace("HandleFileViewClick()");
    log.debug("File View Clicked");

    ResetErrors();

    showFileView.value = (fileTypeSelection.value === "ALL_FILE_TYPES");
    showFileDetailsView.value = !(fileTypeSelection.value === "ALL_FILE_TYPES");  
    showStagingView.value = false;

    if (showFileView.value) {
      await GetLastReceivedSFTPFiles(marketSelection.value);
    }
    else {
      await GetLastReceivedSFTPFilesDetails(marketSelection.value, fileTypeSelection.value);
    }
}
async function HandleStagingViewClick() {
    log.trace("HandleStagingViewClick()");
    log.debug("Staging View Clicked");

    showFileView.value = false;
    showFileDetailsView.value = false;  
    showStagingView.value = true;

    await GetStagingEntries(marketSelection.value);
}
function ResetErrors(){
    showSourceFileError.value = false;
    showTableDataError.value = false;
    fileError.value = undefined;
    stagingError.value = undefined;
    infoError.value = undefined;
    errorDescription.value = undefined;
}
function FormatLoadingError(loadingError: string) {
    log.trace("FormatLoadingError()");
    log.debug("Formatting Loading Error");

    if (loadingError === undefined || loadingError === null) {
        return loadingError;
    }
    
    if (loadingError.includes("conflicted with the FOREIGN KEY constraint")) {

        const fkConstraintMatch = loadingError.match(/FOREIGN KEY constraint "(.*?)"/);
        const tableMatch = loadingError.match(/(\w+Table) {([^}]+)}/); 
        const addedFkMatch = loadingError.match(/Added FK {([^}]+)}/);
        
        if (!fkConstraintMatch || !tableMatch || !addedFkMatch){    
            return loadingError;
        }
        else{
            const fkConstraint = fkConstraintMatch[1];
            const tableName = tableMatch[1];
            const tableInfo = tableMatch[2];
            const addedFkInfo = addedFkMatch[1];
            return `Foreign Key Conflict: ${fkConstraint} on ${tableName} {${tableInfo}} Added FK {${addedFkInfo}}`;
        }
    }
    else{
        return loadingError;
    }
}
const backButtonFileViewAction = computed(() => {
    return fileTypeSelection.value != "ALL_FILE_TYPES" && showStagingView.value == false
});
watch(backButtonFileViewAction, (newValue, oldValue) => {
    if(newValue == true){
        // this prevents back button default action
        window.history.pushState(null, "", window.location.href); 
        //this is to detect that back button was pressed and replaces its default action with a custom action
        window.addEventListener('popstate', () => {
            fileTypeSelection.value = "ALL_FILE_TYPES"
            updateFileTypeSelection();
        });
    }
});
</script>

<style>
.LoadingError {
    width: 16vw;
}
.overflow-text {
    white-space: nowrap;
    overflow: hidden; 
    text-overflow: ellipsis;
    max-width: 16vw;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
}
.overflow-text:hover {
  cursor: pointer; 
  color: blue; 
  text-decoration: underline; 
}
.TimesLoaded {
    width: 5rem;
}
.LoadingSequence {
    width: 5rem;
}
.TableData {
    width: 4rem;
}
.FileType{
    width: 10%;
    border-right:   solid var(--primary-color-lighter) !important;
}
.WaitingName{
    width: 24%;
    border-right:  0px solid var(--primary-color-lighter) !important;
}
.ProcessedName{
    width: 24%;
    border-right:  0px solid var(--primary-color-lighter) !important;
}
.FailedName{
    width: 24%;
    border-right:  0px solid var(--primary-color-lighter) !important;
}
.FileNameDisplay{
    word-break: break-all !important;
}
.WaitingCreatedOn{
    border-right:   solid var(--primary-color-lighter) !important;
    text-align: right !important;
}
.ProcessedCreatedOn{
    border-right:   solid var(--primary-color-lighter) !important;
    text-align: right !important;
}
.FailedCreatedOn{
    text-align: right !important;
}  
</style>

<style scoped lang="scss">
@import "@/assets/styles/centre-button.scss";
.DataProcessing {
    flex-direction: column;
}
#details-view {
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 1rem;
}
.button-left {
    border-radius: var(--topbar-sidebar-content-radius) 0px 0px 0px;
    padding-left: 2rem;
}
.button-right {
    border-radius: 0px var(--topbar-sidebar-content-radius) 0px 0px;
    padding-right: 2rem;
}
.bottom-margin-only {
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 10px;
}
.center-buttons button:disabled {
    color: blue;
    background-color: var(--light-grey);
}
.center-buttons-button-disabled:disabled {
    color: var(--primary-color-dark) !important;
    background-color: var(--light-grey);
}
.selected {
    background-color: var(--primary-color-lighter) !important;
    border-width: 0 0 .2rem 0;
    border-color: blue;
}
.unselected {
    color: #b6b6b6;
    background-color: #f0f0f0;
    font-weight: normal;
    border-color: white;
}
.confirm-button {
    margin-top: 10px;
    border-width: 0px;
    border-radius: var(--buttons-border-radius);
    background-color: var(--primary-color-lighter);
    padding: 10px;
    color: blue;
    font-size: larger;
    font-weight: bold;
    width: 100%;
}
.confirm-button:hover {
    background-color: var(--primary-color);
    color: white;
    cursor: pointer;
}
.confirm-button:disabled {
    color: #b6b6b6;
    background-color: var(--light-grey);
}
.confirm-button:disabled:hover {
    background-color: var(--primary-color-lighter);
    cursor: not-allowed;
}
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.popup {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    max-width: 75%;
    max-height: 75%;
    overflow-y: auto;
}
.popup-button {
    margin-top: 1rem;
    border-radius: var(--table-button-radius);
    border-width: 1px;
    border-color: blue;
    color: blue;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
}
.popup-button:hover {
    cursor: pointer;
    background-color: var(--primary-color);
    color: white;
}
.description-title {
    font-size: 1.3rem;
    text-align: center;
    padding-bottom: 0.3rem;
}
.description-subtitle {
    padding-bottom: 1rem;
    text-align: center;
}
.description-subtitle input {
    font-size: .8rem;
    border-radius: .4rem;
    padding-left: .6rem;
    padding-top: .2rem;
    padding-bottom: .2rem;
}
.label {
    vertical-align: middle;
}
.label-bold {
    vertical-align: middle;
    font-weight: bold;
}
.wrap {
    white-space: pre-line;
}
.anw-info-textbox {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;
}
.details-column {
    flex: 1 1 33%;
}
.details-column-title {
    font-size: large;
    font-weight: bold;
    text-align: center;
    color: var(--primary-color-dark);
}
.text-link {
    color: blue;
    text-decoration: underline;
}
.text-link:hover {
    cursor: pointer;
}
.text-link-denied {
    color: #333;
    border: none;
    background-color: transparent;
}
:deep(.WaitingCreatedOn>div),
:deep(.ProcessedCreatedOn>div),
:deep(.FailedCreatedOn>div),
:deep(.CreatedOn>div) {
    max-width: 10ch !important;
    margin-left: auto;
    text-align: right;
}
.filename {
    max-width: 60ch; overflow: hidden; text-overflow: ellipsis;
}
</style>