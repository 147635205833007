<template>
    <div class="content-container" style="justify-content: flex-start; padding-top: 1rem">
        <Notifier
            v-model="showingSuccNotifier"
            :type="NotifierType.SUCCESS"
            msg="VAT payment successfully registered."
        />
        <GettingErrorInfo
            :showError="!!infoError"
            errorDescription="(Getting Vat Loans)"
            :error="infoError"
            :change="!!infoError"
        />
        <div v-if="isLoading" class="loading">
            <span class="loader"></span>
        </div>
        <div v-else style="display: contents">
            <div class="top-content">
                <form class="form-container flex-row-centered" @submit.prevent="submit()">
                    <MarketSelector />
                    <div class="flex-col-centered date-picker selection">
                        <label for="vat-return-date">
                            <h3 class="header">Drawdown Date:</h3>
                        </label>
                        <DatePicker
                            id="drawdown-date"
                            v-model="drawdownDate"
                            type="date"
                            placeholder="Select Date..."
                            :format="dateFormat"
                            :enable-time-picker="false"
                            textInput
                            prevent-min-max-navigation
                            :teleport="true"
                            required
                            :maxDate="new Date()"
                        />
                    </div>
                    <div class="flex-col-centered selection">
                        <label for="drawdown-amount">
                            <h3 class="header">Drawdown Amount:</h3>
                        </label>
                        <CurrencyInput
                            id="drawdown-amount"
                            v-model="drawdownAmount"
                            :min="0.01"
                            title="The drawdown amount"
                            required
                        />
                    </div>
                    <button type="submit" class="default-btn" :title="submitHint">
                        Confirm
                    </button>
                </form>
            </div>
        </div>
        <DrawdownPopUp
            v-if="showPopUp"
            v-model="payment"
            @confirm="refreshAfterConfirm"
        />
    </div>
</template>

<script setup lang="ts">
import { inject, ref } from "vue";
import MarketSelector from "@/components/MarketSelector.vue";
import ErrorInfo from "@/components/ErrorInfo.vue";
import Notifier from "@/components/Notifier.vue";
import NotifierType from "@/types/NotifierType";
import { dateFormat, marketSelection } from "@/services/helpers/Helper";
import DatePicker from "@/components/common/DatePicker.vue";
import CurrencyInput from "@/components/CurrencyInput.vue";
import { globalReloadKey } from "@/types/ServiceKeys";
import DrawdownPopUp from "@/components/VATLoan/DrawdownPopUp.vue";
import { DrawdownTableItem } from "@/models/Drawdown";
import { AxiosError } from "axios";

// ERRORS
const GettingErrorInfo = ErrorInfo;
const infoError = ref<AxiosError | undefined>(undefined);

const isLoading = ref(false);
const showingSuccNotifier = ref(false);
const drawdownDate = ref<Date | undefined>();
const drawdownAmount = ref<string | undefined>();
const submitHint = "Submit the payment form";
const payment = ref<Partial<DrawdownTableItem>>({ Market: marketSelection.value });
const showPopUp = ref<boolean>(false);
const reload = inject(globalReloadKey);

async function submit() {
    payment.value = {
        Market: marketSelection.value,
        DrawdownDate: drawdownDate.value!,
        DrawdownAmount: parseFloat(drawdownAmount.value!),
    };

    showPopUp.value = true;
}

function refreshAfterConfirm() {
    showPopUp.value = false;
    reload?.();
}
</script>

<style scoped lang="scss">
.form-container {
    display: grid;
    grid-template-columns: auto auto;
    gap: 20px;
    justify-content: space-evenly;
    justify-items: center;
    align-items: center;

    > * {
        min-width: 10rem;
        width: 100%;

        > * {
            width: 100%;
        }
    }

    :deep(select) {
        width: 100%;
    }
}

.form-container-confirmed {
    display: grid;
    grid-template-columns: auto auto;
    gap: 20px;
    justify-content: space-evenly;
    justify-items: center;
    align-items: center;

    > * {
        min-width: 10rem;
        width: 100%;

        > * {
            width: 100%;
        }
    }

    :deep(select) {
        width: 100%;
    }
}
</style>
