import { computed, watch } from 'vue';
//cyclic reference... but no choice...
import { TableExpandableColumnsProps } from "@/components/common/TableExpandableColumns.vue";
import useVModelDefaultVar from './VModelDefaultVar';

export const enum ExpandState {
    Collapsed,
    Expanded,
}
export const labels = ['Expand columns', 'Collapse columns'];
export default function useTableExpandableColumns<T>(props: TableExpandableColumnsProps<T>, emits: (e: 'update:modelValue', v?: ExpandState)=>void) {
    const expandState = useVModelDefaultVar(props, emits, 'modelValue', props.expandedCols ? ExpandState.Collapsed : ExpandState.Expanded);
    const curCols = computed(()=>props.expandedCols?(expandState.value===ExpandState.Expanded?props.expandedCols:props.cols):props.cols);
    // for expanded state of removing the option for expandedCols
    watch(()=>props.expandedCols, (newCols, oldCols)=>{
        if (oldCols && !newCols) expandState.value = ExpandState.Expanded;
    })
    // for expanded state if expandedCols is not defined
    watch(()=>props.modelValue, (newVal)=>{
        if (!props.expandedCols && newVal === ExpandState.Collapsed) expandState.value = ExpandState.Expanded;
    })
    return {
        labels,
        expandState,
        curCols
    }
}