<template>
    <div class="content-container">
        <div class="top-content">
            <div class="flex-row-centered selection-form">
                <div class="flex-col-centered input-container">
                    <MarketSelector @change=changeMarket :enableSE="true" />
                    <slot name="filter-group-extra"></slot>
                </div>
                <form class="flex-col-centered input-container" @submit.prevent=searchContracts>
                    <TitledSelection :for="searchOption === 'ID' ? 'contact-id' : 'contract-dates'"
                        :title="`Search ${contractName} Contracts:`">
                            <GroupedInputButton>
                                <div style="display: grid; grid-template-columns: max-content;">
                                    <input v-model=contractIDs
                                        id="contact-id"
                                        type="text"
                                        placeholder="Enter Contract ID..."
                                        @keyup.enter="searchContracts"
                                        style="grid-column: 1; grid-row: 1; width: 100%;"
                                        :required="!loadAllByDefault && searchOption === 'ID'"
                                        :class="{ hidden:searchOption !== 'ID' }"
                                    />
                                    <DatePicker uid="contract-dates"
                                        v-model=dateRange
                                        range
                                        :enable-time-picker=false
                                        :format=FormatDatePicker
                                        text-input
                                        :teleport=true
                                        placeholder="Select Date..."
                                        :text-input-options=dateInputFormatOption
                                        :partial-range=false
                                        style="grid-column: 1; grid-row: 1;"
                                        :required="!loadAllByDefault && searchOption !== 'ID'"
                                        :class="{ hidden:searchOption === 'ID' }"
                                    />
                                </div>
                                <select v-model=searchOption id="filter-option">
                                    <option v-for="op of Object.entries(FilterOptions)" :key="op[0]" :value="op[0]">
                                        {{ op[1] }}
                                    </option>
                                </select>
                                <button class="selection-form-btn" :title=searchHoverText>Search</button>
                            </GroupedInputButton>
                    </TitledSelection>
                    <slot name="search-group-extra">
                        <button v-if=!disallowBatchDownload
                            class="default-btn"
                            type="button"
                            @click=clientDownloadHireAgreements
                            :disabled=isDownloading
                            :title=downloadHireAgreementsHoverText
                        >Download Agreements</button>
                    </slot>
                </form>
                <slot name=header-extra></slot>
            </div>
        </div>
        <Table ref="contractTable"
            :Rows=displayedContracts
            :Cols=cols
            :RowCheckableFilter=rowCheckableFilter
            :HasError=!!tableError
            :HasCheckBox=_hasCheckbox
            SplitColKeys
            :ReturnKey=true
            CheckIncludesNonDisplayed
            :ColKey="'contractID' as KeyTypeKeys<T>"
            :RowClickedCallBack=rowClickedCallback
            @check="anyChecked = true"
            @clear="anyChecked = false" 
        >
            <template v-for="col of cols.filter(x=>x!=='contractID'&&x!=='hasFileData')" v-slot:[`${String(col)}`]={value}>
                <slot :name=col :value=value>
                    {{ value[col] }}
                </slot>
            </template>
            <template #validation-header>
                Overall Validation
            </template>
            <template #contractID={value}>
                <div v-if="noSingleDownload || disallowDownload">
                    {{ value.contractID }}
                </div>
                <div v-else>
                    <div v-if="marketSelection=='GB'"
                    @click="e=>value.hasFileData?(()=>{viewPdf(value.contractID, 'HireAgreement' );e.stopPropagation()})():undefined"
                    :class="{'fake-link':value.hasFileData}">
                        {{ value.contractID }}
                    </div>
                    <div v-else>
                        {{ value.contractID }}
                    </div>
                </div>
            </template>
            <template #hasFileData="{value}" v-if=!noSingleDownload >
                <TickCrossBooleanDisplay :value="value.hasFileData" />
            </template>
        </Table>
    </div>
</template>

<script setup lang="ts" generic="T extends IContractRaw">
import { computed, onMounted, inject, ref } from "vue";
import { useContractHireAgreement } from "@/services/composables/ContractHireAgreement";
import { FormatDatePicker } from "@/services/helpers/Helper";
// Data
import IContractRaw from "@/Interfaces/Contract/IContractRaw";
// Table
import Table, {KeyTypeKeys} from "@/components/TablePersistentChecking.vue";
// Misc
import DatePicker from '@/components/common/DatePicker.vue';
import TickCrossBooleanDisplay from "@/components/TickCrossBooleanDisplay.vue";
import MarketSelector from "@/components/MarketSelector.vue";
import { marketSelection } from "@/services/helpers/Helper";
import GroupedInputButton from "@/components/GroupedInputButton.vue";
import FilterOptions from "@/models/enums/ContractHAFilterOptions";
import { loggerKey } from "@/types/ServiceKeys";
import TitledSelection from "@/components/TitledSelection.vue";
import { useRoute } from "vue-router";
import { DisplayableKeys } from "@/services/SorterService";
import IContractPageExpose from "@/Interfaces/components/IContractPageExpose";

export interface IContractProps<T> {
    contractGetAPI: (req)=>Promise<T[]>;
    cols: DisplayableKeys<T>[];
    loadAllByDefault?: boolean;
}
interface IContractPropsInternal<T>{
    contractGetAPI: (req)=>Promise<T[]>;
    hasCheckbox?: boolean;
    cols?: DisplayableKeys<T>[];
    contractName?: string;
    rowFilters?: ((row: T)=>boolean)[];
    rowClickedCallback?: (row: T)=>void;
    rowCheckableFilter?: (row: T)=>boolean;
    loadAllByDefault?: boolean;
    noSingleDownload?: boolean;
    seMarketEnable?: boolean
}
const props = withDefaults(defineProps<IContractPropsInternal<T>>(), {
    contractName: 'Hire Agreement',
    hasCheckbox: undefined,
    rowCheckableFilter: (row: IContractRaw)=>row.hasFileData,
    cols: ()=>[
        'contractID',
        'customerID',
        'orderID',
        'market',
        'status',
        'hasFileData',
        'initialDate',
        'activationDate',
        'terminationDate'
    ] as DisplayableKeys<T>[],
    seMarketEnable: false
});
const fileName = 'ContractHireAgreement.vue';
const log = inject(loggerKey);
const {
    downloadHireAgreementsHoverText,
    searchHoverText,
    dateInputFormatOption,
    isDownloading,
    searchOption,
    contractIDs,
    dateRange,
    updateContracts,
    downloadedContracts,
    contractTable,
    tableError,
    disallowDownload,
    disallowBatchDownload,
    anyChecked,
    getSelected,
    searchContracts,
    clientDownloadHireAgreements,
    viewPdf,
    clearChecked, 
    getTableRef,    
} = useContractHireAgreement<T>(props);
const displayedContracts = computed(()=>
    props.rowFilters?.reduce((arr, filter)=>arr?.filter(filter), downloadedContracts.value) ??
        downloadedContracts.value
);
const _hasCheckbox = computed(()=>props.hasCheckbox ?? !disallowBatchDownload);
defineExpose({
    downloadedContracts,
    anyChecked,
    getSelected,
    viewPdf,
    clearChecked,
    searchContracts,
    updateContracts,
    marketSelection,
    getTableRef
});
const route = useRoute();
onMounted(() => {
    log?.info(`${fileName} - onMounted()`);
    document.title = `${route.name?.toString()} - Optio`;
});
function changeMarket(){
    downloadedContracts.value = [];
    contractIDs.value = '';
}
</script>
<style scoped lang="scss">
@import "@/assets/styles/selection-form-inputs.scss";
.selection-form  {
    align-items: flex-start;
}
</style>