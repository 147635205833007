import IFinalSettlementTable from "@/Interfaces/CustomersAndContracts/IFinalSettlementTable";
import { extractNonArraysFromObj } from '@/services/helpers/ObjectHelper';
import DateIgnoreTime from "@/types/DateIgnoreTime";

export default class FinalSettlementTable implements IFinalSettlementTable {
    CONTRACT_ID: string;
    VEHICLE_RETURN_DATE: DateIgnoreTime;
    VEHICLE_REMARKETING_DATE: DateIgnoreTime;

    constructor(data: IFinalSettlementTable) {
        Object.assign(this,extractNonArraysFromObj(data));
        this.CONTRACT_ID = data.CONTRACT_ID;
        this.VEHICLE_RETURN_DATE=new DateIgnoreTime(data.VEHICLE_RETURN_DATE);
        this.VEHICLE_REMARKETING_DATE=new DateIgnoreTime(data.VEHICLE_REMARKETING_DATE);
    }
}
