<template>
    <div class="power-bi">
        <iframe
            title="DashboardReports"
            :src="pbi"
            frameborder="0"
            allowFullScreen="true"
            :key="iframeKey"
        ></iframe>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount } from "vue";
import log from "loglevel";

document.title = `Home - Optio`

const pbi: string = process.env.VUE_APP_POWER_BI_REPORT_URL!;

// Create a ref to store the visibility state
const isPageVisible = ref(true);

// Bind iframe key to reactive property
const iframeKey = ref(0);

// Function to handle visibility change
const handleVisibilityChange = () => {
  if (document.hidden) {
    // Page is not visible
    isPageVisible.value = false;
    log.debug("isPageVisible:", isPageVisible.value);
  } else {
    // Page is visible
    isPageVisible.value = true;
    log.debug("isPageVisible:", isPageVisible.value);
    // iframeKey.value += 1;
    iframeKey.value = 1;
    log.debug("iframeKey:", iframeKey.value);
  }
};

// Add event listener for visibility change
onMounted(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
});

// Remove event listener when the component is unmounted
onBeforeUnmount(() => {
    document.removeEventListener('visibilitychange', handleVisibilityChange);
});
</script>

<style>
.power-bi {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
}

iframe {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: var(--topbar-sidebar-content-radius);
}
</style>
