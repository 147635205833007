import IRvgfTable from "@/Interfaces/CustomersAndContracts/IRvgfTable";
import { extractNonArraysFromObj } from '@/services/helpers/ObjectHelper';
import DateIgnoreTime from "@/types/DateIgnoreTime";
import DatePeriod from "@/types/DatePeriod";

export default class RvgfTable implements IRvgfTable {
    CONTRACT_ID: string;
    PERIOD_START: DateIgnoreTime;
    PERIOD_END: DateIgnoreTime;
    PERIOD_MONTH: DatePeriod;
    REMARKETING_DATE: DateIgnoreTime;

    constructor(data: IRvgfTable) {
        Object.assign(this,extractNonArraysFromObj(data));
        this.CONTRACT_ID = data.CONTRACT_ID;
        this.PERIOD_START=new DateIgnoreTime(data.PERIOD_START);
        this.PERIOD_END=new DateIgnoreTime(data.PERIOD_END);
        this.PERIOD_MONTH=new DatePeriod(data.PERIOD_MONTH);
        this.REMARKETING_DATE=new DateIgnoreTime(data.REMARKETING_DATE);
    }
  }
  