import IFinalSettlementInvoiceTable from "@/Interfaces/CustomersAndContracts/IFinalSettlementInvoiceTable";
import { extractNonArraysFromObj } from '@/services/helpers/ObjectHelper';
import DateIgnoreTime from "@/types/DateIgnoreTime";

export default class FinalSettlementInvoiceTable implements IFinalSettlementInvoiceTable {
    CONTRACT_ID: string;
    INVOICE_ID: string;
    ISSUE_DATE: DateIgnoreTime;
    DUE_DATE: DateIgnoreTime;

    constructor(data: IFinalSettlementInvoiceTable) {
        Object.assign(this,extractNonArraysFromObj(data));
        this.CONTRACT_ID = data.CONTRACT_ID;
        this.INVOICE_ID = data.INVOICE_ID;
        this.ISSUE_DATE=new DateIgnoreTime(data.ISSUE_DATE);
        this.DUE_DATE=new DateIgnoreTime(data.DUE_DATE);
    }
}
