<template ref="TransferPayments">
    <div class="tabs-container">
        <div class="top-tabs tabs-buttons">
            <div class="tabs">
                <button
                    :class="`button-left ${showOutstanding ? 'selected' : 'unselected'}`"
                    :disabled="showOutstanding"
                    :title="
                        showOutstanding
                            ? 'Currently viewing outstanding Transfer Payments'
                            : 'Click to view Transfer Payment history'
                    "
                    @click="ToggleTabs()"
                >
                    Outstanding
                </button>
            </div>
            <div class="tabs">
                <button
                    :class="`button-right ${showOutstanding ? 'unselected' : 'selected'}`"
                    :disabled="!showOutstanding"
                    :title="
                        !showOutstanding
                            ? 'Currently viewing Financing Fees'
                            : 'Click to view Financing Fees'
                    "
                    @click="ToggleTabs()"
                >
                    Confirmed
                </button>
            </div>
        </div>
    </div>
    <div class="content-container">
        <div class="top-content">
            <GettingUnpaidTransferPaymentsErrorInfo
                :showError="!!infoError"
                errorDescription="(Getting Transfer Payments)"
                :error="infoError"
                :change="!!infoError"
            />
            <div class="flex-row-centered">
                <MarketSelector  @change="updateSelection" enableSE />
                <div class="flex-col-centered">
                    <div>Transfer Type Selection:</div>
                    <select
                        @change="updateDisplayed"
                        v-model="selectedType"
                        class="dropdown wider"
                    >
                        <option value="">All</option>
                        <option
                            v-for="transferType in transferTypes"
                            :value="transferType.key"
                            :key="transferType.key"
                        >
                            {{ transferType.val }}
                        </option>
                    </select>
                </div>
                <div class="flex-col-centered">
                    <div class="center-buttons">
                        <button
                            v-if="showOutstanding"
                            ref="transferPaymentButton"
                            @click="toggleProceedToPayment"
                            :disabled="hideConfirmButton"
                            class="incident-nav-button"
                        >
                            Confirm Transfer
                        </button>
                        <button
                            v-if="!showOutstanding"
                            ref="transferPaymentButton"
                            @click="downloadAll"
                            class="incident-nav-button"
                        >
                            Export to CSV
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <TablePersistentChecking
            ref="table"
            :Rows="displayedTransferPayments"
            :Cols="cols"
            :HasCheckBox="hasCheckboxes"
            :RowClickedCallBack="expandRow"
            HasPreCol
            :SplitColKeys="true"
            :DefaultSortCols="defaultSortCols"
            :DefaultSortOrders="defaultSortOrders"
        >
            <template #post-row="{ value }">
                <tr
                    v-if="expanded && expandedRowId === value.TransactionId"
                    :class="['inner-table-row']"
                >
                    <td class="small"></td>
                    <td :colspan="cols.length + 20" class="inner-table-cont">
                        <div v-if="isLoading" class="content-container">
                            <div class="loading">
                                <span class="loader"></span>
                            </div>
                        </div>
                        <TablePersistentChecking
                            class="inner-table"
                            :Rows="expandedRows"
                            :Cols="transactionCols"
                            SplitColKeys
                            :DefaultSortCols="defaultSortColsInner"
                            :DefaultSortOrders="defaultSortOrdersInner"
                        />
                    </td>
                </tr>
            </template>
        </TablePersistentChecking>

        <TransferPopUp
            v-model="selectedPayments"
            @confirm="refreshAfterConfirm"
        ></TransferPopUp>
    </div>
</template>

<script setup lang="ts">
import { ref, Ref, onMounted, computed, inject, onUnmounted } from "vue";
import { defaultMarket, marketSelection } from "@/services/helpers/Helper";
import { TransferPaymentsHelper } from "@/services/helpers/Ledgering/LedgerTransferPaymentsHelper";
import {
    getCols,
    transactionCols,
} from "@/services/helpers/Ledgering/LedgerTransferPaymentsHelper";
import ErrorInfo from "@/components/ErrorInfo.vue";
import TransferPaymentTable from "@/models/Ledgering/TransferPaymentTable";
import TransferPopUp from "@/components/Ledger/TransferPopUp.vue";
import TablePersistentChecking, {
    ITableExpose,
} from "@/components/TablePersistentChecking.vue";
import { globalReloadKey, loggerKey } from "@/types/ServiceKeys";
import { useRoute } from "vue-router";
import { authService } from "@/auth/authSingleton";
import { GetAuthLevel } from "../config/AuthConfig";
import DbTableCsvHelper from "@/services/helpers/DbTableCsvHelper";
import LedgerTransferStatus from "@/types/LedgerTransferStatus";
import MarketSelector from "@/components/MarketSelector.vue";
import LedgerTransaction from "@/models/Ledgering/LedgerTransaction";
import { ledgerTransactions } from "@/services/network";
import ILedgerTransactions from "@/Interfaces/Ledgering/ILedgerTransactions";

const selectedType = ref("");
const transferTypes = computed(getUniqueTransferTypes);
const hideConfirmButton = computed(() => !hasCheckboxes.value);
const showOutstanding: Ref<boolean> = ref(true);
const getHistory: Ref<boolean> = ref(false);

// Services
const reload = inject(globalReloadKey);
const LedgerServices: TransferPaymentsHelper = new TransferPaymentsHelper();

// ERRORS
const GettingUnpaidTransferPaymentsErrorInfo = ErrorInfo;
const infoError = ref<unknown>();
const log = inject(loggerKey);

// TABLE
const table = ref<ITableExpose<TransferPaymentTable>>();
const originalTransferPayments: Ref<TransferPaymentTable[] | undefined> = ref();
const hasCheckboxes = computed(() => {
    return showOutstanding.value ? !!selectedType.value : false;
});
const expanded = ref<boolean>(false);
const expandedRows = ref<LedgerTransaction[] | undefined>();
const expandedRowId = ref();
const isLoading = ref<boolean>(false);
const cols = computed(() => getCols(getHistory.value));
const sortCol = computed<keyof TransferPaymentTable>(() =>
    showOutstanding.value ? "TransactionId" : "DateTimeStatusUpdated"
);
const defaultSortCols = computed(() => [
    cols.value.findIndex((col) => col === sortCol.value),
]);
const sortColInner: keyof LedgerTransaction = "paymentId";
const defaultSortColsInner = [transactionCols.findIndex((col) => col === sortColInner)];
const defaultSortOrders = computed(() => (showOutstanding.value ? [1] : [-1]) as (1 | -1)[]);
const defaultSortOrdersInner = ref<(1 | -1)[]>([1]);

async function expandRow(row: TransferPaymentTable) {
    if (expandedRowId.value === row.TransactionId) {
        // Collapse the row if it's already expanded
        expanded.value = false;
        expandedRowId.value = null;
        expandedRows.value = [];
    } else {
        // Expand the row and fetch new data
        expanded.value = true;
        expandedRowId.value = row.TransactionId;
        const transactionId = { transactionId: row.TransactionId };
        isLoading.value = true;
        const res = await ledgerTransactions.get(
            { market: marketSelection.value },
            { count: 40, ...transactionId }
        );
        const data = res.data as ILedgerTransactions;
        const Transactions = data.transactions
            .filter((item) => item.transactionId == row.TransactionId)
            .map((t) => new LedgerTransaction(t));
        expandedRows.value = Transactions;
        isLoading.value = false;
    }
}
// Popup
const selectedPayments = ref<TransferPaymentTable[]>();
const displayedTransferPayments = ref<TransferPaymentTable[]>();

const route = useRoute();
onMounted(() => {
    marketSelection.value = 'GB'
    getTransferPayments()
});
onUnmounted(() => {
    marketSelection.value = 'GB'
})
// formatting the text of the transfer types
function getFormattedIdentifier(obj: TransferPaymentTable): { key: string; val: string } {
    const typeKeyList = [obj.AccountFrom, obj.AccountTo].sort().join(" - ");
    const formattedIdentifier = typeKeyList.replace(/_/g, " ");

    return { key: obj.TransferTypeIdentifier, val: formattedIdentifier };
}
function getUniqueTransferTypes() {
    const data = showOutstanding.value
        ? originalTransferPayments.value
        : originalTransferPayments.value?.filter((x) =>
              x.UserStatusUpdated?.includes("@")
          );
    const uniqueTypes =
        data?.reduce((uniqueTypes, item) => {
            const formattedIdentifier = getFormattedIdentifier(item);

            if (
                !uniqueTypes.some((existing) => existing.key === formattedIdentifier.key)
            ) {
                uniqueTypes.push(formattedIdentifier);
            }
            return uniqueTypes;
        }, [] as { key: string; val: string }[]) ?? [];

    // Sort uniqueTypes alphabetically based on the val property
    uniqueTypes.sort((a, b) => a.val.localeCompare(b.val));

    return uniqueTypes;
}
function updateDisplayed() {
    log?.info("recomputing displayedTransferPayments");
    table.value?.ClearChecked();
    if (!selectedType.value) {
        displayedTransferPayments.value = showOutstanding.value
            ? originalTransferPayments.value
            : originalTransferPayments.value?.filter((x) =>
                  x.UserStatusUpdated?.includes("@")
              );
    } else {
        displayedTransferPayments.value = showOutstanding.value
            ? originalTransferPayments.value?.filter(
                  (x) => x.TransferTypeIdentifier === selectedType.value
              )
            : originalTransferPayments.value?.filter(
                  (x) =>
                      x.TransferTypeIdentifier === selectedType.value &&
                      x.UserStatusUpdated?.includes("@")
              );
    }
}
async function getTransferPayments() {
    infoError.value = undefined;
    const urlParams =
        process.env.VUE_APP_LEDGERING_TRANSFER_PAYMENTS_URL_PARAMS ?? "ledger-transfers";
    var Message = `Getting Transfer Payments from API`;
    try {
        const data = await LedgerServices.getTransferPayments(
            urlParams,
            getHistory.value
                ? LedgerTransferStatus.COMPLETED
                : LedgerTransferStatus.WAITING,
            marketSelection.value
        );
        originalTransferPayments.value = data.map((x) => new TransferPaymentTable(x));
        updateDisplayed();
    } catch (error) {
        infoError.value = error;
        Message = `(Error) ${Message}: ${error}`;
        log?.error(Message, error);
    }
}

function refreshAfterConfirm() {
    reload?.();
}
const toggleProceedToPayment = () => {
    const checked = table.value?.GetChecked();
    if (checked) {
        if (checked.length <= 0)
            window.alert("Warning: no payment has been selected to confirm yet.");
        else selectedPayments.value = checked;
    }
};

const tabsKey = ref(0);
function ToggleTabs() {
    tabsKey.value += 1;
    ResetTabs();
    getTransferPayments();
}

function ResetTabs() {
    showOutstanding.value = !showOutstanding.value;
    selectedType.value = "";
    getHistory.value = !getHistory.value;
    setTimeout(() => {
        table.value?.ResetSort();
    }, 0);
}
async function downloadAll() {
    const userRoleLevel = GetAuthLevel(authService.getUsersRole());

    let formattedData: TransferPaymentTable[];

    if (userRoleLevel > 5) {
        formattedData = originalTransferPayments.value ?? [];
    } else {
        formattedData = displayedTransferPayments.value ?? [];
    }

    DbTableCsvHelper.generateCsvDownload(formattedData, "ConfirmedTransferPayments.csv");
}
function updateSelection() {
    selectedType.value = "";
    getTransferPayments();
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/layout.scss";
@import "@/assets/styles/buttons.scss";
@import "@/assets/styles/table.scss";
</style>
