<template>
  <button ref=element @click="topFunction()" class="topBtn" aria-label="Go to top">↑</button>
</template>

<script setup lang="ts">
import {ref} from 'vue';
const element = ref<HTMLElement>();
// When the user clicks on the button, scroll to the top of the document
function findNearestScrolledDownElement(el?: HTMLElement | null) {
  if (!el) return el;
  if (el.scrollTop > 0) return el;
  if (el.parentElement) return findNearestScrolledDownElement(el.parentElement);
  return el;
}
function topFunction() {
  findNearestScrolledDownElement(element.value?.parentElement)?.scrollTo(element.value?.parentElement?.scrollLeft ?? 0,0);
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/variables.module";
@import "@/assets/styles/colours.module";
@import "@/assets/styles/buttons";

.topBtn {
    position: fixed; /* Fixed/sticky position */
    bottom: 50px; /* Place the button at the bottom of the page */
    right: 0px;
    z-index: 1000; /* Make sure it does not overlap */
    border: none; /* Remove borders */
    outline: none; /* Remove outline */
    background-color: orange; /* Set a background color */
    color: white; /* Text color */
    cursor: pointer; /* Add a mouse pointer on hover */
    padding: 5px 9px 5px 11px; /* Some padding */
    border-top-left-radius: 5px; /* Rounded corners */
    border-bottom-left-radius: 5px;
    font-size: 18px; /* Increase font size */
    text-align: center;
    opacity: 0.7;

  &:hover {
    background-color: #555; /* Add a dark-grey background on hover */
  }
}
</style>