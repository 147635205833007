import { extractNonArraysFromObj } from "@/services/helpers/ObjectHelper";
import ICarSaleCreditNoteTable from "@/Interfaces/CustomersAndContracts/ICarSaleCreditNoteTable";
import DateIgnoreTime from "@/types/DateIgnoreTime";

export default class CarSaleCreditNoteTable implements ICarSaleCreditNoteTable {
    CAR_SALE_INVOICE_ID: string;
    ISSUE_DATE: DateIgnoreTime;
    constructor(data: ICarSaleCreditNoteTable) {
        Object.assign(this,extractNonArraysFromObj(data));
        this.ISSUE_DATE = new DateIgnoreTime(data.ISSUE_DATE);
        this.CAR_SALE_INVOICE_ID = data.CAR_SALE_INVOICE_ID;
    }
}