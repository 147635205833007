<script setup lang="ts" generic="T, TS extends string | undefined">
import {computed} from 'vue';
export type DropdownModelType<T, TS extends string | undefined> = TS extends undefined ? T : T|null;
export interface DropdownArrayProps<T, TS extends string | undefined> {
    modelValue: DropdownModelType<T, TS>;
    placeholder?: TS;
    hideNullOption?: boolean;
    options: T[];
}
const props = defineProps<
    DropdownArrayProps<T, TS>
>();
const emits = defineEmits<{
    (e:'update:modelValue', val: DropdownModelType<T, TS>)
}>();
const mv = computed({
    get() {
        return props.modelValue;
    },
    set(v) {
        emits('update:modelValue', v);
    }
})
const options = computed(()=>[... new Set(props.options)].sort());
</script>

<template>
<select v-model=mv class="dropdown">
    <option v-if=placeholder :value=null>{{ placeholder }}</option>
    <option v-for="(k,i) of options" :key=i :value=k >
        <slot :name=k :value=k>{{ k }}</slot>
    </option>
</select>
</template>

<style scoped lang="scss">
.dropdown {
    min-width: 10rem;
    padding-top: 5px;
    padding-bottom: 5px;
    border: var(--border-width) solid var(--primary-color-dark);
    border-radius: var(--dropdown-radius);
    font-size: medium;
    font-weight: bold;
    color: var(--primary-color-dark);
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
}
</style>