<template>
    <div class="CarPuchase">
        <div v-if="isLoading" class="content-container">
            <div class="loading">
                <span class="loader"></span>
            </div>
        </div>
        <div v-else class="content-container">
            <div class="top-content">
                <GettingCarPurchasesErrorInfo :showError="!!infoError" errorDescription="(Getting Car Purchase)"
                    :error="infoError" :change="!!infoError" />

                <div class="flex-row-centered">
                    <PeriodSelector v-model=period 
                        showTitle 
                        hasNullSelector 
                        :filter="(p)=>carPurchasesCarSaleInvoice?.some(x=>p.within(x.ISSUE_DATE))"
                    />
                    <form class="flex-col-centered" style="row-gap: 5px;" @submit.prevent=submitContractIdFilter >
                        <label for="contractIdsSearchInput">Filter by Contract Ids:</label>
                        <GroupedInputButton>
                            <input id="contractIdsSearchInput" type="text" :style="`min-width: calc(${contractIdSearchInputPlaceholder.length}ch); flex: 1;`"
                                :placeholder=contractIdSearchInputPlaceholder >
                            <button class="selection-form-btn">
                                Search
                            </button>
                        </GroupedInputButton>
                    </form>
                    <button class="centre-button" @click="AssignCarPurchaseToOwner"
                        style="padding-left: 1rem; padding-right: 1rem;"
                        :disabled="DenyFunctionLevelAccess(FunctionAccessLevels.CarPurchasesAssignToOwner)">
                        Purchase Cars
                    </button>
                </div>
            </div>
            <CarPurchasesPopup v-model=popupSaleIds />

            <Table ref="table" :Rows=displayedCarSaleInvoices :Cols=carSaleInvoiceCols :Labels=carSaleInvoiceLabels
                :ColKey=colKey HasCheckBox :RowCheckableFilter="x=>x.APPROVED_FOR_PAYMENT ?? false" CheckIncludesNonDisplayed
                :DefaultSortCols=carPurchasesDefaultSortCols :DefaultSortOrders=carPurchasesDefaultSortOrders
                v-model:RowsPerPage=carPurchaseRowCount
                v-model:Page=carPurchasePage
                :HasError=!!infoError
            >
                <template #AMOUNT_TOTAL="props">
                    {{ MonetaryValueWithCommas(props.value.AMOUNT_TOTAL) }}
                </template>
                <template #AMOUNT_EX_VAT="props">
                    {{ MonetaryValueWithCommas(props.value.AMOUNT_EX_VAT) }}
                </template>
                <template #AMOUNT_VAT="props">
                    {{ MonetaryValueWithCommas(props.value.AMOUNT_VAT) }}
                </template>
                <template #BALANCE="props">
                    {{ MonetaryValueWithCommas(props.value.BALANCE) }}
                </template>
                <template #APPROVED_FOR_PAYMENT="{ value }">
                    <TickCrossBooleanDisplay :value="value.APPROVED_FOR_PAYMENT" />
                </template>
            </Table>
        </div>
    </div>
</template>
<style lang="scss" scoped>
@import "@/assets/styles/centre-button.scss";
@import "@/assets/styles/selection-form-inputs.scss";
</style>
<script setup lang="ts">
import { ref, computed, onMounted, inject } from "vue";
import { useRoute } from "vue-router";
// Services
import { FunctionAccessLevels, DenyFunctionLevelAccess } from "@/config/AuthConfig";
import { MonetaryValueWithCommas } from "@/services/helpers/Helper";
import useCarPurchaseVars from "@/services/helpers/CarPurchases/CarPurchasesVariables";
import {
    ParseCarPurchaseCarSaleInvoices,
    FilterCarSaleInvoicesByContractIds,
} from "@/services/helpers/CarPurchases/CarPurchasesFunctions";
// Data
import { ICarPurchasesCarSaleInvoice } from "@/Interfaces/CarPurchases/ICarPurchasesCarSaleInvoice";
import { ISelection } from "@/Interfaces/ISelection";
import { CarPurchasesCarSaleInvoice } from "@/models/CarPurchases/CarPurchasesCarSaleInvoice";
import { ContractOwner } from "@/models/CarPurchases/ContractOwner";
// Components
import ErrorInfo from "@/components/ErrorInfo.vue";
import Table, {ITableExpose, KeyTypeKeys} from "@/components/TablePersistentChecking.vue";
import TickCrossBooleanDisplay from "@/components/TickCrossBooleanDisplay.vue";
import CarPurchasesPopup from '@/components/CarPurchases/CarPurchasesPopup.vue';
// Misc
import log from "loglevel";
import { AxiosResponse } from "axios";
import { apiLoggerKey } from "@/types/ServiceKeys";
import useNotifierWithErrFormatter from "@/services/composables/NotifierWithErrFormatter";
import DatePeriod from "@/types/DatePeriod";
import PeriodSelector from "@/components/PeriodSelector.vue";
import GroupedInputButton from "@/components/GroupedInputButton.vue";

const GettingCarPurchasesErrorInfo = ErrorInfo;

log.trace("CarPurchase");
const fileName = "CarPurchase.vue";
const Logger = inject(apiLoggerKey);
const contractIdSearchInputPlaceholder = "Enter comma-seraparated Contract IDs...";

const {
    market,
    carPurchasesDefaultSortCols,
    carPurchasesDefaultSortOrders,
    isLoading,
    carPurchasesServices,
    contractOwners,
    contractOwnersSelections,
    selectedContractOwner,
    fetchingContractOwners,
    showContractOwnersError,
} = useCarPurchaseVars();

//table
const infoError = ref();
const carSaleInvoiceCols: (keyof CarPurchasesCarSaleInvoice)[] = [
	"CONTRACT_ID",
	"INVOICE_ID",
	"COLLECTIVE_INVOICE_ID",
	"MARKET",
	"AMOUNT_TOTAL",
	"AMOUNT_EX_VAT",
	"AMOUNT_VAT",
	"ISSUE_DATE",
	"BALANCE",
	"BALANCE_DATE_UPDATED",
	"JOURNAL_STATUS",
	"APPROVED_FOR_PAYMENT",
];
const carSaleInvoiceLabels = [
	"Contract Id",
	"Invoice Id",
	"Collective Invoice Id",
	"Market",
	"Amount Total",
	"Amount Ex. VAT",
	"Amount VAT",
	"Issue Date",
	"Balance",
	"Balance Date Updated",
	"Journal Status",
	"Approved for Payment",
];
const colKey: KeyTypeKeys<CarPurchasesCarSaleInvoice> = 'INVOICE_ID';
const carPurchasePage = ref(1);
const carPurchaseRowCount = ref(40);
const carPurchasesCarSaleInvoice = ref<CarPurchasesCarSaleInvoice[]>();
const displayedCarSaleInvoices = computed(
    ()=>FilterCarSaleInvoicesByContractIds(contractIds.value,FilterByDateRangeSelection(carPurchasesCarSaleInvoice.value))
);

//inputs
const period = ref(new DatePeriod());
const popupSaleIds = ref<CarPurchasesCarSaleInvoice[]>();
const contractIds = ref<string>();

//component refs
const table = ref<ITableExpose<CarPurchasesCarSaleInvoice>>();

////////////////////////////////////////////////////////////////////////////
// LOGIC
////////////////////////////////////////////////////////////////////////////

// ROUTE
const route = useRoute();
market.value = route.params.market as string;

onMounted(async () => {
    document.title = `${route.name?.toString()} - Optio`;

    const tableProm = GetCarPurchase();
    await tableProm;
});
async function GetCarPurchase() {
    log.trace("GetCarPurchase()");

    const name = GetCarPurchase.name;
    const Class = fileName + "/" + name;
    const collectiveInvoiceId = route.params.id instanceof Array ? route.params.id[0] : route.params.id;
    var Message = `Getting Car Purchases: (collectiveInvoiceId: ${collectiveInvoiceId}, market: ${market.value})`;

    log.debug(`${name}()\n${Message}`);
    try {
        isLoading.value = true;
        carPurchasesCarSaleInvoice.value = undefined;
        const response: AxiosResponse<ICarPurchasesCarSaleInvoice[]> = await carPurchasesServices.GetCarSaleInvoice<ICarPurchasesCarSaleInvoice[]>(collectiveInvoiceId);
        carPurchasesCarSaleInvoice.value = response.data
            .map(ParseCarPurchaseCarSaleInvoices)
            .filter(carSaleInvoice =>
                (carSaleInvoice.BALANCE ?? 0) > 0
            );

        table.value?.ClearChecked();
        Logger?.LogInformation(Message, Class);
    } catch (error) {
        infoError.value = error;
        Message = `(Error) ${Message}: ${error}`;
        log.error(`${Message}`);
        Logger?.LogError(Message, Class);
    } finally {
        isLoading.value = false;
    }
}
/*
    Updates fetched data when dateRangeSelection changes.
    Triggered by dateRangeSelection.change.
*/
function FilterByDateRangeSelection(data?: CarPurchasesCarSaleInvoice[]) {
    return data?.filter(carPurchase => period.value.within(carPurchase.ISSUE_DATE));
}
function AssignCarPurchaseToOwner() {
    if (popupSaleIds.value) return;
    GetContractOwners();
    const checkedItems = table.value?.GetChecked();
    if (!checkedItems?.length) {
        useNotifierWithErrFormatter().notify({ msg: 'No invoice has been selected.' });
        return;
    }
    // Reset Selector
    selectedContractOwner.value = "";
    showContractOwnersError.value = false;
    popupSaleIds.value = checkedItems;
}
async function GetContractOwners() {
    const name = GetContractOwners.name;
    const prefix = name + "()\n";
    const Class = fileName + "/" + name;
    var Message = `Getting Contract Owners`;
    log.debug(`${prefix}${Message}`);

    try {
        infoError.value = undefined;
        fetchingContractOwners.value = true;
        const res = await carPurchasesServices.GetContractOwners<ContractOwner[]>();
        contractOwners.value = res.data;
        contractOwnersSelections.value = res.data.map((contractOwner: ContractOwner) => {
            return {
                text: contractOwner.FullName,
                val: contractOwner.OwnerId
            } as ISelection;
        });
    } catch (error) {
        showContractOwnersError.value = true;
        infoError.value = error;

        Message = `(Error) ${Message}: ${error}`;
        log.error(`${Message}`);
        Logger?.LogError(Message, Class);
    } finally {
        fetchingContractOwners.value = false;
    }
}
function submitContractIdFilter(e: Event) {
    const el = ((e as SubmitEvent).submitter as Element)
    contractIds.value = (el?.parentElement?.children[0] as HTMLInputElement)?.value;
    log.debug(submitContractIdFilter.name, e,contractIds.value)
}
</script>