import './Date';

/**
 * when input to constructor is a number assume that it is the UTC time offset
 * on the wanted local date, add offset to compensate for it
 * WHen input is a date, assumes the local time is the wanted date
 * Then, it sets time to 0:00am in local time when constructed
 * In the json reply, it ignores timezone and replies a timestamp that references local time
 * 1st May in local time is 1st May in UTC regardless of user system settings in toJSON and toString
 */
export default class DateIgnoreTime extends Date {
    constructor(isoDateString: string);
    constructor(localDate: Date);
    constructor(unixTime: number);
    constructor(n?: null);
    constructor(variable?: number|string|Date|null);
    constructor(year: number, monthIndex: number);
    constructor(input?: number|string|Date|null, in2?: number) {
        if (input) {
            if (typeof in2 === 'number') super(input as number, in2)
            else if (input instanceof Date) super(input);
            else if (typeof input === 'string') {
                const year = +input.slice(0,4);
                const month = +input.slice(5,7) - 1;
                const date = input.length > 7 ? +input.slice(8,10) : 1;
                super(year, month, date);
            }
            else super(input + new Date().getTimezoneOffset());
            this.zeroTime();
        } else super('');
    }
    override toString() {
        if (this.isValid()) {
            const year = `${this.getFullYear()}`.padStart(4,'0');
            const month = `${this.getMonth()+1}`.padStart(2,'0');
            const date = `${this.getDate()}`.padStart(2,'0');
            return `${year}-${month}-${date}`;
        }
        return '';
    }
    override toJSON(): string {
        if (this.isValid()) return this.toString();
        return null as unknown as string;
    }
}