<template>
    <div class="card-container">
        <Transition v-if="timer" appear mode="out-in" >
            <div v-if="s" class="card-wrapper" >
                <div class="card-header">Success:</div>
                <div>
                    <div v-if="title">{{ title }}<br></div>
                    {{ msg }}
                </div>
            </div>
            <div v-else class="card-wrapper" >
                <div class="card-header">Success:</div>
                <div>
                    <div v-if="title2">{{ title2 }}<br></div>
                    {{ msg2 }}
                </div>
            </div>
        </Transition>
    </div>
</template>
<script setup lang="ts">
import { time } from 'console';
import {ref} from 'vue';

export interface INotify {
    msg?: string;
    title?: string;
    dur?: number;
    fade?: number;
    callback?: ()=>void,
}

defineExpose({show});
const title = ref('');
const msg = ref('');
const title2 = ref('');
const msg2 = ref('');
const timer = ref<NodeJS.Timeout>();
const s = ref(false);

function sleep(dur: number) {
    return new Promise(resolve=>setTimeout(resolve, dur));
}

async function show(props: INotify) {
    clearTimeout(timer.value);
    if (s.value) {
        msg2.value = props.msg ?? '';
        title2.value = props.title ?? '';
    } else {
        msg.value = props.msg ?? '';
        title.value = props.title ?? '';
    }
    s.value = !s.value;
    timer.value = setTimeout(async()=>{
        timer.value = undefined;
        await sleep(props.fade ?? 500);
        if (!timer.value) {
            props.callback?.();
        }
    }, props.dur ?? 3000);
}
</script>
<style scoped>
.card-container {
    position: fixed;
    z-index: 9999;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
}
.card-wrapper {
    background-color: var(--primary-color-lighter);
    padding: 1em 4em;
    color: black;

    padding-left: 2rem; padding-right: 2rem;
    background-color: #c2dab8 !important;
    opacity: 0.95;
}
.card-header {
    display: inline-block;
    font-size: 1.5rem;
    padding-bottom: 0.3em;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>