import IServiceFees from "@/Interfaces/FeesConfiguration/IServiceFees";
import DateIgnoreTime from "@/types/DateIgnoreTime";
import DateTimeSv from "@/types/DateTimeSv";

export default class ServiceFees implements IServiceFees {
    Market!: string;
    DateValidFrom: DateIgnoreTime;
    OutsourcingFee!: number;
    RemarketingFee!: number;
    User?: string;
    DatetimeEntryInserted!: Date;
    DatetimeEntryUpdated?: Date;
    Timestamp: DateTimeSv;
    Updateable: boolean;

    constructor(raw: IServiceFees) {
        Object.assign(this, raw)
        this.DateValidFrom = new DateIgnoreTime(raw.DateValidFrom);
        this.DatetimeEntryInserted = new Date(raw.DatetimeEntryInserted);
        this.DatetimeEntryUpdated = raw.DatetimeEntryUpdated ? new Date(raw.DatetimeEntryUpdated) : undefined;
        this.Timestamp = new DateTimeSv(findLaterDate(this.DatetimeEntryInserted, this.DatetimeEntryUpdated));
        this.Updateable = new Date().diffDays(this.Timestamp)<=1;
    }
}
export function findLaterDate(inserted: Date, updated: Date | undefined): Date {
    const laterDate = updated ? (updated > inserted ? updated : inserted) : inserted;
    return laterDate;
}