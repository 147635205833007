import { IFileDetailsByType, IFileDetailsStatus, ILastReceivedFilesByType } from "@/Interfaces/DataProcessing/ILastReceivedFilesByType";
import DateTimeSv from "@/types/DateTimeSv";

export class LastReceivedFilesByType implements ILastReceivedFilesByType {
    CarSaleCreditNote?: FileDetailsByType;
    CarSaleInvoice?: FileDetailsByType;
    CarSalesInvoiceList?: FileDetailsByType;
    Churn?: FileDetailsByType;
    Contract?: FileDetailsByType;
    CreditNote?: FileDetailsByType;
    Customer?: FileDetailsByType;
    CustomerInvoice?: FileDetailsByType;
    DunningNotice?: FileDetailsByType;
    FinalSettlement?: FileDetailsByType;
    HireAgreement?: FileDetailsByType;
    PaymentAdvices?: FileDetailsByType;
    Payments?: FileDetailsByType;
    RV?: FileDetailsByType;
    RVGF?: FileDetailsByType;
    RechargeInvoice?: FileDetailsByType;
    RemarketingInvoice?: FileDetailsByType;
    ServiceInvoice?: FileDetailsByType;
    Vehicle?: FileDetailsByType;

    constructor(lastReceivedFilesByType: ILastReceivedFilesByType) {
        this.CarSaleCreditNote = lastReceivedFilesByType.CarSaleCreditNote
            ? new FileDetailsByType(lastReceivedFilesByType.CarSaleCreditNote)
            : undefined;
        this.CarSaleInvoice = lastReceivedFilesByType.CarSaleInvoice
            ? new FileDetailsByType(lastReceivedFilesByType.CarSaleInvoice)
            : undefined;
        this.CarSalesInvoiceList = lastReceivedFilesByType.CarSalesInvoiceList
            ? new FileDetailsByType(lastReceivedFilesByType.CarSalesInvoiceList)
            : undefined;
        this.Churn = lastReceivedFilesByType.Churn
            ? new FileDetailsByType(lastReceivedFilesByType.Churn)
            : undefined;
        this.Contract = lastReceivedFilesByType.Contract
            ? new FileDetailsByType(lastReceivedFilesByType.Contract)
            : undefined;
        this.CreditNote = lastReceivedFilesByType.CreditNote
            ? new FileDetailsByType(lastReceivedFilesByType.CreditNote)
            : undefined;
        this.Customer = lastReceivedFilesByType.Customer
            ? new FileDetailsByType(lastReceivedFilesByType.Customer)
            : undefined;
        this.CustomerInvoice = lastReceivedFilesByType.CustomerInvoice
            ? new FileDetailsByType(lastReceivedFilesByType.CustomerInvoice)
            : undefined;
        this.DunningNotice = lastReceivedFilesByType.DunningNotice
            ? new FileDetailsByType(lastReceivedFilesByType.DunningNotice)
            : undefined;
        this.FinalSettlement = lastReceivedFilesByType.FinalSettlement
            ? new FileDetailsByType(lastReceivedFilesByType.FinalSettlement)
            : undefined;
        this.HireAgreement = lastReceivedFilesByType.HireAgreement
            ? new FileDetailsByType(lastReceivedFilesByType.HireAgreement)
            : undefined;
        this.PaymentAdvices = lastReceivedFilesByType.PaymentAdvices
            ? new FileDetailsByType(lastReceivedFilesByType.PaymentAdvices)
            : undefined;
        this.Payments = lastReceivedFilesByType.Payments
            ? new FileDetailsByType(lastReceivedFilesByType.Payments)
            : undefined;
        this.RV = lastReceivedFilesByType.RV
            ? new FileDetailsByType(lastReceivedFilesByType.RV)
            : undefined;
        this.RVGF = lastReceivedFilesByType.RVGF
            ? new FileDetailsByType(lastReceivedFilesByType.RVGF)
            : undefined;
        this.RechargeInvoice = lastReceivedFilesByType.RechargeInvoice
            ? new FileDetailsByType(lastReceivedFilesByType.RechargeInvoice)
            : undefined;
        this.RemarketingInvoice = lastReceivedFilesByType.RemarketingInvoice
            ? new FileDetailsByType(lastReceivedFilesByType.RemarketingInvoice)
            : undefined;
        this.ServiceInvoice = lastReceivedFilesByType.ServiceInvoice
            ? new FileDetailsByType(lastReceivedFilesByType.ServiceInvoice)
            : undefined;
        this.Vehicle = lastReceivedFilesByType.Vehicle
            ? new FileDetailsByType(lastReceivedFilesByType.Vehicle)
            : undefined;
    }

    GetData(): LastReceivedFilesByTypeDataItem[] {
        const dataItems: LastReceivedFilesByTypeDataItem[] = [];

        Object.entries(this).forEach(([key, value]: [string, FileDetailsByType]) => {
            if (value !== undefined) {
                const dataItem: LastReceivedFilesByTypeDataItem = {
                    FileType: key,
                    WaitingName: value.Waiting?.Name || undefined,
                    WaitingCreatedOn: new DateTimeSv(value.Waiting?.CreatedOn ?? ''),
                    ProcessedName: value.Processed?.Name || undefined,
                    ProcessedCreatedOn: new DateTimeSv(value.Processed?.CreatedOn ?? ''),
                    FailedName: value.Failed?.Name || undefined,
                    FailedCreatedOn: new DateTimeSv(value.Failed?.CreatedOn ?? ''),
                };

                dataItems.push(dataItem);
            } else {
                const dataItem: LastReceivedFilesByTypeDataItem = {
                    FileType: key,
                    WaitingName: undefined,
                    WaitingCreatedOn: undefined,
                    ProcessedName: undefined,
                    ProcessedCreatedOn: undefined,
                    FailedName: undefined,
                    FailedCreatedOn: undefined,
                };

                dataItems.push(dataItem);
            }
        });

        return dataItems;
    }

    GetProcessedNames(): string[] {
        return Object.values(this)
            .map((fileDetailsByType: FileDetailsByType) => {
                if (fileDetailsByType && fileDetailsByType.Processed) {
                    return fileDetailsByType.Processed.Name;
                }
                return "";
            });
    }
    GetProcessedCreatedOns(): (Date | null)[] {
        return Object.values(this)
            .map((fileDetailsByType: FileDetailsByType) => {
                if (fileDetailsByType && fileDetailsByType.Processed) {
                    return fileDetailsByType.Processed.CreatedOn;
                }
                return null;
            });
    }

    GetFailedNames(): string[] {
        return Object.values(this)
            .map((fileDetailsByType: FileDetailsByType) => {
                if (fileDetailsByType && fileDetailsByType.Failed) {
                    return fileDetailsByType.Failed.Name;
                }
                return "";
            });
    }
    GetFailedCreatedOns(): (Date | null)[] {
        return Object.values(this)
            .map((fileDetailsByType: FileDetailsByType) => {
                if (fileDetailsByType && fileDetailsByType.Failed) {
                    return fileDetailsByType.Failed.CreatedOn;
                }
                return null;
            });
    }

    GetWaitingNames(): string[] {
        return Object.values(this)
            .map((fileDetailsByType: FileDetailsByType) => {
                if (fileDetailsByType && fileDetailsByType.Waiting) {
                    return fileDetailsByType.Waiting.Name;
                }
                return "";
            });
    }
    GetWaitingCreatedOns(): (Date | null)[] {
        return Object.values(this)
            .map((fileDetailsByType: FileDetailsByType) => {
                if (fileDetailsByType && fileDetailsByType.Waiting) {
                    return fileDetailsByType.Waiting.CreatedOn;
                }
                return null;
            });
    }
}

export class LastReceivedFilesByTypeDataItem {
    FileType?: string;
    WaitingName?: string;
    WaitingCreatedOn?: Date;
    ProcessedName?: string;
    ProcessedCreatedOn?: Date;
    FailedName?: string;
    FailedCreatedOn?: Date;
}

export class FileDetailsByType implements IFileDetailsByType {
    Processed?: FileDetailsStatus;
    Failed?: FileDetailsStatus;
    Waiting?: FileDetailsStatus;

    constructor(fileDetailsByType: IFileDetailsByType) {
        this.Processed = fileDetailsByType.Processed
            ? new FileDetailsStatus(fileDetailsByType.Processed)
            : undefined;
        this.Failed = fileDetailsByType.Failed
            ? new FileDetailsStatus(fileDetailsByType.Failed)
            : undefined;
        this.Waiting = fileDetailsByType.Waiting
            ? new FileDetailsStatus(fileDetailsByType.Waiting)
            : undefined;
    }
}

export class FileDetailsStatus implements IFileDetailsStatus {
    Name: string;
    CreatedOn: Date;

    constructor(fileDetailsStatus: IFileDetailsStatus) {
        this.Name = fileDetailsStatus.Name;
        this.CreatedOn = new DateTimeSv(fileDetailsStatus.CreatedOn ?? '');
    }
}

// export enum StorageContainerFolders
// {
//     CarSaleCreditNote,
//     CarSaleInvoice,
//     CarSalesInvoiceList,
//     Churn,
//     Contract,
//     CreditNote,
//     Customer,
//     CustomerInvoice,
//     FinalSettlement,
//     // HireAgreement,
//     // PaymentAdvices,
//     Payments,
//     RV,
//     RVGF,
//     RechargeInvoice,
//     ServiceInvoice,
//     Vehicle
// }

export enum SFTPFileStatuses {
    Processed,
    Failed,
    Waiting
}