import { createApp } from 'vue';
import NotificationPopups, {INotify} from '@/components/NotificationPopups.vue'
import NotificationType from '@/types/NotificationType';

export class Notifier {
    private static notificationPopups?: InstanceType<typeof NotificationPopups>;
    static async notify(props: INotify) {
        if (!Notifier.notificationPopups) {
            const elId = 'notificaiton-group';
            const popupGroupDiv = document.getElementById(elId) ?? (()=>{
                const tempDiv =  document.createElement('div');
                document.body.appendChild(tempDiv);
                return tempDiv;
            })();
            popupGroupDiv.id = elId;
            const app = createApp(NotificationPopups);
            Notifier.notificationPopups = app.mount(popupGroupDiv) as InstanceType<typeof NotificationPopups>;
        }
        return await Notifier.notificationPopups.add(props);
    }
    static async success(props: INotify) {
        props.type = NotificationType.SUCCESS;
        return await useNotifier().notify(props);
    }
    static async warn(props: INotify) {
        props.type = NotificationType.WARN;
        return await useNotifier().notify(props);
    }
    static async error(props: INotify) {
        props.type = NotificationType.ERR;
        return await useNotifier().notify(props);
    }
}
export default function useNotifier() {
    return Notifier;
}