<script setup lang="ts" generic="T extends Record<string|number|symbol, string>">
import { computed, ref, onMounted, onUnmounted } from 'vue';
import RadioButton from './RadioButton.vue';

/**
 * T is an enum type
 */
const props = withDefaults(defineProps<{
    name: string;
    modelValue: keyof T;
    options: T;
}>(), {});
const emits = defineEmits<{
    (e:'update:modelValue', val: keyof T);
    (e:'change');
}>()
const choice = computed({
    get() {
        return props.modelValue.toString();
    },
    set(val) {
        emits('update:modelValue', val);
    }
})
const keys = computed(()=>Object.keys(props.options));
const main = ref<HTMLElement>();
const dotDisplayed = ref<HTMLElement>();
const anchor = ref(`--${keys.value[0]}`);
const dotX = ref('');
const dotY = ref('');
function updateAnchor(id: string) {
    anchor.value = `--${id}`;
}
function updateDotPos(el?: HTMLElement) {
    dotDisplayed.value = el;
    const b = dotDisplayed.value?.getBoundingClientRect();
    const a = main.value?.getBoundingClientRect();
    if (a && b) {
        dotX.value = `${b.left-a.left}px`;
        dotY.value = `${b.top-a.top}px`;
    }
}
function updateCurDotPos() {
    updateDotPos(dotDisplayed.value);
}
onMounted(()=>window.addEventListener('resize',updateCurDotPos));
onUnmounted(()=>window.removeEventListener('resize',updateCurDotPos));
</script>

<template>
    <ul ref=main class="radio-group">
        <div v-if=dotDisplayed class="dot"></div>
        <li class="entry" v-for="k of keys" :key=k>
            <RadioButton :id="`${name}-${k}`"
                v-model=choice
                :name=name
                :value=k
                :label=options[k]
                hideCheck
                @change="(el,val)=>{updateDotPos(el);updateAnchor(val);emits('change');}"
            />
        </li>
    </ul>
</template>

<style lang="scss" scoped>
.radio-group {
    all: unset;
    position: relative;
    display: flex;
    >.entry {
        display: flex;
        flex-direction: column;
        &:not(:first-of-type) {
            :deep(.radio-btn-wrapper::before) {
                content: "";
                height: 0.1rem;
                flex: 1 1 0;
                background: var(--primary-color);
            }
            :deep(.radio-btn-wrapper::after) {
                content: "";
                height: 0.1rem;
                flex: 1 1 0;
            }
        }
        &:not(:last-of-type) {
            :deep(.radio-btn-wrapper::before) {
                content: "";
                height: 0.1rem;
                flex: 1 1 0;
            }
            :deep(.radio-btn-wrapper::after) {
                content: "";
                height: 0.1rem;
                flex: 1 1 0;
                background: var(--primary-color);
            }
        }
    }
    >.dot {
        position: absolute;
        left: v-bind(dotX);
        top: v-bind(dotY);
        @supports(anchor-name: v-bind(anchor)) {
            left: 0;
            top: 0;
            anchor-default: v-bind(anchor);
        }

        height: 0.9ch;
        width: 0.9ch;
        background: var(--primary-color);
        border-radius: 50%;

        transition: all 0.15s ease-in-out;
    }
}
</style>
