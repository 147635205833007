import axios, { AxiosResponse } from "axios";
import INetworkService from "./INetwork";
import BaseNetworkService from "./BaseNetworkService";

export default class NetworkService<R, G = undefined, Q = G, D = Q, RD=R>
  extends BaseNetworkService<G, Q>
  implements INetworkService<R, G, Q, D, RD>
{
  get(query?: Q|null): Promise<AxiosResponse<R>>
  get(param: G|null, query: Q|null): Promise<AxiosResponse<R>>;
  async get(params?: G|Q|null, query?: Q|null): Promise<AxiosResponse<R>> {
    function isParam(params?: G|Q|null, query?: Q|null): params is G {
      if ((query!==undefined) && params) return true;
      return false;
    }
    const paramPresent = isParam(params, query);
    const header = await this.getHeader();
    const queryStr = this.getQueryString(paramPresent ? query : params);
    const paramURI = paramPresent ? this.getUri(params) : this.getUri();
    const url = this.getBaseURL() + paramURI + queryStr;
    return await axios.get(url, { headers: header });
  }
  put(data: D): Promise<AxiosResponse<RD, D>>;
  put(params: G | null, data: D): Promise<AxiosResponse<RD, D>>;
  async put(params: G | D | null, data?: D): Promise<AxiosResponse<RD, D>> {
    function isParam(params: G|D|null, data?: D): params is G {
      if (data!==undefined && params) return true;
      return false;
    }
    const paramPresent = isParam(params, data);
    const paramURI = paramPresent ? this.getUri(params) : this.getUri();
    const url = this.getBaseURL() + paramURI;
    const header = await this.getHeader();
    data = paramPresent ? data : params as D;
    return await axios.put(url, data, { headers: header });
  }
  post(data: D): Promise<AxiosResponse<RD, D>>;
  post(params: G | null, data: D): Promise<AxiosResponse<RD, D>>;
  async post(params: G|D|null, data?: D): Promise<AxiosResponse<RD, D>> {
    function isParam(params: G|D|null, data?: D): params is G {
      if (data!==undefined && params) return true;
      return false;
    }
    const paramPresent = isParam(params, data);
    const paramURI = paramPresent ? this.getUri(params) : this.getUri();
    const url = this.getBaseURL() + paramURI;
    const header = await this.getHeader();
    data = paramPresent ? data : params as D;
    return await axios.post(url, data, { headers: header });
  }
  async delete(payload?: G | D): Promise<AxiosResponse<RD, D>> {
    const url = this.getBaseURL() + this.uri;
    const header = await this.getHeader();
    return await axios.delete(url, { data: payload, headers: header });
  }
}