import { AxiosResponse } from "axios";
import { financialSystemNetworkService, GetByteArrayFromBase64String } from "@/services/helpers/Helper";
import log from "loglevel";
import { Log2API } from "@/services/Logger";
import IJournalReportItem from "@/Interfaces/Journaling/IJournalingReportItem";
import IBackoutJournalBody from "@/Interfaces/Journaling/IBackoutJournalBody";
import DateIgnoreTime from "@/types/DateIgnoreTime";

export class JournalingReportService {
	Logger = new Log2API();

	public ParseItems(item: IJournalReportItem) {
		log.trace("ParseItems()");
		// Convert IssueDate string to Date object
		item.IssueDateDate = new DateIgnoreTime(item.IssueDate);
		return item;
	}

	public async GetJournalingReport(
		jobId: string,
		journalType: string
	): Promise<IJournalReportItem[]> {
		log.trace(`GetJournalingReport()\njobId: ${jobId}\njournalType: ${journalType}`);
		
		// {{url}}/journaling-reports/{{jobId}}?type={{journalType}}
		const urlParams: string = "/" + jobId + "?type=" + journalType + "&";
		log.debug("GetJournalingReport()\nurlParams", urlParams);
		
		return new Promise((resolve, reject) => {
			financialSystemNetworkService
				.get(process.env.VUE_APP_JOURNALING_REPORTS_URL_PARAMS! + urlParams)
				.then((res: AxiosResponse<IJournalReportItem[]>) => {
					log.debug(`GetData()\nres.data: ${res.data}`);
					resolve(res.data);
				}).catch((error: any) => {
					reject(error);
				});
		});
	}

	public async GetJournalingReportPdfUrl(id: string): Promise<string> {
		log.trace("GetJournalingReportPdfUrl()\nid:", id);
				
		// {{url}}/journaling-reports/files/{{id}}
		const urlParams = process.env.VUE_APP_JOURNALING_REPORTS_URL_PARAMS! + "/files/" + id;
		const url: string = await GetByteArrayFromBase64String(urlParams)
			.then((byteArray) => {
				const file: Blob = new Blob([byteArray], { type: "application/pdf" });
				const url = URL.createObjectURL(file);
				log.debug("GetJournalingReportPdfUrl()\nFile url", url);
				return url;
			})
			.catch((error: any) => {
				throw error;
			});

		return url;
	}

	public async PerformBackoutJournal(journalReportItem: IJournalReportItem, jobId: string, journalType: string): Promise<AxiosResponse> {
        log.trace(`BackoutJournal()\njournalReference: ${journalReportItem.JournalReference}`);
		
		// {{url}}/journaling/backout
		const urlParams: string = process.env.VUE_APP_JOURNALING_BACKOUT_URL_PARAMS! + "?";

		const body: IBackoutJournalBody = {
			JobId: jobId,
			InstanceNumber: journalReportItem.InstanceNumber,
			JournalType: journalType,
			IssueDate: journalReportItem.IssueDate.toLocaleString().replace("/", "-").split(", ").shift()?? "",
		}
     
		return new Promise((resolve, reject) => {
			financialSystemNetworkService
				.post(body, urlParams)
				.then((res) => {
					log.debug("BackoutJournal() res.data", res.data);
					resolve(res);
				})
				.catch((error: any) => {
					reject(error);
				});
		})
	}
}
