<template>
    <div class="AlertsAndWarnings">
        <div class="content-container">
            <div v-if="showPopup" @click="showPopup = false" title="Click to close detailed description." class="overlay">
                <div @click.stop class="popup">
                    <div class="description-title">Detailed Description:</div>
                    <div class="wrap" v-html="detailedDescription"></div>
                    <div>
                        <button @click="showPopup = false"
                            title="Close detailed description. (You can also click outside the popup box to close detailed description.)">Close</button>
                    </div>
                </div>
            </div>
            <div class="top-content">
                <div class="top-grid">
                    <MarketSelector @change=updateSelection />
                    <button class="centre-button" @click=ManageAlertsAndWarnings
                        :disabled="DenyFunctionLevelAccess(FunctionAccessLevels.ManageAlertsAndWarnings)"
                        :title="DenyFunctionLevelAccess(FunctionAccessLevels.ManageAlertsAndWarnings) ? accessDeniedManageAnWHoverText : manageAnWHoverText">
                        Manage Alerts & Warnings
                    </button>
                    <span>Displayed Alerts and Warnings: {{ alertsAndWarnings?.length }}</span>
                    <div class="flex-col-centered">
                        <div>Status Visibility:</div>
                        <div class="flex-row-centered">
                            <span title="Number of assigned issues">
                                Assigned ({{
                                    alertsAndWarnings?.filter((issue) => {
                                        return (
                                            issue.handler?.real_name?.length
                                            && !issue.status.name.toLowerCase().startsWith('closed')
                                        );
                                    }).length
                                }})
                            </span>
                            <input type="checkbox" style="margin: 0" v-model="showAssignedTickets"
                                @change="filterAlertsAndWarnings()"
                                :title="`Toggle visibility for assigned issues to ${!showAssignedTickets ? 'show' : 'hide'}`" />,
                            <span title="Number of unassigned issues">
                                Unassigned (<span :class="alertsAndWarnings?.filter((issue) => {
                                    return (
                                        !issue.handler?.real_name?.length
                                        && !issue.status.name.toLowerCase().startsWith('closed')
                                    );
                                }).length! > 0 ? 'failure' : ''">
                                    {{ alertsAndWarnings?.filter((issue) => {
                                        return (
                                            !issue.handler?.real_name?.length &&
                                            !issue.status.name
                                                .toLowerCase()
                                                .startsWith("closed")
                                        );
                                    }).length
                                    }}
                                </span>)
                            </span>
                            <input type="checkbox" style="margin: 0" v-model="showUnassignedTickets"
                                @change="filterAlertsAndWarnings()"
                                :title="`Toggle visibility for unassigned issues to ${!showUnassignedTickets ? 'show' : 'hide'}`" />
                        </div>
                    </div>
                </div>
            </div>
            <Table :Rows=displayedAlertsAndWarnings :Cols=cols :Labels=labels
                :HasError=!!infoError
                v-model:RowsPerPage=rowCount
                v-model:Page=page
                :DefaultSortCols=defaultSortCols
                :DefaultSortOrders=defaultSortOrders >
                <template #description="props">
                    <div v-if="props.value.description.length > 30"
                        style="display: flex; justify-content: space-between; align-items: center; column-gap: 0.3rem; min-width: 100%;">
                        <span style="word-break: break-all;">{{ props.value.description.slice(0, 30) }}...</span>
                        <button class="table-button"
                            @click="ViewDetailedDescription(props.value.description)" title="View full description">
                            show
                        </button>
                    </div>
                    <div v-else>
                        {{ props.value.description }}
                    </div>
                </template>
                <template #GetSeverity="props">
                    <div v-if="props.value.severity.name === 'crash'" class="failure">
                        {{ props.value.severity.name }}
                    </div>
                    <div v-else-if="props.value.severity.name === 'major'" class="medium">
                        {{ props.value.severity.name }}
                    </div>
                    <div v-else>
                        {{ props.value.severity.name }}
                    </div>
                </template>
                <template #GetPriority="props">
                    <div v-if="props.value.priority.name === 'high'" class="failure">
                        {{ props.value.priority.name }}
                    </div>
                    <div v-else-if="props.value.priority.name === 'normal'" class="medium">
                        {{ props.value.priority.name }}
                    </div>
                    <div v-else>
                        {{ props.value.priority.name }}
                    </div>
                </template>
            </Table>
            <div class="anw-info-textbox">
                Please note, not all alerts and warnings are displayed in this portal.
                To view all alerts and warnings, please visit MantisHub&nbsp;
                <span
                    :title="DenyFunctionLevelAccess(FunctionAccessLevels.ManageAlertsAndWarnings) ? accessDeniedManageAnWHoverText : manageAnWHoverText"
                    @click="ManageAlertsAndWarnings()"
                    :class="DenyFunctionLevelAccess(FunctionAccessLevels.ManageAlertsAndWarnings) ? 'text-link-denied' : 'text-link'">here</span>.
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, inject } from "vue";
// Services
import log from "loglevel";
import { apiLoggerKey } from "@/types/ServiceKeys";
import { FunctionAccessLevels, DenyFunctionLevelAccess } from "@/config/AuthConfig";
import useNotifierWithErrFormatter from "@/services/composables/NotifierWithErrFormatter";
import { mantisIssues } from "@/services/network";
// Table
import Table from "@/components/TablePersistentChecking.vue";
// Misc
import { MantisIssue } from "@/models/MantisIssue";
import MarketSelector from "@/components/MarketSelector.vue";

const mantisUrl = process.env.VUE_APP_MANTIS_URL;

const manageAnWHoverText = "Manage Alerts and Warnings in MantisHub (3rd party resource opens in new tab)";
const accessDeniedManageAnWHoverText = "Manage Alerts and Warnings in MantisHub (Access denied)";
const cols: (keyof MantisIssue)[] = [
    "id",
    "summary",
    "description",
    "GetCategory", // "category",
    "GetSeverity", // "severity",
    "GetPriority", // "priority",
    "GetStatus", // "status",
    "GetModule", // "module",
    "GetCorrelationId", // "correlation_id",
    "GetVolvoTicketID",
    "GetAssignedTo", // "handler",
    "GetCreatedAt", // "created_at",
];
const labels = [
    "ID",
    "Issue Summary",
    "Detailed Description",
    "Category",
    "Severity",
    "Priority",
    "Status",
    "Module",
    "Correlation Id",
    "Volvo Ticket ID",
    "Assigned To",
    "Created At",
];

const page = ref(1);
const rowCount = ref(40);
const sortCol: keyof MantisIssue = "GetCreatedAt";
const defaultSortCols = [cols.findIndex(col=>col === sortCol)];
const defaultSortOrders: (-1)[] = [-1];

log.debug("Alerts and Warnings");
const Logger = inject(apiLoggerKey);

////////////////////////////////////////////////////////////////////////////
// LOGIC
////////////////////////////////////////////////////////////////////////////
onMounted(async () => {
    log.trace("onMounted() AlertsAndWarnings");

    document.title = `Alerts and Warnings - Optio`;

    await GetMantisIssues();
});
async function GetMantisIssues() {
    const name = __filename + "/" + GetMantisIssues.name;
    try {
        const data = (await mantisIssues.get()).data;
        alertsAndWarnings.value = data.issues.map(x=>new MantisIssue(x));
        displayedAlertsAndWarnings.value = data.issues;

        filterAlertsAndWarnings();
        sortAlertsAndWarnings();

        Logger?.LogInformation('Obtained Alerts and Warnings from API', name);
    } catch (error) {
        infoError.value = error;
        useNotifierWithErrFormatter().error({
            name,
            errorType: 'Getting Alerts and Warnings',
            error
        })
    }
}

// ERRORS
const infoError = ref();

// TABLE
const alertsAndWarnings = ref<MantisIssue[]>();
const displayedAlertsAndWarnings = ref<MantisIssue[]>();

// MISC
const showClosedTickets = ref(false);
const showAssignedTickets = ref(true);
const showUnassignedTickets = ref(true);

async function updateSelection() {
    log.trace("updateSelection()");
    // Fetch Data
    await GetMantisIssues();
}
function filterAlertsAndWarnings() {
    log.trace(`filterAlertsAndWarnings()\nshowClosedTickets: ${showClosedTickets.value}`);
    log.debug(`filterAlertsAndWarnings()\nshowClosedTickets: ${showClosedTickets.value}`);

    if (!showClosedTickets.value) {
        log.debug(`filterAlertsAndWarnings()\nRemove closed tickets`);
    }
    if (!showAssignedTickets.value) {
        log.debug(`filterAlertsAndWarnings()\nRemove assigned tickets`);
    }
    if (!showUnassignedTickets.value) {
        log.debug(`filterAlertsAndWarnings()\nRemove unassigned tickets`);
    }

    const filteredClosedTickets = showClosedTickets.value
        ? alertsAndWarnings.value
        : alertsAndWarnings.value?.filter(
            (issue: MantisIssue) =>
                !issue.status.name.toLowerCase().startsWith("closed")
        );

    const filteredAssignedTickets = showAssignedTickets.value
        ? filteredClosedTickets
        : filteredClosedTickets?.filter(
            (issue: MantisIssue) =>
                !issue.handler?.real_name?.length
        );

    const filteredUnassignedTickets = showUnassignedTickets.value
        ? filteredAssignedTickets
        : filteredAssignedTickets?.filter((issue: MantisIssue) =>
            Boolean(issue.handler?.real_name)
        );

    displayedAlertsAndWarnings.value = filteredUnassignedTickets;
    sortAlertsAndWarnings();
}
function sortAlertsAndWarnings() {
    log.trace("sortAlertsAndWarnings()");

    displayedAlertsAndWarnings.value = displayedAlertsAndWarnings.value?.sort(
        (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    );
}
function ManageAlertsAndWarnings() {
    log.trace("ManageAlertsAndWarnings()");

    if (!DenyFunctionLevelAccess(FunctionAccessLevels.ManageAlertsAndWarnings)) {
        log.debug("ManageAlertsAndWarnings()\nAccess granted");
        // Open url in new tab
        window.open(mantisUrl, "_blank");
    } else {
        log.debug("ManageAlertsAndWarnings()\nAccess denied");
    }
}
const showPopup = ref(false);
const detailedDescription = ref<string>();
function ViewDetailedDescription(description?: string) {
    log.debug("ViewDetailedDescription()\nDescription:", description);
    const regex = /\\n|\\r\\n|\\n\\r|\\r/g;
    detailedDescription.value = description
        ?.replaceAll("\\\\", "\\")
        .replace(regex, "<br>");
    showPopup.value = true;
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/centre-button.scss";
.top-grid {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-evenly;
    justify-items: center;
    row-gap: 1lh;
}
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.popup {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    text-align: center;
    max-width: 75%;
    max-height: 75%;
    overflow-y: auto;
}
.popup button {
    margin-top: 1rem;
    border-radius: var(--table-button-radius);
    border-width: 1px;
    border-color: blue;
    color: blue;
}
.popup button:hover {
    cursor: pointer;
    background-color: var(--primary-color);
    color: white;
}
.description-title {
    font-size: 1.3rem;
    padding-bottom: 1rem;
}
.wrap {
    white-space: pre-line;
}
.anw-info-textbox {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}
.text-link {
    color: blue;
    text-decoration: underline;
}
.text-link:hover {
    cursor: pointer;
}
.text-link-denied {
    color: blue;
    text-decoration: underline;
}
.text-link-denied:hover {
    cursor: not-allowed;
}
</style>