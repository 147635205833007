<template>
    <div class="top-content">
        <form class="form-container selection-form" @submit.prevent=Submit>
            <div>
                <div class="flex-col-centered selection">
                    <label for="reportType"><h3 class="header">Report Type:</h3></label>
                    <select v-model="selectedReportType" class="dropdown">
                        <option v-for="reportType in Object.entries(FundingReportOptions)"
                            :value="reportType[1]" :key="reportType[0]">
                            {{ reportType[1] }}
                        </option>
                    </select>
                </div>
            </div>
            <div>
                <MarketSelector v-if="selectedReportType == FundingReportOptions.DATA_TAPE || selectedReportType == FundingReportOptions.UTIL_REPORT_DATA" />
                <div v-if="selectedReportType == FundingReportOptions.UTIL_REPORT_DATA" class="flex-col-centered selection">
                <label for="contract-id"><h3 class="header">List of Contract IDs:</h3></label>
                <input v-model=contractIDs
                    id="contact-id"
                    type="text"
                    placeholder="Enter Contract ID(s)..."
                />
                </div>
                <div v-else class="flex-col-centered selection">
                    <label for="owner"><h3 class="header">Contract Owner Selection:</h3></label>
                    <select v-model="selectedContractOwner" class="dropdown" required>
                        <option v-for="contractOwner in contractOwners"
                            :value="contractOwner.OwnerId" 
                            :key="contractOwner.OwnerId" >
                            {{ contractOwner.FullName }}
                        </option>
                    </select>
                </div>
                <PeriodSelector v-model=selectedPeriod :defaultRefDate="startPeriod" showTitle />
            </div>
            <div>
                <button
                    type="submit"
                    class="default-btn"
                    :title="DownloadFundingReportsExportDisabled
                                ? 'Select Market, Contract Owner and Period first.'
                                : `Confirm funding report export (Market: '${marketSelection}', Contract Owner: '${selectedContractOwner}' Period: '${selectedPeriod})`"
                    :disabled=DownloadFundingReportsExportDisabled
                >
                    Confirm Export
                </button>
            </div>
        </form>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, inject, computed, ComputedRef } from "vue";
// Services
import { IAPILogger } from "@/interfaces/IAPILogger";
import { financialSystemNetworkService } from "@/services/helpers/Helper";
import useSpinnerPopup from "@/services/composables/SpinnerPopup";
import { marketSelection } from "@/services/helpers/Helper";
// Data
import { ContractOwner } from "@/models/CarPurchases/ContractOwner";
import IFileResponse from "@/Interfaces/IFileResponse";
import IGetDataTapeRequest from '@/Interfaces/FundingReports/IGetDataTapeRequest';
// Components
import MarketSelector from '@/components/MarketSelector.vue';
// Misc
import log from "loglevel";
import { AxiosResponse } from "axios";
import { Buffer } from "buffer";
import FundingReportOptions from "@/models/enums/FundingReportOptions";
import useNotifierWithErrFormatter from "@/services/composables/NotifierWithErrFormatter";
import PeriodSelector from "@/components/PeriodSelector.vue";
import DatePeriod from "@/types/DatePeriod";
import { euSecuritisationReport } from '@/services/network';

log.trace("FundingReportsExport");
const fileName = "FundingReportsExport.vue";
const Logger: IAPILogger = inject("Logger")!;

//Error Handling
const downloadOwnerErrorDesc = "Getting Contract Owners"
const downloadErrorDesc = "Downloading Funding Report Export";

const contractOwners = ref<ContractOwner[]>([]);
const selectedContractOwner = ref("");
const contractIDs = ref<string>("");
const dateRange = ref<Date[]>();
const selectedPeriod = ref<DatePeriod | null>(null);
const startPeriod = ref(new DatePeriod(new Date(2023, 10 ))); 
const selectedReportType = ref(FundingReportOptions.DATA_TAPE);
const fileResponse = ref<IFileResponse>();
const showingSuccNotifier = ref(false);

onMounted(async () => {
    log.trace("onMounted() Funding Reports Export");
    document.title = `Funding Reports Export - Optio`;
    await GetContractOwners();
});

function Reset() {
    dateRange.value = undefined;
}
async function GetContractOwners() {
    log.trace("GetContractOwners()");

    const name = GetContractOwners.name;
    const prefix = name + "()\n";
    const Class = fileName + "/" + name;

    var Message = `Getting Contract Owners`;

    log.debug(`${prefix}${Message}`);

    const urlParams: string = `${process.env.VUE_APP_CONTRACT_OWNERS_URL_PARAMS!}`;
    const close = useSpinnerPopup().show();
    try {
        const res: AxiosResponse<ContractOwner[]> = await financialSystemNetworkService.get(urlParams);
        contractOwners.value = res.data;
        selectedContractOwner.value = contractOwners.value[0].OwnerId ?? "";
    } catch (error) {
        useNotifierWithErrFormatter().error({
            errorType: downloadOwnerErrorDesc,
            error,
        });
    } finally {
        close();
    }
}
async function Submit() {
    try {
        const reportType = selectedReportType.value;
        const market = marketSelection.value;
        const period = selectedPeriod.value;
        const owner = selectedContractOwner.value;
        const contractIds = contractIDs.value;

        if (!period || !period.isValid()) {
            throw new Error(`'Invalid period selection: ${selectedPeriod.value}'`);
        }
        switch (reportType) {
            case FundingReportOptions.DATA_TAPE:
                SubmitDataTape(market, period, owner);
                break;
            case FundingReportOptions.UTIL_REPORT_DATA:
                SubmitUtilReportData(market, period, contractIds);
                break;
            case FundingReportOptions.ANNEX5:
                SubmitAnnex5(period, owner);
                break;
            case FundingReportOptions.ANNEX12:
                SubmitAnnex12(period, owner);
                break;
            default:
                throw new Error(`Invalid Funding Report Export Type received: ${reportType}`);
        }
    } catch (error) {
        useNotifierWithErrFormatter().error({
            errorType: 'Invalid data input',
            error
        });
    }
}
async function SubmitDataTape(market: string, period: DatePeriod, owner: string) {
    const name = SubmitDataTape.name;
    const Message = `Submitting Data Tape export form: ` +
        `Market: ${market}` +
        `Contract owner: ${owner}` +
        `Period: ${period}`;
    const Class = fileName + "/" + name;
    
    Logger.LogInformation(Message, Class);
    await DownloadDataTape(period, market, owner);
}
async function SubmitUtilReportData(market: string, period: DatePeriod, contractIDs: string) {
    const name = SubmitUtilReportData.name;
    const Message = `Submitting Util Report Data export form: ` +
        `Market: ${market}` +
        `Contract IDs: ${contractIDs}` +
        `Period: ${period}`;
    const Class = fileName + "/" + name;
    
    Logger.LogInformation(Message, Class);
    await DownloadUtilReportData(period, market, contractIDs);
}
async function SubmitAnnex5(period: DatePeriod, owner: string) {
    const name = SubmitAnnex5.name;
    const Message = `Submitting Annex 5 export for ${period}`;
    const Class = fileName + "/" + name;

    Logger.LogInformation(Message, Class);
    await DownloadAnnex5(period, owner);
}
async function SubmitAnnex12(period: DatePeriod, owner: string) {
    const name = SubmitAnnex12.name;
    const Message = `Submitting Annex 12 export for ${period}`;
    const Class = fileName + "/" + name;

    Logger.LogInformation(Message, Class);
    await DownloadAnnex12(period, owner);
}
enum DownloadFileTypes {
    zip = "zip",
    xml = "xml"
}
async function DownloadDataTape(period: DatePeriod, market: string, owner: string) {
    log.trace("DownloadDataTape()");

    const name = DownloadDataTape.name;
    const Class = fileName + "/" + name;

    const closeSpinner = useSpinnerPopup().show();
    const urlParams: string =
        process.env.VUE_APP_GET_FUNDING_REPORTS_EXPORT_URL_PARAMS!;

    const reqBody: IGetDataTapeRequest = {
        Owner: owner,
        Market: market,
        PeriodDate: period
    };

    var Message = `Downloading Funding Reports Export files (URL params: ${urlParams})`;

    log.debug(`${name}()\n${Message}`);

    try {
        const res = await financialSystemNetworkService.post(reqBody, urlParams) as AxiosResponse<IFileResponse>;
        fileResponse.value = res.data;
        log.trace(
            "DownloadDataTape()\nfileResponse.Name:",
            fileResponse.value.Name
        );

        MakeDownloadFile(fileResponse.value, DownloadFileTypes.zip);
        Reset();
        showingSuccNotifier.value = true;

        Logger?.LogInformation(Message, Class);
    } catch(error) {
        useNotifierWithErrFormatter().error({
            errorType: downloadErrorDesc,
            error
        });
    } finally {
        closeSpinner();
    }
}
async function DownloadUtilReportData(period: DatePeriod, market: string, contractIDs: string) {
    log.trace("DownloadUtilReportData()");

    const name = DownloadUtilReportData.name;
    const Class = fileName + "/" + name;

    // if the ids need to be separated depending on the backend functionality
    const contractIDStr = contractIDs.split(',').map(x=>x.trim());

    const closeSpinner = useSpinnerPopup().show();
    const urlParams: string =
        process.env.VUE_APP_GET_FUNDING_REPORTS_EXPORT_URL_PARAMS!;

    const reqBody: IGetDataTapeRequest = {
        Market: market,
        PeriodDate: period,
        ContractIDs: contractIDStr
    };

    var Message = `Downloading Funding Reports Export files (URL params: ${urlParams})`;

    log.debug(`${name}()\n${Message}`);

    try {
        const res = await financialSystemNetworkService.post(reqBody, urlParams) as AxiosResponse<IFileResponse>;
        fileResponse.value = res.data;
        log.trace(
            "DownloadUtilReportData()\nfileResponse.Name:",
            fileResponse.value.Name
        );

        MakeDownloadFile(fileResponse.value, DownloadFileTypes.zip);
        Reset();
        showingSuccNotifier.value = true;

        Logger?.LogInformation(Message, Class);
    } catch(error) {
        useNotifierWithErrFormatter().error({
            errorType: downloadErrorDesc,
            error
        });
    } finally {
        closeSpinner();
    }
}
async function DownloadAnnex5(period: DatePeriod, ownerID: string) {
    const closeSpinner = useSpinnerPopup().show();

    try {
        await euSecuritisationReport.download({
            type: 'annexe5',
            period
        }, {
            ownerID
        });
    } catch(error) {
        useNotifierWithErrFormatter().error({
            errorType: "Downloading Annexe5",
            error,
        });
    } finally {
        closeSpinner();
    }
}
async function DownloadAnnex12(period: DatePeriod, ownerID: string) {
    const closeSpinner = useSpinnerPopup().show();

    try {
        await euSecuritisationReport.download({
            type: 'annexe12',
            period
        }, {
            ownerID
        });
    } catch(error) {
        useNotifierWithErrFormatter().error({
            errorType: "Downloading Annexe12",
            error,
        });
    } finally {
        closeSpinner();
    }
}
const DownloadFundingReportsExportDisabled: ComputedRef<boolean> = computed(() => {
    log.trace("(computed) DownloadFundingReportsExportDisabled");

    switch (selectedReportType.value) {
        case FundingReportOptions.DATA_TAPE:
            log.trace(
                `(computed) DownloadFundingReportsExportDisabled
                \nFundingReportOptions.DATA_TAPE: ${FundingReportOptions.DATA_TAPE}
                \n_export.value.market: ${marketSelection.value}, !!_export.value.market: ${!!marketSelection.value},
                \n_export.value.contractOwner: ${selectedContractOwner.value}, !!_export.value.contractOwner: ${!!selectedContractOwner.value},
                \selectedPeriod.value: ${selectedPeriod.value},
                \nselectedReportType.value: ${selectedReportType.value}, !!selectedReportType.value: ${!!selectedReportType.value}`
            );
            return !(
                !!marketSelection.value
                && !!selectedContractOwner.value
                && !!selectedPeriod.value && selectedPeriod.value.isValid()
                && !!selectedReportType.value
            );
        case FundingReportOptions.UTIL_REPORT_DATA:
            log.trace(
                `(computed) DownloadFundingReportsExportDisabled
                \nFundingReportOptions.UTIL_REPORT_DATA: ${FundingReportOptions.UTIL_REPORT_DATA}
                \n_export.value.market: ${marketSelection.value}, !!_export.value.market: ${!!marketSelection.value},
                \n_export.value.contractIDs: ${contractIDs.value}, !!_export.value.contractIDs: ${!!contractIDs.value},
                \selectedPeriod.value: ${selectedPeriod.value},
                \nselectedReportType.value: ${selectedReportType.value}, !!selectedReportType.value: ${!!selectedReportType.value}`
            );
            return !(
                !!marketSelection.value
                && !!contractIDs.value && contractIDs.value != ""
                && !!selectedPeriod.value && selectedPeriod.value.isValid()
                && !!selectedReportType.value
            );
        case FundingReportOptions.ANNEX5:
            log.trace(
                `(computed) DownloadFundingReportsExportDisabled
                \nFundingReportOptions.ANNEX5: ${FundingReportOptions.ANNEX5}
                \selectedPeriod.value: ${selectedPeriod.value?.toJSON()},
                \nselectedReportType.value: ${selectedReportType.value}, !!selectedReportType.value: ${!!selectedReportType.value}`
            );
            return !(
                !!selectedPeriod.value
                && !!selectedReportType.value
            );
        default:
            log.error(`Invalid Funding Report Export Type received: ${selectedReportType.value}`);
            return false;
    }

});
async function MakeDownloadFile(fileResponse: IFileResponse, downloadFileType: DownloadFileTypes) {
    log.debug(`MakeDownloadFile()\ndownloadFileType: ${downloadFileType} (${DownloadFileTypes[DownloadFileTypes.zip]}, ${DownloadFileTypes[DownloadFileTypes.xml]})`);

    var Message = "Making download file from Funding Reports Export file data";

    const name = MakeDownloadFile.name;
    const Class = fileName + "/" + name;

    var file: Blob;
    switch (downloadFileType) {
        case DownloadFileTypes.zip:
            const byteArray: ArrayBuffer = Buffer.from(fileResponse.Data, "base64");
            file = new Blob([byteArray], { type: `application/${DownloadFileTypes.zip}` });
            break;
        case DownloadFileTypes.xml:
            const xmlData: string = fileResponse.Data;
            file = new Blob([xmlData], { type: `application/${DownloadFileTypes.xml}` });
            break;
        default:
            log.error("Invalid downloadFileType provided: ", downloadFileType);
            file = new Blob();
            break;
    }

    try {
        const url = URL.createObjectURL(file);
        // Create anchor element and set attributes
        const link = document.createElement("a");
        link.href = url;
        link.download = fileResponse.Name;

        // Trigger a click on the anchor element
        link.click();

        // Remove the anchor element
        link.remove();
    } catch (error) {
        useNotifierWithErrFormatter().error({
            errorType: Message,
            error
        });
    }
}
</script>

<style scoped lang="scss">
.form-container {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 20px;
    justify-content: space-evenly;
    justify-items: center;
    align-items: start;
    >*{
        min-width: 10rem;
        width: 100%;
        >*{
            width: 100%;
        }
    }
    :deep(select) {
        width: 100%;
        margin-bottom: 10px;
    }
    :deep(input) {
        width: 100%;
        margin-bottom: 10px;
    }
}
</style>