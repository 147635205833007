<script setup lang="ts" generic="T">
import { ref, computed } from 'vue';
import type { ComponentExposed } from 'vue-component-type-helpers';
import { DisplayableKeys } from '@/services/SorterService';
import TablePersistentChecking from '../TablePersistentChecking.vue';
export interface TableExpandableRowsProps<T> {
    rows?: T[];
    cols: DisplayableKeys<T>[];
    /** number of rows to show before hiding with expand button*/
    maxRows?: number;
}
const props = withDefaults(defineProps<TableExpandableRowsProps<T>>(),{
    maxRows: 3,
});
const emits = defineEmits<{
    (e:'expand');
    (e:'collapse');
}>();
defineExpose<{
    checkAll:()=>void;
}>({
    checkAll: ()=>table.value?.CheckAll(),
});
const table = ref<ComponentExposed<typeof TablePersistentChecking>>();
const expanded = ref(false);
const rowsPerPage = computed(()=>expanded.value?props.rows?.length??props.maxRows:props.maxRows);
const showExpand = computed(()=>(props.rows?.length ?? 0) > props.maxRows);
</script>
<template>
    <div class="rows-container" >
        <TablePersistentChecking ref="table"
            v-bind=$attrs
            :Rows=rows
            :Cols=cols
            :RowsPerPage=rowsPerPage
            DisablePaging
            style="flex: 0 1 fit-content"
        />
        <div v-if=showExpand class="expand" @click="expanded=!expanded;expanded?emits('expand'):emits('collapse');">{{ expanded ? 'Collapse rows...' : 'Expand rows...' }}</div>
    </div>
</template>
<style scoped lang="scss">
.rows-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    >.expand {
        cursor:pointer;
        background-color: var(--primary-color-lighter);
        padding: 8px 8px 8px 20px;
        font-size: 0.6rem;
    }
}
</style>
