<template>
    <Popup :model-value="!!modelValue" @update:model-value=close has-close >
        <form @submit.prevent="forceValidation(modelValue,setToValid)" class="flex-col-centered" style="row-gap: 1lh;">
            <div class="flex-col-centered textarea-container" style="flex: 1 1 auto; row-gap: 5px;">
                <label for="overrideMessage">Please enter comments for overriding contract validation(s):</label>
                <textarea id="overrideMessage" required v-model=text></textarea>
            </div>
            <button class="submit-btn">Confirm overriding validation(s) to be {{ validStr }}</button>
        </form>
    </Popup>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import Popup from '../Popup.vue';
import { contractApprovalValidationOverride } from '@/services/network';
import { authService } from '@/auth/authSingleton';
import useSpinnerPopup from '@/services/composables/SpinnerPopup';
import {ContractValidationType} from '@/models/enums/ContractValidationType';
import useNotifierWithErrFormatter from '@/services/composables/NotifierWithErrFormatter';

const props = defineProps<{
    /**
     * contractIDs
     */
    modelValue?: string[];
    validationType: keyof typeof ContractValidationType;
    setToValid?: boolean;
}>();
const emits = defineEmits<{
    (e: 'update:modelValue');
    (e: 'override', contractIDs: string[], setToValid: boolean);
}>();
const text = ref('');
const working = ref(false);
const validStr = computed(()=>props.setToValid ? 'VALID' : 'INVALID');
async function forceValidation(contractIDs?: string[], setToValid?: boolean) {
    if (working.value) return;
    const closeSpinner = useSpinnerPopup().show();
    try {
        working.value = true;
        if (!contractIDs) {
            throw new ReferenceError('contractIDs is unexpectedly null. (popup shouldn\'t be open)');
        }
        setToValid = !!setToValid;
        const overrideMessage = text.value;
        const username = authService.getEmailAddress();
        await contractApprovalValidationOverride.post({
            validationType: props.validationType,
        }, {
            contractIDs,
            username,
            setToValid,
            overrideMessage,
        });
        working.value = false;
        useNotifierWithErrFormatter().success({ msg: `Successfully forced contracts to be ${setToValid ? 'valid' : 'invalid'} for:\n${contractIDs}` });
        emits('override', contractIDs, setToValid);
        close();
    } catch(error) {
        useNotifierWithErrFormatter().error({ error, errorType: 'Forcing Validation Result'});
    } finally {
        closeSpinner();
        working.value = false;
    }
}
function close() {
    text.value = '';
    emits('update:modelValue');
}
</script>
<style scoped lang="scss">
.flex-col-centered.textarea-container {
    align-items: stretch;
    >textarea {
        flex: 1 1 auto;
        min-height: 8lh;
    }
}
.submit-btn {
    cursor: pointer;
    border-width: 0px;
    border-radius: var(--buttons-border-radius);
    background-color: var(--primary-color-lighter);
    padding: 10px;
    color: blue;
    font-size: larger;
    font-weight: bold;
    &:hover {
        color: white;
        background-color: var(--primary-color);
    }
}
</style>