import DateIgnoreTime from "./DateIgnoreTime";

export default class DatePeriod extends DateIgnoreTime {
    constructor(input?: number|string|Date|null, in2?: number) {
        // @ts-expect-error
        super(input, in2);
        if(input) this.setDate(1);
    }
    override toString(format?: string) {
        if (this.isValid()) {
            const year = `${this.getFullYear()}`.padStart(4,'0');
            const month = `${this.getMonth()+1}`.padStart(2,'0');
            return `${year}-${month}`;
        }
        return 'NULL';
    }
    within(date?: Date) {
        if (date && date.isValid()) {
            if (!this.isValid()) return true;
            return this.getMonth() === date.getMonth() && this.getFullYear() === date.getFullYear();
        }
        return false;
    }
}