<template>
    <div class="FundManagement">
        <div class="content-container">
            This page is currently under development.
        </div>
    </div>
</template>

<script setup lang="ts">
document.title = `Fund Management - Optio`;
</script>
