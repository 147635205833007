import IChurnTable from "@/Interfaces/CustomersAndContracts/IChurnTable";
import { extractNonArraysFromObj } from '@/services/helpers/ObjectHelper';
import DateIgnoreTime from "@/types/DateIgnoreTime";
import DatePeriod from "@/types/DatePeriod";

export default class ChurnTable implements IChurnTable {
    CONTRACT_ID: string;
    PERIOD_MONTH: DatePeriod;
    APPROVED_CHURN_AMOUNT_DATE_UPDATED: DateIgnoreTime;

    constructor(data: IChurnTable) {
        Object.assign(this,extractNonArraysFromObj(data));
        this.CONTRACT_ID = data.CONTRACT_ID;
        this.PERIOD_MONTH = new DatePeriod(data.PERIOD_MONTH);
        this.APPROVED_CHURN_AMOUNT_DATE_UPDATED = new DateIgnoreTime(data.APPROVED_CHURN_AMOUNT_DATE_UPDATED);
    }
  }
  