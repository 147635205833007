<template id="CarPurchases">
    <div class="content-container">
        <div class="top-content">
            <GettingCarPurchasesErrorInfo :showError="!!infoError" errorDescription="(Getting Car Purchases)"
                :error="infoError" :change="!!infoError" />
            <div class="flex-row-centered">
                <MarketSelector @change=updateMarketSelection enableSE />
                <PeriodSelector 
                    v-model=period 
                    showTitle 
                    hasNullSelector
                    :updateOnPropertyChange="marketSelection"
                    :filter="(p)=>invoices?.some(x=>p.within(x.IssueDate))"
                />
            </div>
        </div>
        <Table :Rows=displayedCarPurchases
            :Cols=carPurchasesCols
            :Labels=carPurchasesLabels
            v-model:RowsPerPage=carPurchasesRowCount
            v-model:Page=carPurchasesPage
            :HasError=!!infoError
            :RowClickedCallBack=RowClicked
            :DefaultSortCols=carPurchasesDefaultSortCols
            :DefaultSortOrders=carPurchasesDefaultSortOrders >
            <template #Balance={value}>
                {{ MonetaryValueWithCommas(value.Balance) }}
            </template>
        </Table>
    </div>
</template>

<script setup lang="ts">
import {
    computed,
    onMounted,
    onUnmounted,
    ref,
    inject,
} from "vue";
// Services
import { apiLoggerKey } from "@/types/ServiceKeys";
import { MarketSelection, MonetaryValueWithCommas } from "@/services/helpers/Helper";
import useCarPurchasesVars from "@/services/helpers/CarPurchases/CarPurchasesVariables";
import {
    RowClicked,
    ParseCarPurchaseCollectiveCarSaleInvoices,
} from "@/services/helpers/CarPurchases/CarPurchasesFunctions";
// Data
import { ICarPurchasesCollectiveCarSaleInvoice } from "@/Interfaces/CarPurchases/ICarPurchasesCollectiveCarSaleInvoice";
import { marketSelection } from "@/services/helpers/Helper";
import { CarPurchasesCollectiveCarSaleInvoice } from "@/models/CarPurchases/CarPurchasesCollectiveCarSaleInvoice";
// Table
import Table from "@/components/TablePersistentChecking.vue";
// Misc
import log from "loglevel";
import { DisplayableKeys } from "@/services/SorterService";
import ErrorInfo from "@/components/ErrorInfo.vue";
import MarketSelector from "@/components/MarketSelector.vue";
import PeriodSelector from "@/components/PeriodSelector.vue";
import DatePeriod from "@/types/DatePeriod";
import { useRoute } from "vue-router";

const GettingCarPurchasesErrorInfo = ErrorInfo;
const route = useRoute();
log.trace("CarPurchases");
const Logger = inject(apiLoggerKey);

const {
    isLoading,
    carPurchasesServices,
    carPurchasesDefaultSortCols,
    carPurchasesDefaultSortOrders,
} = useCarPurchasesVars();

const carPurchasesCols: DisplayableKeys<CarPurchasesCollectiveCarSaleInvoice>[] = [
	"CollectiveInvoiceId",
	// "CollectiveInvoiceSequenceNumber",
	"Market",
	"IssueDate",
	"Balance",
];
const carPurchasesLabels = [
	"Collective Invoice Id",
	// "Collective Invoice Seq. No.",
	"Market",
	"Issue Date",
	"Balance",
];
const invoices = ref<CarPurchasesCollectiveCarSaleInvoice[]>();
const displayedCarPurchases = computed(()=>invoices.value?.filter(x => period.value?.within(x.IssueDate)));
const carPurchasesPage = ref(1);
const carPurchasesRowCount = ref(40);
const period = ref<DatePeriod>();

const infoError = ref();

////////////////////////////////////////////////////////////////////////////
// LOGIC
////////////////////////////////////////////////////////////////////////////
onMounted(async () => {
    log.trace("onMounted() CarPurchases");
    document.title = `CarPurchases - Optio`;
    marketSelection.value = 'GB'
    
    let routeMarket = route.params.market as string 
    if (Object.keys(MarketSelection).includes(routeMarket)){
        marketSelection.value = routeMarket as keyof typeof MarketSelection;
    } 
    await GetCollectiveCarSaleInvoices();
});
onUnmounted(() => {
    marketSelection.value = 'GB'
})
async function GetCollectiveCarSaleInvoices() {
    const name = __filename + "/" + GetCollectiveCarSaleInvoices.name;

    var Message = `Getting Car Purchases: (market: ${marketSelection.value})`;
    log.debug(`${name}()\n${Message}`);
    try {
        infoError.value = undefined;
        invoices.value = undefined;
        const response = await carPurchasesServices.GetCollectiveCarSaleInvoices<ICarPurchasesCollectiveCarSaleInvoice[]>(marketSelection.value);
        invoices.value = response.data
            .filter(carPurchase => (carPurchase.Balance ?? 0)>0)
            .map(ParseCarPurchaseCollectiveCarSaleInvoices);

        Logger?.LogInformation(Message, name);
    } catch (error: unknown) {
        infoError.value = error;
        Message = `(Error) ${Message}: ${error}`;
        log.error(`${Message}`);
        Logger?.LogError(Message, name);
    } finally {
        isLoading.value = false;
    }
}
/*
    Fetched new data when marketSelection changes.
    Triggered by marketSelection.change.
*/
async function updateMarketSelection() {
    await GetCollectiveCarSaleInvoices()
}
</script>
