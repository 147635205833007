<template>
    <Popup
        :model-value="!!filteredData"
        title="Transfer confirmation"
        hasClose
        :onClose="closeChild"
    >
        <template #contents>
            <ConfirmTransferErrorInfo
                :showError="showError"
                :closeError="closeError"
                :errorDescription="errorDescription"
                :error="infoError"
                :change="!!showError"
                :axiosError="true"
                :errorDisplayText="errorText"
            />
            <ConfirmPaymentWarningInfo
                :showWarning="showWarning"
                :closeWarning="closeWarning"
                :warningDescription="warningDescription"
                :warning="infoWarning"
                :change="!!showWarning"
                :axiosError="true"
            />
            <div class="grid-item">Please confirm that you are happy to proceed.</div>
            <div class="grid-item">
                A sum of <strong> {{ currencyUsed+Math.abs(totalDiff)?.toFixed(2) }} </strong> is
                being transfered from account <strong> {{ transferFrom }} </strong> to
                account <strong> {{ transferTo }} </strong>.
            </div>
            <div class="top-content">
                <span style="padding-bottom: 0.4rem">Select a payment date:</span>
                <span
                    title="Select a date for confirmed ledger tranfers"
                    class="search-with-button"
                >
                    <div class="date-picker" style="width: 50%">
                        <DatePicker
                            v-model="selectedDate"
                            type="date"
                            :enable-time-picker="false"
                            :format="FormatDatePicker"
                            placeholder="Select Date..."
                            :text-input-options="textInputOptions"
                            text-input
                            :max-date="maxDate"
                            :teleport="true"
                            required
                        />
                    </div>

                    <button
                        ref="confirmLedgerTransferButton"
                        @click="ConfirmLedgerTransfer()"
                        :disabled="selectedDate == undefined"
                        class="right-button"
                    >
                        Confirm
                    </button>
                </span>
            </div>
        </template>
    </Popup>
</template>

<script setup lang="ts">
import { computed, ref, Ref } from "vue";
import TransferPaymentTable from "@/models/Ledgering/TransferPaymentTable";
import DatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { TransferPaymentsHelper } from "@/services/helpers/Ledgering/LedgerTransferPaymentsHelper";
import { AxiosError } from "axios";
import Popup from "@/components/Popup.vue";
import ErrorInfo from "@/components/ErrorInfo.vue";
import WarningInfo from "../WarningInfo.vue";
import useNotifierWithErrFormatter from "@/services/composables/NotifierWithErrFormatter";
import useSpinnerPopup from "@/services/composables/SpinnerPopup";
import { CurrencySymbol, MarketCurrency } from "@/services/helpers/Helper";

const props = defineProps<{
    modelValue: TransferPaymentTable[] | undefined;
}>();
const emits = defineEmits<{
    (e: "update:modelValue");
    (e: "confirm");
}>();
const filteredData = computed({
    get() {
        return props.modelValue;
    },
    set(val) {
        emits("update:modelValue");
    },
});
const working = ref(false);
const selectedDate: Ref<Date | undefined> = ref(undefined);
const textInputOptions = ref({ format: "yyyy/MM/dd" });
const today: Date = new Date();
const maxDate: Date = new Date(today);
maxDate.setDate(today.getDate() + 1);
const currencyUsed = computed(()=>{
    let marketValue = filteredData.value?.[0].Market
    if(marketValue != undefined && marketValue != null ){
        return CurrencySymbol[MarketCurrency[marketValue]]
    }
    else{
        return  CurrencySymbol[MarketCurrency['GBP']]
    }
});

// ERRORS
const ConfirmTransferErrorInfo = ErrorInfo;
const showError: Ref<boolean> = ref(false);
const closeError: Ref<boolean> = ref(true);
const infoError: Ref<AxiosError | undefined> = ref(undefined);
const errorDescription = ref<string>("");
const errorText = ref<string>("");

// Warning
const ConfirmPaymentWarningInfo = WarningInfo;
const showWarning: Ref<boolean> = ref(false);
const closeWarning: Ref<boolean> = ref(true);
const infoWarning: Ref<AxiosError | undefined> = ref(undefined);
const warningDescription = ref<string>("");

// Services
const LedgerServices: TransferPaymentsHelper = new TransferPaymentsHelper();

function closeChild() {
    clearEntries();
    emits("update:modelValue");
}

const listFrom: Ref<TransferPaymentTable[]> = ref(
    computed(
        () =>
            filteredData.value?.filter(
                (x) => x.AccountFrom === filteredData.value?.[0]?.AccountFrom
            ) ?? []
    )
);

const listTo: Ref<TransferPaymentTable[]> = ref(
    computed(
        () =>
            filteredData.value?.filter(
                (x) => x.AccountFrom === filteredData.value?.[0]?.AccountTo
            ) ?? []
    )
);
const listATotal: Ref<number> = computed(() => {
    return listFrom.value.reduce(
        (sum, row) =>
            sum +
            (typeof row.Amount === "number" ? row.Amount : parseFloat(row.Amount || "0")),
        0
    );
});

const listBTotal: Ref<number> = computed(() => {
    return listTo.value.reduce(
        (sum, row) =>
            sum +
            (typeof row.Amount === "number" ? row.Amount : parseFloat(row.Amount || "0")),
        0
    );
});

const totalDiff: Ref<number> = computed(() => {
    return listATotal.value - listBTotal.value;
});

const transferFrom = computed(() =>
    totalDiff.value > 0
        ? filteredData.value?.[0]?.AccountFrom
        : filteredData.value?.[0]?.AccountTo
);

const transferTo = computed(() =>
    totalDiff.value > 0
        ? filteredData.value?.[0]?.AccountTo
        : filteredData.value?.[0]?.AccountFrom
);

const FormatDatePicker = (date: Date) => {
    const name = FormatDatePicker.name;

    return date?.toLocaleString("sv-SE").split(" ")[0].replaceAll("-", "/");
};

const FormatRequestDate = (date: Date) => {
    return date?.toLocaleString("sv-SE").split(" ")[0];
};

const TransactionIds = computed(() => filteredData.value?.map((x) => x.TransactionId));

function clearEntries() {
    selectedDate.value = undefined;
}
async function ConfirmLedgerTransfer() {
    if (!TransactionIds.value)
        throw new ReferenceError("TransactionIds unexpectedly empty");
    if (working.value) return;
    working.value = true;
    const closeSpinner = useSpinnerPopup().show();
    try {
        if (selectedDate.value) {
            await LedgerServices.putConfirmTransferPayments(
                TransactionIds.value,
                FormatRequestDate(selectedDate.value)
            );
        }
        useNotifierWithErrFormatter().success({
            msg: "Payment successfully registered.",
        });
        closeChild();
        emits("confirm");
    } catch (error) {
        useNotifierWithErrFormatter().error({
            name: `${__filename}/${ConfirmLedgerTransfer.name}`,
            errorType: "Confirming payment",
            error,
        });
    } finally {
        closeSpinner();
        working.value = false;
    }
}
</script>

<style scoped>
.grid-item {
    padding: 10px;
}
</style>
