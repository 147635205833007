<template>
    <div style="position: fixed; z-index: 9999; left: 0; width:100vw;">
        <Transition mode="out-in">
            <div v-if=showing class="desc-error" style="width: fit-content;">
                <div v-if="type != 'ERROR'" class="desc"
                    style="padding-left: 2rem; padding-right: 2rem; background-color: #c2dab8 !important; opacity: 0.95;">
                    <div class="desc-header">{{ type }}:</div>
                    <div>
                        <div v-if="title">{{ title }}<br></div>
                        {{ msg }}
                    </div>
                </div>
                <div v-if="type == 'ERROR'" class="desc"
                    style="padding-left: 2rem; padding-right: 2rem; background-color: burlywood !important; opacity: 0.95;">
                    <div class="desc-header">{{ type }}:</div>
                    <div>
                        <div v-if="title">{{ title }}<br></div>
                        {{ msg }}
                    </div>
                </div>
            </div>
        </Transition>
    </div>
</template>
<script setup lang="ts">
/**
 * incomplete to be modified to show different icons
 * and use render controller to destroy/create instance from vue component composable
 * without adding it on template every page
 */
//import {computed, onMounted, getCurrentInstance, render, onUnmounted} from 'vue';
import {computed, watch} from 'vue';
import NotifierType from '@/types/NotifierType';
import CancellablePromise from '@/types/CancellablePromise';

const props = withDefaults(defineProps<{
    /**
     * whether the alert is showing
     */
    modelValue: boolean;
    title?: string;
    msg: string;
    type: NotifierType;
    /**
     * duration in seconds
     */
    duration?: number;
}>(), {
    duration: 3,
});
const emits = defineEmits<{
    (e: 'update:modelValue', val: boolean): void;
}>();
defineExpose({
    show
});
const showing = computed({
    get() {
        return props.modelValue;
    },
    set(val: boolean) {
        emits('update:modelValue',val);
    }
});
var hidingPromise: CancellablePromise;
function show() {
    showing.value = true;
}
function hide() {
    showing.value = false;
}
function onShow() {
    hidingPromise?.cancel();
    hidingPromise = new CancellablePromise(
        new Promise<void>((res,rej)=>{
            setTimeout(()=>res()
            , Math.round(props.duration*1000));
        })
    ).then(hide) as CancellablePromise;
}
watch(showing,(v, old)=>{
    if(v && !old) onShow();
});
</script>
<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>