import { FormatDatePicker } from "@/services/helpers/CarPurchases/CarPurchasesFunctions";
import { CarPurchasesCarSaleInvoice } from "./CarPurchasesCarSaleInvoice";
import DateIgnoreTime from "@/types/DateIgnoreTime";

export class CarPurchasePayment {
    ContractId?: string;
    InvoiceId?: string;
    AmountVAT?: number;
    IssueDate: DateIgnoreTime;

    constructor(data: Partial<CarPurchasesCarSaleInvoice>) {
        // Object.assign(this, data);
        this.ContractId = data.CONTRACT_ID;
        this.InvoiceId = data.INVOICE_ID;
        this.AmountVAT = data.AMOUNT_VAT;
        this.IssueDate = new DateIgnoreTime(data.ISSUE_DATE);
    }
}