<template>
    <div class="col-sm-12">
        <table class="vtl-table">
            <thead class="vtl-thead" ref="tableHeader">
                <tr class="vtl-thead">
                    <th v-if="HasCheckBox && checkAll" class="vtl-thead vtl-td-checkbox">
                        <input type="checkbox" class="vtl-thead" :checked="IsAllChecked()"
                            @click="UpdateCheckedRows ? UpdateCheckedRows() : CheckUncheckAll" title="Check all rows" />
                    </th>
                    <th v-if="!checkAll && HasCheckBox" class="vtl-thead vtl-td-checkbox"></th>
                    <th v-for="(col, i) in Cols" class="vtl-thead" :key="i">
                        <div class="vtl-thead-column vtl-sortable vtl-both" :class="{
                        'vtl-asc':
                            sortCols.indexOf(i) >= 0 &&
                            sortOrders[sortCols.indexOf(i)] === 1,
                        'vtl-desc':
                            sortCols.indexOf(i) >= 0 &&
                            sortOrders[sortCols.indexOf(i)] === -1,
                    }" @click="sortBy(i as number)"
                            :title="`Toggle sort for this column (${Labels ? Labels![i] ?? col.toString() : ''})`">
                            <div v-if="Labels && Labels.length == Cols.length && Labels[i]" v-html="Labels[i]"></div>

                            <span v-else :title="`Toggle sort for this column (${col.toString()})`">{{ col }}</span>
                        </div>
                    </th>
                </tr>
            </thead>
            <tr class="vtl-loading-content">
                <td v-if="isLoading" :colspan="Cols.length + (HasCheckBox ? 1 : 0)">
                    Loading...
                </td>
            </tr>
            <template v-if="localRows.length > 0 && !HideTable">
                <tbody class="vtl-tbody">
                    <tr v-for="(row, i) in pageRows" :key="i" class="vtl-tbody"
                        :class="{ 'vtl-checked': UnstyledCheckedRows ? false : row.checked, 'vtl-clickable': !!RowClickedCallback }"
                        @click="onRowClick(row.value)">
                        <td v-if="HasCheckBox" class="vtl-tbody">
                            <input type="checkbox" class="vtl-tbody" :value="ColKey ? row.value[ColKey as (keyof any)] : i"
                                :checked="row.checked"
                                @change="UpdateCheckedRows ? UpdateCheckedRows(row) : CheckRow?.(row)"
                                @click="disabledEventPropagation($event)" :disabled="CheckBoxForAllRows
                            ? false
                            : CheckBoxOnRowDefinesNegatives
                                ? row.value[CheckBoxOnRow ?? '']
                                : !row.value[CheckBoxOnRow ?? '']
                        " />
                        </td>
                        <td v-for="(col, j) in Cols" :key="j" class="vtl-tbody" :class="col"
                            :title="RowClickedCallback ? 'Click to view row details (opens new tab)' : ''">
                            <slot :name="(Array.isArray(col)) ? col.join('-') : (col as string)" :value="row.value">
                                <div v-if="Array.isArray(col)">
                                    <span>{{
                        col.map((key) => access(row.value, key)).join(" ")
                    }}</span>
                                </div>
                                <span v-else>{{ access(row.value, col) }}</span>
                            </slot>
                        </td>
                    </tr>
                </tbody>
            </template>
            <tr class="vtl-loading-content">
                <td v-if="ShowExpand" :colspan="Cols.length + (HasCheckBox ? 1 : 0)" @click="ExpandRow()">
                    {{ contractRows ? 'Collapse rows...' : 'Expand rows...' }}
                </td>
            </tr>
        </table>
        <div class="vtl-empty-msg" v-if="numRecords == 0 && !isLoading">
            {{ NoDataAvailableMessage }}
        </div>
    </div>
    <div class="vtl-paging vtl-row" v-if="maxPage > 1 && !HideTable">
        <div class="vtl-paging-info col-md-4">
            <div role="status" aria-live="polite" class="vtl-paging-span">
                {{ StringFormat(PagingInfoMessage, rowStart, rowEnd, numRecords) }}
            </div>
        </div>
        <ul class="vtl-pagination col-md-4">
            <li class="page-item" :class="{ disabled: page <= 1 }">
                <div class="page-link" aria-label="Jump to First Page" @click="MovePage(1)">
                    <span aria-hidden="true">&laquo;</span>
                    <span class="sr-only">First</span>
                </div>
            </li>
            <li class="page-item" :class="{ disabled: page <= 1 }">
                <div class="page-link" aria-label="Previous" @click="PrevPage">
                    <span aria-hidden="true">&lt;</span>
                    <span class="sr-only">Prev</span>
                </div>
            </li>
            <li class="page-item" v-for="n in pageSwitch.num" :key="n + pageSwitch.start"
                :class="{ active: page === n + pageSwitch.start }">
                <div class="page-link" @click="MovePage(n + pageSwitch.start)">
                    {{ n + pageSwitch.start }}
                </div>
            </li>
            <li class="page-item" :class="{ disabled: page >= maxPage }">
                <div class="page-link" aria-label="Next" @click="NextPage">
                    <span aria-hidden="true">&gt;</span>
                    <span class="sr-only">Next</span>
                </div>
            </li>
            <li class="page-item" :class="{ disabled: page >= maxPage - 1 }">
                <div class="page-link" aria-label="Next" @click="MovePage(maxPage)">
                    <span aria-hidden="true">&raquo;</span>
                    <span class="sr-only">Last</span>
                </div>
            </li>
        </ul>
        <div class="vtl-paging-change col-md-4">
            <span class="vtl-paging-span">{{ PageSizeChangeLabel }} </span>
            <select class="vtl-paging-select" v-model="rowsPerPage" @change="loadDataIfCallBack">
                <option v-for="pageOption in (PageOptions as Array<number>)" :value="pageOption" :key="pageOption">
                    {{ pageOption }}
                </option>
            </select>
            <span class="vtl-paging-span">{{ GotoPageLabel }} </span>
            <select class="vtl-paging-select" v-model="page" @change="MovePage(page)">
                <option v-for="n in maxPage" :key="n" :value="n">
                    {{ n }}
                </option>
            </select>
        </div>
    </div>
    <ScrollToTopButton :visibleY="headerBot - scrollOffset" />
</template>

<script setup lang="ts" generic="T">
import {
    ref,
    Ref,
    computed,
    nextTick,
    ComputedRef,
    onMounted,
    onUnmounted,
    inject,
    watch,
} from "vue";
import CancellablePromise from "@/types/CancellablePromise";
import Sorter from "@/services/SorterService";
import ScrollToTopButton from "@/components/ScrollToTopButton.vue";
import { table } from "console";
// Misc
import log from "loglevel";

export interface ITableProps<T> {
    /**
     *  An array of T typed objects for displaying on the table
     */
    Rows?: Array<T>; //can be updated by parent
    /**
     *  An array containing field names of T, or subarrays
     *   of field names if the cell uses multiple field of data
     *   when sorting, the last element is the most important
     *
     *  example: if T has 'arms': Array<Arm>,'legs': Array<Leg>,'head': Head
     *  you can pass ['arms',['legs','head']]
     */
    Cols: Array<keyof T | Array<keyof T>>;
    /**
     * The column headings you want to show on the table for each column
     * If the length doesn't match that of Cols, will show the field
     * names defined in Cols instead
     */
    Labels?: Array<string>;
    /**
     * The unique key of each row for accessing rows, not important
     * for now actually because Rows isn't implemented as a map at the moment
     */
    ColKey?: string;
    /**
     * Set to sort this column by the order defined by `DefaultSortCols` by default
     * if possible must be an numeric index value
     */
    DefaultSortCols?: Array<number>;
    /**
     * Set to sort the columns in `DefaultSortCols` to ascending (1) or order by
     * descending (-1) order
     */
    DefaultSortOrders?: Array<1 | -1>;
    /**
     * If true, show an extra column of checkboxes to the left
     *
     * Default is true.
     */
    HasCheckBox?: boolean;
    /**
     * If true, it shows the checkbox in the header
     *
     * Default is true.
     */
    checkAll?: boolean;
    /**
     * If true, all rows have checkboxes to the left
     * If false, checkboxed rows are specified in `CheckboxedRows`
     *
     * Default is true.
     */
    CheckBoxForAllRows?: boolean;
    /**
     * If true, all rows have checkboxes to the left
     * If false, checkboxed rows are specified by `CheckboxOnRow`
     *
     * Default is true.
     */
    CheckBoxOnRow?: string;
    /**
     * If true, 'CheckBoxOnRow' defined which rows should should be have checkbooxes
     *
     * Default is false.
     */
    CheckBoxOnRowDefinesNegatives?: boolean;
    // Number of rows per page
    /**
     * Default number of rows per page when the page is displayed.
     * (The user may have a preference saved, pass it here)
     *
     * Default is 20.
     */
    RowsPerPage?: number;
    // data passed in may only be a subset of the total number of records
    /**
     * The total number of records for the current query from the database
     * for calculating number of pages. When data is fully loaded, you
     * can just pass Rows.length. Otherwise pass the number the server returns.
     */
    NumRecords?: number; //can be updated by parent
    //assumes when NumRecords!=Rows.length, needs to be dynamically loaded
    /**
     * Do I return the reference to checked rows or just the keys of those rows
     * If key is not defined, return entire row even if true.
     *
     * Default is false.
     */
    ReturnKey?: boolean;
    /**
     * Array of page numbers to show on the screen for the dropdown box.
     *
     * Default is [20,50,100].
     */
    PageOptions?: Array<number>;
    /**
     * The message displayed on the bottom left corner
     * {0} is the starting row on display, {1} is the last row on display
     * {2} is the current page
     *
     * Default message is Showing {0}-{1} of {2}
     */
    PagingInfoMessage?: string;
    /**
     * The label in front of the dropdown box of page
     *
     * Default is Row count:
     */
    PageSizeChangeLabel?: string;
    GotoPageLabel?: string;
    NoDataAvailableMessage?: string;
    /**
     * If true, hides the table but leaves the headings untouched
     */
    HideTable?: boolean;
    RowClickedCallback?: (row: T) => void;
    UnstyledCheckedRows?: boolean;
    CheckRow?: Function;
    UpdateCheckedRows?: Function;
    CheckedByDefault?: boolean;
    SynchroniseChecks?: Function;
    SynchroniseChecksTrigger?: boolean;
    checkedRows?: (keyof T)[];
    checkCounter?: number;
    ShowExpand?: boolean,
    ExpansionThreshold?: number,
    LoadDataCallBack?: (
        page: number,
        rowsPerPage: number,
        sortCols: Array<number>,
        sortOrders: Array<-1 | 1>
    ) => CancellablePromise<void>;
}
export interface RowWrapper<T> {
    checked: boolean;
    value: T;
}
export interface ITableExportsInternal<T> {
    LoadData: () => void;
    GetChecked: () => T[] | T[keyof T][];
    TableLeft: number | Ref<number>;
    TableRight: number | Ref<number>;
}
const props = withDefaults(defineProps<ITableProps<T>>(), {
    HasCheckBox: true,
    checkAll: true,
    RowsPerPage: 20,
    PageOptions: () => [20, 50, 100],
    PagingInfoMessage: "Showing {0}-{1} of {2}",
    PageSizeChangeLabel: "Row count: ",
    GotoPageLabel: "Go to page: ",
    NoDataAvailableMessage: "No data",
    CheckBoxForAllRows: true,
    checkedRows: () => [] as (keyof T)[],
    ExpansionThreshold: 3,
});
let isLoading = ref(true);
let callBackPromise: CancellablePromise<void> | undefined = undefined;
const page: Ref<number> = inject("page") ?? ref(1);
const numRecords = computed(() => {
    if (props.LoadDataCallBack) return props.NumRecords ?? 0;
    else return props.Rows ? props.Rows.length : 0;
});
const rowsPerPage: Ref<number> = inject("rowCount") ?? ref(props.RowsPerPage ?? 20);
log.trace("rowsPerPage:", rowsPerPage.value);
const rowStart = computed(() => (page.value - 1) * rowsPerPage.value + 1);
const rowEnd = computed(() => {
    const limit = rowStart.value + rowsPerPage.value - 1;
    const n = numRecords.value ?? 0;
    return n >= limit ? limit : n;
});
const maxPage = computed(() => {
    const n = numRecords.value ?? 0;
    if (n <= 0) return 0;
    let maxP = Math.floor(n / rowsPerPage.value);
    const mod = n % rowsPerPage.value;
    if (mod > 0) maxP++;
    return maxP;
});
const pageSwitch = computed(() => {
    let start = page.value - 3; // A dynamic value based on page width
    start = start < 0 ? 0 : start;
    let end = start + 5;
    if (end > maxPage.value) {
        start = maxPage.value - 5;
        end = maxPage.value;
    }
    start = start < 0 ? 0 : start;
    return { start: start, num: end - start };
});
const sortCols = ref(props.DefaultSortCols ?? ([] as number[]));
const sortOrders = ref(getDefaultSortOrder());
function getDefaultSortOrder(): Array<1 | -1> {
    if (props.DefaultSortOrders) {
        if (props.DefaultSortOrders.length === props.DefaultSortCols?.length) {
            return props.DefaultSortOrders;
        }
    }
    return props.DefaultSortCols ?
        Array(props.DefaultSortCols.length).fill(1) :
        [] as Array<1 | -1>;
}
function getSortingKeys() {
    return sortCols.value.flatMap(i => props.Cols[i]);
}
function getSortingOrders() {
    const ordersMapped = sortCols.value.flatMap((colIdx, sortIdx) => {
        const keys = props.Cols[colIdx];
        return (keys instanceof Array) ? keys.map(_ => sortOrders.value[sortIdx]) : sortOrders.value[sortIdx];
    });
    return ordersMapped;
}
// Rows that are displayed
const localRows: ComputedRef<Array<RowWrapper<T>>> = computed(
    () => {
        if (!props.Rows) {
            isLoading.value = true;
            return [];
        }
        let rows = props.Rows.map((row: T) => {
            return { value: row, checked: false } as RowWrapper<T>;
        });
        if (!props.LoadDataCallBack) {
            // Sort locally if there is no callback to get sorted data
            const sortColKeys =
                sortCols.value.length >= 0
                    ? sortCols.value.map((i) => props.Cols[i])
                    : props.ColKey as keyof T
                        ? [props.ColKey as keyof T]
                        : [];

            if (sortColKeys.length > 0) {
                const sortColKeys = getSortingKeys();
                const sortOrders = getSortingOrders();
                rows = rows.sort((a: RowWrapper<any>, b: RowWrapper<any>): number =>
                    Sorter.CompareObjectWithMultipleKeys(
                        a.value,
                        b.value,
                        sortColKeys,
                        sortOrders
                    )
                );
            }
        }
        nextTick(() => {
            isLoading.value = false;
            if (!props.LoadDataCallBack) UpdateTablePosition();
        });
        return rows;
    }
);

const contractRows: Ref<boolean> = ref(false);
// const contractRows: Ref<boolean> = inject("contractRows") ?? ref(false);
const pageRows: ComputedRef<Array<RowWrapper<any>>> = computed(() => {
    if (props.ShowExpand && !contractRows.value) {
        return props.LoadDataCallBack
            ? localRows.value.slice(0, props.ExpansionThreshold)
            : localRows.value.slice(rowStart.value - 1, rowEnd.value).slice(0, props.ExpansionThreshold);
    } else {
        return props.LoadDataCallBack
            ? localRows.value
            : localRows.value.slice(rowStart.value - 1, rowEnd.value);
    }
});
const allRows: ComputedRef<Array<RowWrapper<any>>> = computed(() =>
    props.LoadDataCallBack
        ? localRows.value
        : localRows.value
);
const headerBot = ref(0);
const tableLeft = ref(0);
const tableRight = ref(0);
const tableHeader: Ref<HTMLElement | undefined> = ref(undefined);
const scrollOffset = 50;
defineExpose<ITableExportsInternal<any>>({
    LoadData: loadDataIfCallBack,
    GetChecked,
    TableLeft: tableLeft,
    TableRight: tableRight,
});

onMounted(() => {
    window.addEventListener("resize", UpdateTablePosition);
    UpdateTablePosition();
    loadDataIfCallBack();
});
onUnmounted(() => window.removeEventListener("resize", UpdateTablePosition));
function onRowClick(row: T) {
    props.RowClickedCallback?.(row);
}
function ExpandRow() {
    log.trace("ExpandRow")
    contractRows.value = !contractRows.value;
}
function UpdateTablePosition() {
    if (!tableHeader.value) return;
    const headerPos = tableHeader.value.getBoundingClientRect();
    headerBot.value = window.scrollY + headerPos.bottom;
    tableLeft.value = window.scrollX + headerPos.left;
    tableRight.value = window.scrollX + headerPos.right;
}
function IsAllChecked(): boolean {
    log.trace("IsAllChecked()");
    if (allRows.value.length == 0) return false;
    else if (allRows.value.length === props.checkCounter) {
        return true;
    }
    else {
        log.trace("IsAllChecked():", false);
        return false;
    }
}
function UpdateCheckedRows(row?: RowWrapper<any>) {
    log.trace("UpdateCheckedRows()")
    props.UpdateCheckedRows!(row, allRows.value);
}
watch(
    () => props.SynchroniseChecksTrigger,
    () => {
        log.trace("Watch()\nSynchroniseChecksTrigger");
        props.SynchroniseChecks?.(allRows.value);
    }
)
watch(
    () => props.CheckedByDefault,
    () => {
        log.trace("Watch()\nCheckedByDefault");
        props.UpdateCheckedRows!(undefined, allRows.value);
    }
)
function UncheckAll() {
    log.trace("UncheckAll()")
    for (const row of allRows.value) {
        if (row.checked) {
            props.CheckRow?.(row);
        }
    }
}
function CheckAll() {
    log.trace("CheckAll()")
    log.trace("allRows:", allRows.value);
    for (const row of allRows.value) {
        if (!row.checked) {
            props.CheckRow?.(row);
        }
    }
}
function CheckUncheckAll() {
    log.trace("CheckUncheckAll()")
    if (IsAllChecked()) {
        UncheckAll();
    } else {
        CheckAll();
    }
}
function GetChecked(): T[] | T[keyof T][] {
    if (props.ReturnKey && props.ColKey) {
        const key = props.ColKey;
        return pageRows.value.filter((a) => a.checked).map((a) => a.value[key]);
    } else return pageRows.value.filter((a) => a.checked).map((a) => a.value);
}
function sortBy(colIndex: number) {
    log.trace("sortBy()");
    const idx = sortCols.value.indexOf(colIndex);
    if (idx >= 0) {
        if (sortOrders.value[idx] < 0) {
            sortCols.value.splice(idx, 1);
            sortOrders.value.splice(idx, 1);
        } else sortOrders.value[idx] = -1;
    } else {
        sortCols.value.unshift(colIndex);
        sortOrders.value.unshift(1);
    }
    log.debug(`sortBy()\nsortCols: ${sortCols.value}\nsortOrders: ${sortOrders.value}`);

    loadDataIfCallBack();

    const oldCheckedRows = props.checkedRows;
    props.checkedRows.length = 0;
    pageRows.value.forEach((row) => {
        if (oldCheckedRows.includes(row.value.InvoiceId)) {
            log.debug(`sortyBy()\ninvoice id: ${row.value.InvoiceId}`);
            props.CheckRow?.(row);
        };
    });
}
function StringFormat(template: string, ...args: any[]) {
    return template.replace(/{(\d+)}/g, function (match, number) {
        return typeof args[number] != "undefined" ? args[number] : match;
    });
}
function updatePage() {
    if (page.value > maxPage.value) page.value = maxPage.value > 0 ? maxPage.value : 1;
}
function clearCallbackRef() {
    callBackPromise = undefined;
}
function loadDataIfCallBack() {
    if (props.LoadDataCallBack) {
        const promise = props.LoadDataCallBack(
            page.value,
            rowsPerPage.value,
            sortCols.value,
            sortOrders.value
        );
        if (callBackPromise) callBackPromise.cancel();
        isLoading.value = true;
        callBackPromise = promise.then(() => {
            updatePage();
            clearCallbackRef();
            UpdateTablePosition();
        }, clearCallbackRef) as CancellablePromise<void>;
    }
}
// changePage event to make sure checkboxes are cleared
function pageChangeCallBack() {
    loadDataIfCallBack();
}
function PrevPage(): boolean {
    const old = page.value;
    if (page.value == 1) return false;
    page.value--;
    pageChangeCallBack();
    return true;
}
function NextPage(): boolean {
    const old = page.value;
    if (page.value >= maxPage.value) return false;
    page.value++;
    pageChangeCallBack();
    return true;
}
function MovePage(p: number) {
    const old = page.value;
    page.value = p;
    pageChangeCallBack();
}
function disabledEventPropagation(event) {
    if (event.stopPropagation) {
        event.stopPropagation();
    } else if (window.event) {
        window.event.cancelBubble = true;
    }
}
function access(item: T, key: keyof T) {
    const v = item[key] as any;
    return (v instanceof Function) ? v.call(item) : v;
}
</script>

<style scoped lang="scss">
.vtl-td-checkbox {
    width: 1px;
    white-space: nowrap;
}

.vtl-td-checkbox input:hover {
    cursor: pointer;
}

.vtl-tbody input:hover {
    cursor: pointer;
}

span.MultiKeyCell::after {
    content: " ";
}

.vtl-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

table {
    display: inline-table;
    font-family: "Open Sans", sans-serif;
    border-collapse: collapse;
}

.vtl-table {
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100%;
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    overflow: scroll;
}

.vtl-thead {
    padding: .5rem .5rem .5rem .5rem;
    text-align: left;
    background: var(--primary-color-dark);
    color: #fff;
}

input[type="checkbox"] {
    text-align: center;
    transform: scale(1.5);
    margin: 2px 10px;
    z-index: -1;
    overflow-x: hidden;
}

.vtl-tbody {
    td#{&} {
        font-size: 12px;
    }

    td:not(:last-child) {
        text-align: left;
        padding: 8px 10px 8px 10px;
        border-right: solid var(--primary-color-lighter);
    }
}

.vtl-sortable {
    cursor: pointer;
    background-position: right;
    background-repeat: no-repeat;
    padding-right: 30px !important;
}

tr:nth-child(odd) {
    background-color: var(--light-grey);
}

tr:hover {
    background: var(--primary-color-lighter);
}

.vtl-table tr:hover.vtl-clickable {
    cursor: pointer;
}

tr:focus {
    background: var(--primary-color-lighter);
}

tr.vtl-checked {
    background-color: var(--primary-color-lighter);
    padding: 8px 8px 8px;
}

.vtl-sortable {
    cursor: pointer;
    background-position: right;
    background-repeat: no-repeat;
    padding-right: 30px !important;
}

.vtl-both {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAQAAADYWf5HAAAAkElEQVQoz7X QMQ5AQBCF4dWQSJxC5wwax1Cq1e7BAdxD5SL+Tq/QCM1oNiJidwox0355mXnG/DrEtIQ6azioNZQxI0ykPhTQIwhCR+BmBYtlK7kLJYwWCcJA9M4qdrZrd8pPjZWPtOqdRQy320YSV17OatFC4euts6z39GYMKRPCTKY9UnPQ6P+GtMRfGtPnBCiqhAeJPmkqAAAAAElFTkSuQmCC");
}

.vtl-asc {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZ0lEQVQ4y2NgGLKgquEuFxBPAGI2ahhWCsS/gDibUoO0gPgxEP8H4ttArEyuQYxAPBdqEAxPBImTY5gjEL9DM+wTENuQahAvEO9DMwiGdwAxOymGJQLxTyD+jgWDxCMZRsEoGAVoAADeemwtPcZI2wAAAABJRU5ErkJggg==);
}

.vtl-desc {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZUlEQVQ4y2NgGAWjYBSggaqGu5FA/BOIv2PBIPFEUgxjB+IdQPwfC94HxLykus4GiD+hGfQOiB3J8SojEE9EM2wuSJzcsFMG4ttQgx4DsRalkZENxL+AuJQaMcsGxBOAmGvopk8AVz1sLZgg0bsAAAAASUVORK5CYII=);
}

.arrow {
    float: right;
    width: 12px;
    height: 15px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-y: bottom;
}

.vtl-paging-change,
.vtl-paging-info {
    margin-top: auto;
    margin-bottom: auto;
}

.vtl-paging-change,
.vtl-paging-page,
.vtl-paging-count {
    justify-content: flex-end;
}

.vtl-paging-span {
    font-size: large;
    font-weight: bold;
    color: var(--primary-color-dark);
    margin-left: 15px;
}

.vtl-paging-select {
    border: 2px solid var(--primary-color-dark);
    border-radius: 5px;
    font-size: large;
    font-weight: bold;
    padding: 1px;
    margin-left: 5px;
    margin-right: 15px;
    color: var(--primary-color-dark);
}

.vtl-pagination {
    font-family: "Open Sans", sans-serif;
    text-align: right;
    width: 750px;
    padding: 0px;
    white-space: nowrap;
    justify-content: flex-end;
    display: -ms-flexbox;
    display: flex;
    list-style: none;
    border-radius: 1rem;
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
}

.page-link {
    display: inline-block;
    padding: 4px 10px;
    color: #fff;
    border-radius: 1rem;
    background: var(--primary-color-dark);
    margin: 0px 5px;
    cursor: pointer;
}

.page-link:hover {
    background: #717699;
}

.page-item.active .page-link {
    background: #717699;
    pointer-events: none;
    cursor: auto;
}

.page-item.disabled .page-link {
    background: #717699;
    pointer-events: none;
    cursor: auto;
    border-color: #dee2e6;
}

.col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    width: var(--content-width);
    margin: auto;
    display: table;
}

.col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    display: flex;
}

.hidden {
    display: none;
}

.vtl-loading-content {
    background-color: var(--primary-color-lighter);
}

.vtl-loading-content td {
    padding-left: 20px;
}

.vtl-empty-msg {
    display: flex;
    justify-content: left;
    align-items: center;
    background-color: var(--primary-color-lighter);
    font-size: large;
    padding: 8px;
    padding-left: 20px;
}
</style>
