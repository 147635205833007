import * as Msal from "msal";
import { msalConfig, apiScope, msalConfigPowerBi, apiScopePowerBi } from "@/config/msalConfig";
import log from "loglevel";

export default class AuthService {
    constructor(powerBi) {
        if (!powerBi) {
            this.loginRequest = {
                scopes: [apiScope],
                prompt: "select_account",
            };
        }
        this.accessTokenRequest = {
            scopes: [!powerBi? apiScope : apiScopePowerBi],
        };
        this.app = !powerBi? new Msal.UserAgentApplication(msalConfig) : new Msal.UserAgentApplication(msalConfigPowerBi);
        this.powerBi = powerBi;
    }

    login() {
		log.debug("AuthService\nlogin()");
        return this.app.loginPopup(this.loginRequest).then(() => {
            this.app
                .acquireTokenSilent(this.accessTokenRequest)
                .then((accessTokenResponse) => {
                    if (!accessTokenResponse.accessToken) {
                        return false;
                    }
					log.trace("AuthService\nlogin()\naccessTokenResponse:", accessTokenResponse);
                    return accessTokenResponse.accessToken.length > 0;
                }, (reason)=> { log.error('failed to acquire token') });
        });
    }

    logout() {
		log.debug("AuthService\nlogout()");
        this.app.logout();
    }

    async getApiToken(loginHint) {
		log.trace("AuthService\ngetApiToken()");
        if (this.powerBi) {
            this.accessTokenRequest.loginHint = loginHint;
            log.trace("AuthService\ngetApiToken()\nloginHint:", this.accessTokenRequest.loginHint, "\nredirectUri:", this.app.getRedirectUri());
        }
        const tokenResponse = await this.app
            .acquireTokenSilent(this.accessTokenRequest)
            .catch(async (error) => {
                log.error("getApiToken()\npowerBi:", this.powerBi, "\nerror:", error);
                if (error.name === "InteractionRequiredAuthError") {
                    return await this.app.acquireTokenPopup(
                        this.accessTokenRequest,
                    ).catch((reason)=>log.error(reason));
                }
            });
        if (tokenResponse) {
            return tokenResponse.accessToken;
        } else {
            return undefined;
        }
    }

    isLoggedIn() {
		log.trace("AuthService\nisLoggedIn()");
        let loggedIn = false;
        if (this.app.getAccount()) {
            loggedIn = this.app
                .acquireTokenSilent(this.accessTokenRequest)
                .then((response) => {
                    if (!response.accessToken) {
                        return false;
                    }
                    return response.accessToken.length > 0;
                },(reason)=>{ log.error(reason); return false; });
        }
        return loggedIn;
    }

    getUserName() {
		log.trace("AuthService\ngetUserName()");
        const account = this.app.getAccount();
        if (account) {
            return account.name;
        }
        return "Unknown User";
    }

    getEmailAddress() {
		log.trace("AuthService\ngetEmailAddress()");
        const account = this.app.getAccount();
        if (account) {
            log.trace("getEmailAddress()\nuserName:", account.userName);
            return account.userName;
        }
        log.warn("getEmailAddress()\nUser name could not be retrieved.");
        return "Unknown User";
    }

    getUsersRole() {
        //return 'External';
		log.trace("AuthService\ngetUsersRole()");
        const account = this.app.getAccount();
        if (account) {
            if (account.idToken) {
                if (account.idToken.roles) {
                    return account.idToken.roles[0];
                }
            }
        }
        return "UnknownRole";
    }
}
