import IContractDownPaymentScheduleTable from "@/Interfaces/CustomersAndContracts/IContractDownPaymentScheduleTable";
import { extractNonArraysFromObj } from '@/services/helpers/ObjectHelper';
import DateIgnoreTime from "@/types/DateIgnoreTime";

export default class ContractDownPaymentScheduleTable implements IContractDownPaymentScheduleTable {
    CONTRACT_ID: string;
    PERIOD_DATE: DateIgnoreTime;

    constructor(data: IContractDownPaymentScheduleTable) {
        Object.assign(this,extractNonArraysFromObj(data));
        this.CONTRACT_ID = data.CONTRACT_ID;
        this.PERIOD_DATE = new DateIgnoreTime(data.PERIOD_DATE);
    }
}