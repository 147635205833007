import { AxiosResponse } from "axios";
import NetworkService from "./NetworkService";

/** A network service class to change response.data to become camel case
 * only support forcing pascal to camel case at the moment 
 * if first word has more than 2 capital letters, treats it as a single acroynm
*/
export default class FCNetworkService<R, G=undefined, Q=G, D=Q, RD=R> extends NetworkService<R,G,Q,D,RD> {
    private static isUpperCase(c: string) {
        return c.toUpperCase() === c;
    }
    private static lowerFirstLetter(key: string) {
        return key.charAt(0).toLowerCase() + key.slice(1);
    }
    private static strToCamelCase(key: string) {
        if (key.length > 0) {
            if (this.isUpperCase(key.charAt(0))) {
                if (key.length<=1 || !this.isUpperCase(key.charAt(1))) return this.lowerFirstLetter(key); 
            }
        }
        return key;
    }
    private static objToCamelCase(obj: unknown) {
        console.log(this.objToCamelCase.name, obj, typeof obj !== 'object', !obj, obj instanceof Array);
        if (typeof obj !== 'object' || !obj) return obj;
        if (obj instanceof Array) return obj.map(x => this.objToCamelCase(x));
        const entries = Object.entries(obj);
        const result = Object.fromEntries(entries.map(([k,v])=>{
            k = this.strToCamelCase(k);
            switch (typeof v) {
                case 'object':
                    return [k, this.objToCamelCase(v)];
                default:
                    return [k, v];
            }
        }));
        console.log(this.objToCamelCase.name, obj, result);
        return result;
    }

    override async get(params?: G|Q|null, query?: Q|null): Promise<AxiosResponse<R>> {
        //@ts-ignore
        const result = await super.get(params, query);
        result.data = FCNetworkService.objToCamelCase(result.data);
        return result;
    }
    override async post(params: G|D|null, data?: D): Promise<AxiosResponse<RD, D>> {
        //@ts-ignore
        const result = await super.post(params, data);
        result.data = FCNetworkService.objToCamelCase(result.data);
        return result;
    }
    override async put(params: G|D|null, data?: D): Promise<AxiosResponse<RD, D>> {
        //@ts-ignore
        const result = await super.put(params, data);
        result.data = FCNetworkService.objToCamelCase(result.data);
        return result;
    }
    override async delete(payload?: G|D): Promise<AxiosResponse<RD, D>> {
        //@ts-ignore
        const result = await super.delete(payload);
        result.data = FCNetworkService.objToCamelCase(result.data);
        return result;
    }
}