import { computed, ref, Ref } from 'vue';
export type Writeable<T> = { -readonly [P in keyof T]: T[P] };
export default function useVModelDefaultVar<P,
N extends keyof P & string,
E extends (event: `update:${N}`, ...args: any[]) => any
>(props: P, emits: E, propName: N, defaultVal: NonNullable<P[N]>, callback?: (val: NonNullable<P[N]>)=>void) {
    const _internal: Ref<NonNullable<P[N]>> = ref(props[propName] ?? function(v: Writeable<P>[N]){
        emits(`update:${propName}`, v);
        return v;
    }(defaultVal)) as Ref<NonNullable<P[N]>>;
    const variable = computed<NonNullable<P[N]>>({
        get() {
            return props[propName] ?? _internal.value;
        },
        set: callback?(val: NonNullable<P[N]>)=>{
            _internal.value = val;
            emits(`update:${propName}`, val);
            callback(val);
        }:(val: NonNullable<P[N]>)=>{
            _internal.value = val;
            emits(`update:${propName}`, val);
        }
    });
    return variable;
}