class LinkedSetLink<T> {
    private _prev: LinkedSetLink<T>;
    private _next: LinkedSetLink<T>;

    constructor(public value: T) {
        this._prev = this;
        this._next = this;
    }
    public insertBefore(item: LinkedSetLink<T>) {
        const prev = item._prev = this._prev;
        const next = item._next = this;
        next._prev = item;
        prev._next = item;
    }
    public remove() {
        const prev = this._prev;
        const next = this._next;
        next._prev = prev;
        prev._next = next;
    }
    public reset() {
        this._next = this._prev = this;
    }
    public prev() {
        return this._prev;
    }
    public next() {
        return this._next;
    }
}
class LinkedMap<K, V> {
    private _map: Map<K, LinkedSetLink<V>>;
    private _pivot: LinkedSetLink<V>;

    constructor(iterable?: IterableIterator<[K, V]>) {
        this._map = new Map<K, LinkedSetLink<V>>();
        this._pivot = new LinkedSetLink<V>(undefined as V);
        if (iterable) this._addAll(iterable);
    }
    private _addAll(iterable: IterableIterator<[K, V]>) {
        for (const [k, v] of iterable) this.set(k, v);
    }
    forEach(func: (v: V) => void) {
        for (const item of this.values()) func(item);
    }
    set(k: K, v: V) {
        var link = this._map.get(k);
        if (link) {
            link.remove();
            this._pivot.insertBefore(link);
            link.value = v;
        }
        else {
            link = new LinkedSetLink(v);
            this._pivot.insertBefore(link);
            this._map.set(k, link);
        }
    }
    get(k: K) {
        return this._map.get(k)?.value;
    }
    has(k: K) {
        return this._map.has(k);
    }
    delete(k: K) {
        const link = this._map.get(k);
        if (link) {
            this._map.delete(k);
            link.remove();
        }
    }
    clear() {
        this._map.clear();
        this._pivot.reset();
    }
    get size() {
        return this._map.size;
    }
    *values() {
        let link = this._pivot.next();
        while (link !== this._pivot) {
            yield link.value;
            link = link.next();
        }
    }
    keys() {
        return this._map.keys();
    }
    [Symbol.iterator]() {
        return this.values().next();
    }
    *entries() {
        for (const [key, link] of this._map.entries())
            yield [key, link.value];
    }
    *reversed() {
        let link = this._pivot.prev();
        while (link !== this._pivot) {
            yield link.value;
            link = link.prev();
        }
    }
    first() {
        return this._pivot.next().value;
    }
    last() {
        return this._pivot.prev().value;
    }
}
export default LinkedMap;