<template>
    <Popup :model-value="!!confirmCarPurchaseRows" @update:model-value=close has-close :title="confirmingCarPurchases
        ? 'Confirming Car Purchases:'
        : 'Confirm Car Purchases:'
        ">
        <template #contents v-if=confirmCarPurchaseRows>
            <div v-if="fetchingContractOwners || confirmingCarPurchases" class="loading">
                <span class="loader"></span>
            </div>
            <div v-else-if="!DenyFunctionLevelAccess(FunctionAccessLevels.CarPurchasesAssignToOwner)" class="top-content">
                <GetContractOwnersErrorInfo :showError=showContractOwnersError
                    errorDescription="(Getting Contract Owners)" :error="infoError" />
                <ConfirmCarPurchasesErrorInfo :showError=showConfirmCarPurchasesError :closeError="true"
                    errorDescription="(Confirming Car Purchases)" :error="infoError" />
                <div class="center-dropdown-table-container">
                    <div class="center-dropdown-table">
                        <tbody style="font-weight: bold;">
                            <tr>
                                <td>
                                    <div>
                                        Select Contract Owner:
                                    </div>
                                    <select v-model="selectedContractOwner" class="dropdown">
                                        <option v-for="contractOwner in contractOwnersSelections"
                                            :value="contractOwner.text" :key="contractOwner.val">
                                            {{ contractOwner.text }}
                                        </option>
                                    </select>
                                </td>
                                <td>
                                    <div>
                                        Owner Assignment Date:
                                    </div>
                                    <div class="date-picker" style="padding-top: 5px;">
                                        <DatePicker v-model="ownerAssignmentDate" type="date" :enable-time-picker="false"
                                            :format="FormatDatePicker" placeholder="Select Date..."
                                            :text-input-options="textInputOptions" text-input />
                                    </div>
                                </td>
                                <td>
                                    <div class="center-buttons" style="margin-top: 0px;">
                                        <button class="incident-nav-button" @click="ConfirmCarPurchases()"
                                            :disabled="confirmCarPurchaseDisabled"
                                            :title="confirmCarPurchaseDisabled
                                                ? 'Select Contract Owner and Owner Assignment Date to Confirm Car Purchase'
                                                : `Confirm '${selectedContractOwner} ' Car Purchase for ${MonetaryValueWithCommas(confirmCarPurchaseRowsTotalBalance)}`"
                                            style="width: 100%; margin-top: 1rem;">
                                            Confirm Purchase
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="3" style="padding-top: 10px; font-weight: normal;">
                                    <div class="center-buttons">
                                        <div v-if="selectedContractOwner.length > 0 && ownerAssignmentDate !== undefined">
                                            <div>
                                                <strong>{{selectedContractOwner}}'</strong> will be assigned <strong>{{confirmCarPurchaseRows.length}}</strong> cars on <strong>{{ FormatDatePicker(ownerAssignmentDate) }}</strong> for a total purchase price of:
                                            </div>
                                        </div>
                                        <div v-else>
                                            Total purchase price:
                                        </div>

                                        <button class="incident-nav-button-fake" style="margin-left: 0.7rem;">
                                            {{ currentCurrencySymbol+MonetaryValueWithCommas(confirmCarPurchaseRowsTotalBalance) }}
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </div>
                </div>
            </div>
            <div style="padding-bottom: 5rem;">
                <Table v-if="!(fetchingContractOwners || confirmingCarPurchases) && !DenyFunctionLevelAccess(FunctionAccessLevels.CarPurchasesAssignToOwner)" :Rows=confirmCarPurchaseRows :Cols=confirmCarPurchaseCols :Labels=confirmCarPurchaseLabels >
                    <template #AMOUNT_TOTAL="props">
                        {{ MonetaryValueWithCommas(props.value.AMOUNT_TOTAL) }}
                    </template>
                    <template #BALANCE="props">
                        {{ MonetaryValueWithCommas(props.value.BALANCE) }}
                    </template>
                </Table>
            </div>
        </template>
    </Popup>
</template>
<script setup lang="ts">
import { inject, computed, ref } from "vue";
// Services
import { IAPILogger } from "@/interfaces/IAPILogger";
import { FunctionAccessLevels, DenyFunctionLevelAccess } from "@/config/AuthConfig";
import { CurrencySymbol, MarketCurrency, MonetaryValueWithCommas } from "@/services/helpers/Helper";
import useVars from "@/services/helpers/CarPurchases/CarPurchasesVariables";
import { FormatDatePicker } from "@/services/helpers/CarPurchases/CarPurchasesFunctions";
import SuccessCardPopup from '@/services/composables/SuccessCardPopup';
// Components
import ErrorInfo from "@/components/ErrorInfo.vue";
import Table from "@/components/TablePersistentChecking.vue";
import Popup from '@/components/Popup.vue';
import DatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
// Misc
import log from "loglevel";
import { AxiosError } from "axios";
import { globalReloadKey } from "@/types/ServiceKeys";
import { CarPurchasesPaymentRequest } from "@/models/CarPurchases/CarPurchasesPaymentRequest";
import { CarPurchasesCarSaleInvoice } from "@/models/CarPurchases/CarPurchasesCarSaleInvoice";
import { DisplayableKeys } from "@/services/SorterService";

log.trace("CarPurchase");
const fileName = "CarPurchase.vue";
const Logger: IAPILogger = inject("Logger")!;
const GetContractOwnersErrorInfo = ErrorInfo;
const ConfirmCarPurchasesErrorInfo = ErrorInfo;

const emits = defineEmits<{
    (e: 'update:modelValue', val?: CarPurchasesCarSaleInvoice[]);
    (e: 'close');
    (e: 'confirm');
}>();
const props = defineProps<{
    modelValue?: CarPurchasesCarSaleInvoice[]
}>();
const confirmCarPurchaseRows = computed({
    get() {
        return props.modelValue;
    },
    set(val) {
        emits('update:modelValue', val);
    }
});
const currentCurrencySymbol = computed(()=>{    
    let marketValue = confirmCarPurchaseRows.value?.[0].MARKET
    if(marketValue != undefined && marketValue != null ){
        return CurrencySymbol[MarketCurrency[marketValue]]
    }
    else{
        return  CurrencySymbol[MarketCurrency['GBP']]
    }
})
const reload = inject(globalReloadKey);
const {
    showContractOwnersError,
    carPurchasesServices,
    contractOwnersSelections,
    selectedContractOwner,
    fetchingContractOwners,
    market,
} = useVars();

const confirmCarPurchaseCols: DisplayableKeys<CarPurchasesCarSaleInvoice>[] = [
	"CONTRACT_ID",
	"INVOICE_ID",
	// "COLLECTIVE_INVOICE_ID",
	// "INVOICE_SEQUENCE_NUMBER",
	"MARKET",
	"AMOUNT_TOTAL",
	// "AMOUNT_EX_VAT",
	// "AMOUNT_VAT",
	"ISSUE_DATE",
	// "PAYMENT_TERMS",
	"BALANCE",
	// "BALANCE_DATE_UPDATED",
	// "JOURNAL_REFERENCE",
	// "JOURNAL_DATETIME_UPSERTED",
	// "JOURNAL_STATUS",
	// "JOURNAL_APPROVED_FOR_PAYMENT",
	// "APPROVED_FOR_PAYMENT",
];
const confirmCarPurchaseLabels = [
	"Contract Id",
	"Invoice Id",
	// "Collective Invoice Id",
	// "Invoice Sequence No.",
	"Market",
	"Amount Total",
	// "Amount Ex. VAT",
	// "Amount VAT",
	"Issue Date",
	// "Payment Terms",
	"Balance",
	// "Balance Date Updated",
	// "Journal Reference",
	// "Journal Datetime Upserted",
	// "Journal Status",
	// "Journal Approved for Payment",
	// "Approved for Payment",
];
const textInputOptions = { format: 'yyyy/MM/dd' };
const ownerAssignmentDate = ref<Date>();

const confirmingCarPurchases = ref(false);
const showConfirmCarPurchasesError = ref(false);
const infoError = ref();

const confirmCarPurchaseDisabled = computed(
	() => {
		return (
			selectedContractOwner.value.length == 0
			|| ownerAssignmentDate.value == undefined
		);
	}
);
const confirmCarPurchaseRowsTotalBalance = computed(
	() => {
		return confirmCarPurchaseRows.value?.reduce(
			(sum: number, CSI: CarPurchasesCarSaleInvoice) => sum + (CSI.BALANCE ?? 0),
			0
		);
	}
);
const confirmCarPurchaseRowsTotalExVATBalance = computed(
	() => {
		return confirmCarPurchaseRows.value?.reduce(
			(sum: number, CSI: CarPurchasesCarSaleInvoice) => sum + (CSI.AMOUNT_EX_VAT ?? 0),
			0
		);
	}
);
const confirmCarPurchaseRowsTotalVATBalance = computed(
	() => {
		return confirmCarPurchaseRows.value?.reduce(
			(sum: number, CSI: CarPurchasesCarSaleInvoice) => sum + (CSI.AMOUNT_VAT ?? 0),
			0
		);
	}
);

function close(refresh = false) {
    confirmCarPurchaseRows.value = undefined;
    ownerAssignmentDate.value = undefined;
    if (refresh) reload?.();
}

async function ConfirmCarPurchases() {
    if (confirmingCarPurchases.value) return;
    confirmingCarPurchases.value = true;

    const name = ConfirmCarPurchases.name;
    const Class = fileName + "/" + name;
    const baseMsg = 'Confirming Car Purchases';
    try {
        if (!confirmCarPurchaseRows.value) throw new ReferenceError('confirmCarPurchaseRows is unexpectedly undefined');
        const Message = `${baseMsg} (Car Sale Invoice IDs: ${confirmCarPurchaseRows.value?.map(x=>x.CONTRACT_ID).join(", ")})`;
        log.debug(Message);
        const res = await carPurchasesServices.ConfirmCarPurchasesPayment<CarPurchasesPaymentRequest>({
			collectiveInvoiceId: confirmCarPurchaseRows.value[0].COLLECTIVE_INVOICE_ID,
			confirmCarPurchaseRows: confirmCarPurchaseRows.value,
			confirmCarPurchaseRowsTotalBalance: confirmCarPurchaseRowsTotalBalance.value,
			confirmCarPurchaseRowsTotalExVATBalance: confirmCarPurchaseRowsTotalExVATBalance.value,
			confirmCarPurchaseRowsTotalVATBalance: confirmCarPurchaseRowsTotalVATBalance.value,
			market: market.value,
			ownerAssignmentDate: ownerAssignmentDate.value,
			selectedContractOwner: selectedContractOwner.value,
        });
        if (res.status == 201) {
            close(true);
            SuccessCardPopup.show({ "msg": "Car Purchase successfully registered." });
        } // if anything between 201 and 400 or 200 are returned...??
    } catch(error) {
        showConfirmCarPurchasesError.value = true;
        infoError.value = error;
        const Message = `(Error) ${baseMsg}: ${error instanceof Error ? error.message : ((error as AxiosError).response?.data as any)?.Error ?? error }`;
        log.error(Message);
        Logger.LogError(Message, Class);
    } finally {
        confirmingCarPurchases.value = false;
    }
}
</script>
<style scoped lang="scss">
.incident-nav-button-fake:hover {
    cursor: default;
    border-radius: var(--buttons-border-radius);
    background-color: var(--primary-color-lighter);
    padding: 10px;
    color: blue;
    font-size: larger;
    font-weight: bold;
}
</style>