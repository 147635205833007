<template>
    <button v-if="isVisible" @click="topFunction()" class="topBtn" aria-label="Go to top">
        ↑
    </button>
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount } from "vue";
const isVisible = ref(false);
const props = defineProps<{ visibleY: number }>();

onMounted(() => {
    window.addEventListener("scroll", checkIsVisible);
});
onBeforeUnmount(() => {
    window.removeEventListener("scroll", checkIsVisible);
});

function checkIsVisible() {
    isVisible.value = props.visibleY < window.scrollY;
}
// When the user clicks on the button, scroll to the top of the document
function topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}
</script>

<style scoped lang="scss">
.topBtn {
    position: fixed; /* Fixed/sticky position */
    bottom: 50px; /* Place the button at the bottom of the page */
    right: 0px;
    z-index: 1000; /* Make sure it does not overlap */
    border: none; /* Remove borders */
    outline: none; /* Remove outline */
    background-color: orange; /* Set a background color */
    color: white; /* Text color */
    cursor: pointer; /* Add a mouse pointer on hover */
    padding: 5px 9px 5px 11px; /* Some padding */
    border-top-left-radius: 5px; /* Rounded corners */
    border-bottom-left-radius: 5px;
    font-size: 18px; /* Increase font size */
    text-align: center;
    opacity: 0.7;
}
.topBtn:hover {
    background-color: #555; /* Add a dark-grey background on hover */
}
</style>
