import IVehicleTable from '@/Interfaces/CustomersAndContracts/IVehicleTable';
import ICarSaleInvoiceTable from '@/Interfaces/CustomersAndContracts/ICarSaleInvoiceTable';
import ICustomerCreditNoteTable from '@/Interfaces/CustomersAndContracts/ICustomerCreditNoteTable';
import ICustomerTable from '@/Interfaces/CustomersAndContracts/ICustomerTable';
import IContractTable from '@/Interfaces/CustomersAndContracts/IContractTable';
import ICarSaleCreditNoteTable from '@/Interfaces/CustomersAndContracts/ICarSaleCreditNoteTable';
import ICustomerPaymentAdviceSpecificationTable from '@/Interfaces/CustomersAndContracts/ICustomerPaymentAdviceSpecificationTable';
import ICustomerInvoiceSpecificationTable from '@/Interfaces/CustomersAndContracts/ICustomerInvoiceSpecificationTable';
import ICustomerInvoiceTable from '@/Interfaces/CustomersAndContracts/ICustomerInvoiceTable';
import IRemarketingInvoiceTable from '@/Interfaces/CustomersAndContracts/IRemarketingInvoiceTable';
import IFinalSettlementTable from '@/Interfaces/CustomersAndContracts/IFinalSettlementTable';
import IChurnTable from '@/Interfaces/CustomersAndContracts/IChurnTable';
import ICustomerPaymentTable from '@/Interfaces/CustomersAndContracts/ICustomerPaymentTable';
import IRvgfTable from '@/Interfaces/CustomersAndContracts/IRvgfTable';
import IFinalSettlementInvoiceTable from '@/Interfaces/CustomersAndContracts/IFinalSettlementInvoiceTable';
import ICustomerCreditNoteSpecificationTable from '@/Interfaces/CustomersAndContracts/ICustomerCreditNoteSpecificationTable';
import IContractDownPaymentScheduleTable from '@/Interfaces/CustomersAndContracts/IContractDownPaymentScheduleTable';
import VehicleTable from '@/models/CustomersAndContracts/VehicleTable';
import CarSaleInvoiceTable from '@/models/CustomersAndContracts/CarSaleInvoiceTable';
import CustomerCreditNoteTable from '@/models/CustomersAndContracts/CustomerCreditNoteTable';
import CustomerTable from '@/models/CustomersAndContracts/CustomerTable';
import ContractTable from '@/models/CustomersAndContracts/ContractTable';
import CarSaleCreditNoteTable from '@/models/CustomersAndContracts/CarSaleCreditNoteTable';
import CustomerPaymentAdviceSpecificationTable from '@/models/CustomersAndContracts/CustomerPaymentAdviceSpecificationTable';
import CustomerInvoiceSpecificationTable from '@/models/CustomersAndContracts/CustomerInvoiceSpecificationTable';
import CustomerInvoiceTable from '@/models/CustomersAndContracts/CustomerInvoiceTable';
import RemarketingInvoiceTable from '@/models/CustomersAndContracts/RemarketingInvoiceTable';
import FinalSettlementTable from '@/models/CustomersAndContracts/FinalSettlementTable';
import ChurnTable from '@/models/CustomersAndContracts/ChurnTable';
import CustomerPaymentTable from '@/models/CustomersAndContracts/CustomerPaymentTable';
import RvgfTable from '@/models/CustomersAndContracts/RvgfTable';
import FinalSettlementInvoiceTable from '@/models/CustomersAndContracts/FinalSettlementInvoiceTable';
import CustomerCreditNoteSpecificationTable from '@/models/CustomersAndContracts/CustomerCreditNoteSpecificationTable';
import ContractDownPaymentScheduleTable from '@/models/CustomersAndContracts/ContractDownPaymentScheduleTable';

export function makeVehicleTables(vehicleTables: IVehicleTable[]) {
    return vehicleTables.map(x=>new VehicleTable(x));
}
export function makeCarSaleInvoiceTables(carSaleInvoiceTables: ICarSaleInvoiceTable[]) {
    return carSaleInvoiceTables.map(x=>new CarSaleInvoiceTable(x));
}
export function makeCustomerCreditNoteTables(customerCreditNoteTables: ICustomerCreditNoteTable[]) {
    return customerCreditNoteTables.map(x=>new CustomerCreditNoteTable(x));
}
export function makeCustomerTables(customerTables: ICustomerTable[]) {
    return customerTables.map(x=>new CustomerTable(x));
}
export function makeContractTables(contractTables: IContractTable[]) {
    return contractTables.map(x=>new ContractTable(x));
}
export function makeCarSaleCreditNoteTables(carSaleCreditNoteTables: ICarSaleCreditNoteTable[]) {
    return carSaleCreditNoteTables.map(x=>new CarSaleCreditNoteTable(x));
}
export function makeCustomerPaymentAdviceSpecificationTables(customerPaymentAdviceSpecificationTables: ICustomerPaymentAdviceSpecificationTable[]) {
    return customerPaymentAdviceSpecificationTables.map(x=>new CustomerPaymentAdviceSpecificationTable(x));
}
export function makeCustomerInvoiceSpecificationTables(customerInvoiceSpecificationTables: ICustomerInvoiceSpecificationTable[]) {
    return customerInvoiceSpecificationTables.map(x=>new CustomerInvoiceSpecificationTable(x));
}
export function makeCustomerInvoiceTables(customerInvoiceTables: ICustomerInvoiceTable[]) {
    return customerInvoiceTables.map(x=>new CustomerInvoiceTable(x));
}
export function makeRemarketingInvoiceTables(remarketingInvoiceTables: IRemarketingInvoiceTable[]) {
    return remarketingInvoiceTables.map(x=>new RemarketingInvoiceTable(x));
}
export function makeFinalSettlementTables(finalSettlementTables: IFinalSettlementTable[]) {
    return finalSettlementTables.map(x=>new FinalSettlementTable(x));
}
export function makeChurnTables(churnTables: IChurnTable[]) {
    return churnTables.map(x=>new ChurnTable(x));
}
export function makeCustomerPaymentTables(customerPaymentTables: ICustomerPaymentTable[]) {
    return customerPaymentTables.map(x=>new CustomerPaymentTable(x));
}
export function makeRvgfTables(rvgfTables: IRvgfTable[]) {
    return rvgfTables.map(x=>new RvgfTable(x));
}
export function makeFinalSettlementInvoiceTables(finalSettlementInvoiceTables: IFinalSettlementInvoiceTable[]) {
    return finalSettlementInvoiceTables.map(x=>new FinalSettlementInvoiceTable(x));
}
export function makeCustomerCreditNoteSpecificationTables(customerCreditNoteSpecificationTables: ICustomerCreditNoteSpecificationTable[]) {
    return customerCreditNoteSpecificationTables.map(x=>new CustomerCreditNoteSpecificationTable(x));
}
export function makeContractDownPaymentScheduleTables(contractDownPaymentScheduleTables: IContractDownPaymentScheduleTable[]) {
    return contractDownPaymentScheduleTables.map(x=>new ContractDownPaymentScheduleTable(x));
}