<template>
    <Popup :modelValue=!!secondaryTableData
        @update:model-value=closeChild
        title="T&C Consent Table"
        hasClose
    >
        <template #contents v-if=secondaryTableData>
            <div class="grid-item">
                <div class="content-container">
                    <Table 
                        :Rows=secondaryTableData
                        :Cols=cols
                        SplitColKeys>
                        <template #fileReference="{ value }">
                            <div class="centre-text" v-if="value.fileReference">
                                <a :href="value.fileReference" target="_blank" class="linkTextStyle"  v-if="value.fileReference">File Link 🔗</a>
                            </div>
                        </template>
                        <template #consentDatetime="{ value }">
                            {{ value.consentDatetime.replace('T', ' ') }}
                        </template>
                    </Table>
                </div>
            </div>
        </template>
    </Popup>
</template>

<script setup lang="ts">
import { computed } from "vue";
import Popup from "./Popup.vue";
import ICustomerTermsConsent from "@/Interfaces/Contract/ICustomerTermsConsent";
import Table from "@/components/TablePersistentChecking.vue";
const cols: (keyof ICustomerTermsConsent)[] = [
    "contractID",
    "documentType",
    "fileReference",
    "documentVersion",
    "consentDatetime"
];
const props = defineProps<{
    modelValue?: ICustomerTermsConsent[];
}>();
const emits = defineEmits<{
    (e: 'update:modelValue', val?: ICustomerTermsConsent[]);
    (e: 'confirm');
}>();
const secondaryTableData = computed({
    get() {
        return props.modelValue;
    },
    set(val) {
        emits('update:modelValue', val);
    }
});
function closeChild() {
    secondaryTableData.value = undefined;
}
</script>
<style scoped>
.grid-item {
    padding: 10px;
}
.centre-text {
    display: flex;
    margin: auto; 
    justify-content: center;
    align-items: center;
    &:hover{
        color: blue;
        text-decoration: underline;
        border: none;
        background-color: transparent;
    }
}
.linkTextStyle{
    color: blue;
    font-size: larger;
    text-decoration: none;
    &:hover {
        text-decoration:underline;
        cursor: pointer;
        font-weight: bold;
    }
}
</style>